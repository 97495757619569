import { AppButton, globalDrawerState } from '@esg/ui';
import React from 'react';
import { useRecoilState } from 'recoil';

import { PlusOutlined } from '@ant-design/icons';
import { translateCodes } from '@/locales';
import { ProductCategoryForm } from '@/components/form/product-category/ProductCategoryForm';

interface CreateProductCategoryBtnProps {
    readonly refetchProductCategory?: () => void;
}

export const CreateProductCategoryBtn = (
    props: CreateProductCategoryBtnProps
) => {
    const [, setDrawerState] = useRecoilState(globalDrawerState);
    return (
        <AppButton
            translateCode={translateCodes.CREATE_PRODUCT_CATEGORY_BUTTON}
            icon={<PlusOutlined />}
            type='primary'
            onClick={() => {
                setDrawerState({
                    isOpen: true,
                    titleTransCode: translateCodes.TITLE_FORM_CREATE_PRODUCT_CATEGORY,
                    content: (
                        <ProductCategoryForm
                            onSuccess={props.refetchProductCategory}
                        />
                    ),
                });
            }}
        />
    );
};