import React from 'react';
import { TableCustom, useHttpQuery, Text, useLocalesFormat, FlexBox, Block, ColumnAvatarGroup, usePagination } from '@esg/ui';
import { Card, Divider, TableColumnsType, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { getProductDetailClientDetailReport } from '@esg/business-report/api/client-detail-report/getProductDetailClientDetail';
import { getProductDetailClientDetailTotalReport } from '@esg/business-report/api/client-detail-report/getProductDetailClientDetailTotal';
import { GetDetailSaleResponse } from '@esg/business-pos';
import dayjs from 'dayjs';
import { translateCodes } from '@/locales';
import'./CustomerProductDetailTable.scss';
import useOpenSaleFrom from '@/pages/fast-pos/sale/hook/useOpenSaleFrom';
interface CustomerProductDetailTableProps {
    customerId?: string;
    productId?: string;
}

export const CustomerProductDetailTable = ({ customerId, productId }: CustomerProductDetailTableProps) => {
    const { t } = useTranslation();
    const { formatDate, formatCurrency } = useLocalesFormat();
    const [searchParams, setSearchParams] = usePagination(
        {
            pageSize: '10',
        }
    );
    const { onShowFormSale } = useOpenSaleFrom();

    const { data: productDetails, isFetching: isProductReportFetching } = useHttpQuery(getProductDetailClientDetailReport, {
        query: {
            CustomerId: customerId,
            ProductId: productId,
            search: searchParams.searchByMain,
            orderBy: searchParams.orderByMain,
            page: searchParams.pageMain,
            pageSize: searchParams.pageSizeMain,
        }
    });

    const { data: productDetailTotals } = useHttpQuery(getProductDetailClientDetailTotalReport, {
        query: {
            CustomerId: customerId,
            ProductId: productId,
            
        }
    });

    const employeeColumn = (productReport: GetDetailSaleResponse) => {
        const employees = productReport.saleDetails
            ?.flatMap(o => o.saleDetailEmployees ?? [])
            .map(saleDetailEmployee => saleDetailEmployee.employee)
            .filter(employee => employee !== undefined) ?? [];
        
        return (
            <ColumnAvatarGroup
                items={employees}
                maxCount={2}
                prefixEnd={
                    employees?.length === 1 ?
                        employees[0].fullName ?? '' :
                        `${employees?.length} employee`}
            />
        );
    };

    const columnDetails: TableColumnsType<GetDetailSaleResponse> = [
        {
            title: 'Order Id',
            dataIndex: 'code',
            key: 'code',
            sorter: true,
            render: (_, productReport) => (
                <a onClick={() => onShowFormSale(productReport)}>
                    {productReport.code}
                </a>
            )
        },
        {
            title: 'Date & time',
            key: 'saleDate',
            dataIndex: 'saleDate',
            render: (_, productReport) => {
                const createdAt = dayjs(productReport?.date);
                const checkoutAtTime = formatDate(productReport?.checkoutAt, 'hh:mm A');
                const createdTime = formatDate(createdAt, 'hh:mm A');
                const createdDate = formatDate(createdAt);

                return (
                    <Block>
                        <p>{createdTime} - {productReport?.checkoutAt ? checkoutAtTime : ''}</p>
                        <p>{createdDate}</p>
                    </Block>
                );
            },
        },

        {
            title: 'Employees',
            dataIndex: 'employee',
            key: 'employee',
            render: (_, productReport) => employeeColumn(productReport)
        },
        {
            title: t(translateCodes.QUANTITY),
            dataIndex: 'quantity',
            key: 'quantity',
            render: (_, productReport) => {
                const toTalQuantity = productReport.saleDetails?.filter(o=>o.objectId == productId).reduce((total, saleDetail) => total + saleDetail.quantity, 0);
                return (
                    <Text fontWeight={600}>{toTalQuantity}</Text>
                );
            }
        },
        
        {
            title: t(translateCodes.TOTAL_AMOUNT),
            key: 'Amount',
            dataIndex: 'Amount',
            render: (_,productReport) => {
                const toTalAmount = productReport.saleDetails?.filter(o=>o.objectId == productId).reduce((total, saleDetail) => total + saleDetail.totalAmount, 0);
                return (
                    <Text fontWeight={600}>{formatCurrency(toTalAmount ?? 0)}</Text>
                );
            },

        }
    ];

    return (
        <Block className='customer-product-detail-table'>
            <FlexBox direction='column' gap={20}>
                <FlexBox justifyContent='space-between'>
                    <Block>
                        <Text fontSize={16} fontWeight={700}>Order History</Text>
                    </Block>
                    <Block >
                        <Text>Total: </Text>
                        <Text fontWeight='bold'>{productDetailTotals?.total}</Text>
                        <Divider type='vertical' />
                        <Text>Total amount: </Text>
                        <Text fontWeight='bold'>{formatCurrency(productDetailTotals?.totalAmount ?? 0)}</Text>
                    </Block>
                </FlexBox>
            </FlexBox>
            <Card>
                <TableCustom
                    onPageChange={(page) =>
                        setSearchParams({...searchParams, page: page.toString()})
                    }
                    className='custom-table-detail'
                    showHeader={false}
                    placeholderSearchTransCode={translateCodes.CLIENT_PLACEHOLDER_SEARCH}
                    loading={isProductReportFetching}
                    titleTableTransCode={translateCodes.PRODUCT_LIST}
                    subTitleTable={<Tag color='processing'>{`${productDetails?.items.length} ${t(translateCodes.PRODUCTS)}`}</Tag>}
                    columns={columnDetails}
                    dataSource={productDetails?.items ?? []}
                    pageInfo={productDetails?.meta}
                />
            </Card>
        </Block>
    );
};

