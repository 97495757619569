import {
    Block,
    InputNumberField,
    SelectField,
    Spacer,
    TreeSelectField,
} from '@esg/ui';
import React, { useMemo } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { GetAllProductCategoryResponseItem, GetAllServiceCategoryResponseItem } from '@esg/business-management';
import { promotionConditionRules } from '../rules';

const promotionTypeOptions = [
    {label: 'Service promotion' , value: 0},
    {label: 'Product promotion' , value: 1},
    // {label: 'Package promotion' , value: 2},
    {label: 'Total sale promotion' , value: 3}
];

const promotionModeOptions = [
    {label: 'All valid services' , value: 0},
    {label: 'First service only' , value: 1},
    {label: 'Lowest value service only' , value: 2},
    {label: 'Highest value service only' , value: 2},
];

interface PromotionConditionProps {
    form: UseFormReturn<FieldValues, unknown, FieldValues | undefined>
    serviceCategories?: GetAllServiceCategoryResponseItem[];
    productCategories?: GetAllProductCategoryResponseItem[];
}

export const PromotionConditionForm = (props: PromotionConditionProps) => {
    const { form } = props;

    const treeDataServiceCategory = useMemo(() => {
        return (
            props.serviceCategories?.map((i) => ({
                label: i.name.toLocaleUpperCase(),
                value: i.id,
                children: i.services.map((s) => ({
                    key: s.name,
                    label: s.name,
                    value: s.id,
                }))
            }))
            ?? []
        );
    }, [props.serviceCategories]);

    const treeDataProductCategory = useMemo(() => {
        return (
            props.productCategories?.map((i) => ({
                label: i.name.toLocaleUpperCase(),
                value: i.id,
                children: i.products.map((s) => ({
                    key: s.name,
                    label: s.name,
                    value: s.id,
                }))
            }))
            ?? []
        );
    }, [props.productCategories]);

    const promotionObjectType = form.watch('promotionCondition.objectType') as number;

    return (
        <Block bottom={20}>
            <Spacer bottom={8}>
                <SelectField
                    label='Promotion type'
                    placeholder='Select promotion type'
                    options={promotionTypeOptions}
                    style={{ width: '100%' }}
                    name='promotionCondition.objectType'
                    rules={promotionConditionRules.valueRequire}
                    onChange={() => form.setValue('promotionCondition.objectValue', [])}
                    allowClear={false}
                />
            </Spacer>
            {
                promotionObjectType !== 3 && <Spacer bottom={8}>
                    <TreeSelectField
                        treeData={promotionObjectType === 0 ? treeDataServiceCategory : treeDataProductCategory}
                        name='promotionCondition.objectValue'
                        placeholder={`${!promotionObjectType ?'Select services' : 'Select products'}`}
                        treeDefaultExpandAll={true}
                    />
                </Spacer>
            }
            {
                promotionObjectType !== 3 && <Spacer bottom={8}>
                    <SelectField
                        label='Multiple service mode'
                        placeholder='Select promotion type'
                        options={promotionModeOptions}
                        style={{ width: '100%' }}
                        name='promotionCondition.objectMode'
                        allowClear={false}
                    />
                </Spacer>
            }
            <Spacer bottom={8}>
                <InputNumberField
                    label='Usage limit'
                    style={{ width: '100%' }}
                    name='promotionCondition.numberOfTimes'
                    placeholder='Number of used service'
                    type='number'
                    rules={promotionConditionRules.valueRequire}
                />
            </Spacer>
        </Block>    
    );
};

