import React from 'react';
import { IconProps } from '../../Types';

export const IconPrintDailySale = (props: IconProps) => {
    return (
        <svg
            {...props}
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g clipPath='url(#clip0_2140_102840)'>
                <path
                    d='M15.0003 5.83268V4.33268C15.0003 3.39926 15.0003 2.93255 14.8187 2.57603C14.6589 2.26243 14.4039 2.00746 14.0903 1.84767C13.7338 1.66602 13.2671 1.66602 12.3337 1.66602H7.66699C6.73357 1.66602 6.26686 1.66602 5.91034 1.84767C5.59674 2.00746 5.34177 2.26243 5.18198 2.57603C5.00033 2.93255 5.00033 3.39926 5.00033 4.33268V5.83268M5.00033 14.9993C4.22535 14.9993 3.83786 14.9993 3.51994 14.9142C2.65721 14.683 1.98335 14.0091 1.75218 13.1464C1.66699 12.8285 1.66699 12.441 1.66699 11.666V9.83268C1.66699 8.43255 1.66699 7.73249 1.93948 7.19771C2.17916 6.7273 2.56161 6.34485 3.03202 6.10517C3.5668 5.83268 4.26686 5.83268 5.66699 5.83268H14.3337C15.7338 5.83268 16.4339 5.83268 16.9686 6.10517C17.439 6.34485 17.8215 6.7273 18.0612 7.19771C18.3337 7.73249 18.3337 8.43255 18.3337 9.83268V11.666C18.3337 12.441 18.3337 12.8285 18.2485 13.1464C18.0173 14.0091 17.3434 14.683 16.4807 14.9142C16.1628 14.9993 15.7753 14.9993 15.0003 14.9993M12.5003 8.74935H15.0003M7.66699 18.3327H12.3337C13.2671 18.3327 13.7338 18.3327 14.0903 18.151C14.4039 17.9912 14.6589 17.7363 14.8187 17.4227C15.0003 17.0661 15.0003 16.5994 15.0003 15.666V14.3327C15.0003 13.3993 15.0003 12.9326 14.8187 12.576C14.6589 12.2624 14.4039 12.0075 14.0903 11.8477C13.7338 11.666 13.2671 11.666 12.3337 11.666H7.66699C6.73357 11.666 6.26686 11.666 5.91034 11.8477C5.59674 12.0075 5.34177 12.2624 5.18198 12.576C5.00033 12.9326 5.00033 13.3993 5.00033 14.3327V15.666C5.00033 16.5994 5.00033 17.0661 5.18198 17.4227C5.34177 17.7363 5.59674 17.9912 5.91034 18.151C6.26686 18.3327 6.73357 18.3327 7.66699 18.3327Z'
                    stroke={props.color}
                    strokeWidth='1.66667'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_2140_102840'>
                    <rect width='20' height='20' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

IconPrintDailySale.defaultProps = {
    className: '',
    onClick: () => { },
    color: '#475467',
};
