import { PlusOutlined } from '@ant-design/icons';
import { AppButton, globalDrawerState } from '@esg/ui';
import React from 'react';
import { useRecoilState } from 'recoil';
import { PaymentMethodForm } from '@/components/form/payment-method';
import { translateCodes } from '@/locales';

interface CreatePaymentMethodBtnProps {
  readonly refetchCreatePaymentMethod?: () => void;
}

export const CreatePaymentMethodBtn = (_props: CreatePaymentMethodBtnProps) => {
    const [, setDrawerState] = useRecoilState(globalDrawerState);

    return (
        <AppButton
            translateCode={translateCodes.CREATE_METHOD}
            icon={<PlusOutlined />}
            type='primary'
            onClick={() => {
                setDrawerState({
                    isOpen: true,
                    titleTransCode: translateCodes.CREATE_METHOD,
                    content: <PaymentMethodForm
                        onSuccess={_props.refetchCreatePaymentMethod} 
                    />,
                });
            }}
        />
    );
};
