export const HOME_URL = '/';

export const POS_URL = '/fast-pos';

export const SCHEDULER_URL = '/scheduler';

export const CRM_URL = '/crm';
export const CUSTOMER_DETAIL_URL = CRM_URL + '/customer';
export const CUSTOMER_GROUP_URL = CRM_URL + '/customer/groups';
export const CUSTOMER_SOURCE_URL = CRM_URL + '/customer/sources';

export const PRODUCT_TRACKER_URL = '/product-tracker';
export const PRODUCT_TRACKER_SERVICES_URL = PRODUCT_TRACKER_URL + '/services';
export const PRODUCT_TRACKER_SERVICE_CATEGORY_URL = PRODUCT_TRACKER_URL + '/service-category';
export const PRODUCT_TRACKER_PRODUCTS_URL = PRODUCT_TRACKER_URL + '/products';
export const PRODUCT_TRACKER_PRODUCT_CATEGORY_URL = PRODUCT_TRACKER_URL + '/product-category';
export const PRODUCT_TRACKER_PACKAGES_URL = PRODUCT_TRACKER_URL + '/package';
export const PRODUCT_TRACKER_PACKAGE_CATEGORY_URL = PRODUCT_TRACKER_URL + '/package-category';
export const PRODUCT_TRACKER_INVENTORY_URL = PRODUCT_TRACKER_URL + '/inventory';
export const PRODUCT_TRACKER_STOCK_URL = PRODUCT_TRACKER_URL + '/stock';
export const PRODUCT_TRACKER_PURCHASE_ORDER_URL = PRODUCT_TRACKER_URL + '/purchase-order';

export const RESOURCE_MANAGEMENT_URL = '/resource-management';
export const RESOURCE_MANAGEMENT_SHIFT_URL = RESOURCE_MANAGEMENT_URL + '/shifts';
export const RESOURCE_MANAGEMENT_EMPLOYEE_MEMBER_URL = RESOURCE_MANAGEMENT_URL + '/employee-members';
export const RESOURCE_MANAGEMENT_JOB_TYPE_URL = RESOURCE_MANAGEMENT_URL + '/job-type';
export const RESOURCE_MANAGEMENT_COMMISSION_URL = RESOURCE_MANAGEMENT_URL + '/commission';
export const RESOURCE_MANAGEMENT_FACILITY_LIST_URL = RESOURCE_MANAGEMENT_URL + '/facility-list';

export const BOOKKEEPER_URL = '/bookkeeper';

export const SALE_ANALYZER_URL = '/sale-analyzer';

export const SALE_ANALYZER_APPOINTMENT_URL = SALE_ANALYZER_URL + '/appointment';
export const SALE_ANALYZER_REVENUE_URL = SALE_ANALYZER_URL + '/revenue';
export const SALE_ANALYZER_CLIENT_URL = SALE_ANALYZER_URL + '/client';

export const SALON_TRAFFIC_URL = '/salon-traffic';

export const SALON_MARKETING_URL = '/salon-marketing';

export const SALON_MARKETPLACE_URL = '/salon-marketplace';

export const AUTOMATION_URL = SALON_MARKETING_URL + '/automation';
export const SEGMENT_URL = SALON_MARKETING_URL + '/segment';
export const PROMOTION_URL = SALON_MARKETING_URL + '/promotion';
export const CAMPAIGN_URL = SALON_MARKETING_URL + '/campaign';

export const SETTING_URL = '/setting';
export const SETTING_GENERAL_INFORMATION_URL = SETTING_URL + '/general-information';
export const SETTING_GENERAL_BRANCH = SETTING_URL + '/branch';
export const SETTING_SUBSCRIPTION_URL = SETTING_URL + '/subscription';
export const SETTING_PAYMENT_METHOD_URL = SETTING_URL + '/payment-method';
export const SETTING_TAX_URL = SETTING_URL + '/tax';
export const SETTING_BRANCH_URL = SETTING_URL + '/branch';
export const SETTING_BANK_ACCOUNT_URL = SETTING_URL + '/bank-account';
export const SETTING_BOOKING_ONLINE_URL = SETTING_URL + '/booking-online';
export const SETTING_USER_ROLE_URL = SETTING_URL + '/user-role';
export const SETTING_ACCOUNT_INFORMATION_URL = SETTING_URL + '/account-information';
export const SETTING_ACCOUNT_PERMISSION_URL = SETTING_URL + '/account-permissions';
export const SETTING_INTEGRATIONS_URL = SETTING_URL + '/integrations';

export const HELP_CENTER_URL = '/help-center';

export const LOGOUT_PATH = '/auth/logout';
export const ORGANIZATION_MANAGEMENT_URL  = '/organization-management';
export const PROFILE = '/profile';

export const SALON_INFORMATION = ORGANIZATION_MANAGEMENT_URL + '/salon-information';
export const SUBSCRIPTION = ORGANIZATION_MANAGEMENT_URL + '/subscription';
export const BRANCH_MANAGEMENT = ORGANIZATION_MANAGEMENT_URL + '/branch-management';
