import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';
import { PromotionConditionRequest } from './getAllPromotion';

export interface CreatePromotionRequest extends HttpRequestData {
    readonly body: {
        id?: string; 
        name: string; 
        code: string;
        isActive: boolean; 
        unit: number;
        value: number; 
        effectiveDate: string; 
        expirationDate: string; 
        promotionCondition?: PromotionConditionRequest;
    };
}

export const createPromotion = httpUtil.createHttpRequestMeta<
    CreatePromotionRequest,
    void
>({
    baseUrl: env.apiUrlBase, 
    path: '/api/v1/promotions',
    method: 'POST', 
    authentication: 'bearer',
});