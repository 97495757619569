import { resourceApis } from '@esg/business-resource-management';
import { AppAvatar, AppButton, AvatarItem, ColumnAvatarGroup, FlexBox, InputSearch, Text, useHttpQuery } from '@esg/ui';
import { Checkbox, Divider, SelectProps, Select } from 'antd';
import { BaseOptionType, DefaultOptionType } from 'antd/es/select';
import { DisplayValueType } from 'rc-select/lib/BaseSelect';

import { useTranslation } from 'react-i18next';
import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { translateCodes } from '@/locales';
import './EmployeeFilter.scss';

interface EmployeeFilterProps extends SelectProps {
    readonly width?: CSSProperties['width'];
    readonly employeeIds?: string[];
    readonly handleFilter: (employeeIds: string[]) => void;
    readonly setFilterOpen: (filter: string) => void;
    readonly filerOpen: string;
    readonly allEmployees?: boolean;
}

export const EmployeeFilter = (props: EmployeeFilterProps) => {
    const { t } = useTranslation();
    const { employeeIds, width = 180, handleFilter, setFilterOpen, filerOpen, allEmployees, ...rest } = props;
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [values, setValues] = useState(employeeIds || []);
    const { data: employeeResponse } = useHttpQuery(resourceApis.getAllEmployees, {
        query: {
            isAllowBooking: true,
            pageSize: 1000
        }
    });

    useEffect(() => {
        if (employeeIds?.length === 0) {
            setValues([]);
        }
        if (filerOpen != 'employee') {
            setOpen(false);
        }
    }, [employeeIds, filerOpen]);

    const options = useMemo(
        () => (
            employeeResponse?.items.filter((o) => (
                o.fullName.toLowerCase().includes(searchValue.toLowerCase())
            )).map((o) => ({ 
                ...o,
                value: o.id,
                label: o.fullName 
            })) || []
        ),[searchValue, employeeResponse?.items]
    );

    const optionRender = useCallback(
        ({ data }: { data: BaseOptionType }) => (
            <FlexBox gap={12} alignItems='center' key={data.value}>
                <Checkbox checked={values.includes(data.value)} />
                <FlexBox gap={12} alignItems='center'>
                    <AppAvatar data={data} size='sm' icon={<UserOutlined />} />
                    <Text>{data.label}</Text>
                </FlexBox>
            </FlexBox>
        ),
        [values]
    );

    const dropdownRender = useCallback(
        (options: React.ReactElement) => (
            <div onMouseLeave={() => setOpen(false)}>
                <InputSearch 
                    variant='borderless'
                    name='search-employee'
                    placeholder='Search employee'
                    onChange={(value) => setSearchValue(value.target.value)}
                />
                {options}
                <Divider />
                <div
                    className='select-filter-actions'
                    onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <AppButton
                        className='action-btn'
                        translateCode='CANCEL'
                        onClick={() => {
                            handleFilter?.([]);
                            setValues([]);
                        }}
                    />
                    <AppButton
                        className='action-btn'
                        type='primary'
                        translateCode='Apply'
                        onClick={() => {
                            handleFilter(values);
                            setSearchValue('');
                        }}
                    />
                </div>
            </div>
        ),
        [handleFilter, values]
    );

    const maxTagPlaceholder = useCallback(
        (omittedValues: DisplayValueType[], selectOptions: DefaultOptionType[]) => {
            // Chuyển đổi omittedValues sang AvatarItem[]
            const combined: AvatarItem[] = omittedValues
                .map((o) => {
                    const match = selectOptions.find((ov) => ov.value === o.value);
                    return match
                        ? {
                            id: match.value,
                            src: match.avatar,
                            fullName: match.label,
                            avatar: match.avatar,
                        }
                        : null;
                })
                .filter(Boolean) as AvatarItem[]; // Lọc bỏ các giá trị null

            const isAll = omittedValues.length === selectOptions.length;
            const isOne = omittedValues.length === 1;
            return (
                <span>
                    {isAll ? (
                        t(translateCodes.ALL_EMPLOYEES)
                    ) : (
                        <ColumnAvatarGroup
                            items={combined}
                            maxCount={1}
                            prefixEnd={
                                isOne
                                    ? `${omittedValues[0].label || ''}`
                                    : `${omittedValues.length} ${t(translateCodes.EMPLOYEE_MORE)}`
                            }
                        />
                    )}
                </span>
            );
        },
        [t]
    );

    const style: CSSProperties = useMemo(() => ({ width, height: 36 }), [width]);

    return (
        <Select
            open={open}
            value={values}
            onClick={() => {
                setOpen(true);
                setFilterOpen('employee');
            }}
            onChange={(value) => {
                setValues(value);
            }}
            mode='multiple'
            options={options}
            style={style}
            showSearch={false}
            popupMatchSelectWidth={308}
            popupClassName='select-filter'
            optionRender={optionRender}
            maxTagCount={0}
            maxTagPlaceholder={(value) => maxTagPlaceholder(value, options)}
            className={allEmployees ? 'placeholderSelectAll employeeFilterField' : 'employeeFilterField' }
            placeholder={allEmployees ? t(translateCodes.ALL_EMPLOYEES) : t(translateCodes.FILTER_EMPLOYEE)}
            dropdownRender={dropdownRender}
            {...rest}
        />
    );
};
