import { translateCodes } from '@/locales';

export const segmentRules = {
    segmentName: {
        required: translateCodes.VAL_SAM_01,
        maxLength: {
            value: 255,
            message: translateCodes.MAX_LENGTH_SEGMENT
        },
    },
    description: {
        maxLength: {
            value: 255,
            message: translateCodes.MAX_LENGTH_SEGMENT_DESCRIPTION
        },
    },
};

export const segmentConditionRules = {
    valueRequire: {
        required: translateCodes.VAL_SAM_02,
    },
};