import { PlusOutlined } from '@ant-design/icons';
import { AppButton, globalDrawerState } from '@esg/ui';
import React from 'react';
import { useRecoilState } from 'recoil';
import { BankAccountForm } from '@/components';

interface CreateBankAccountBtnProps {
  readonly refetchCreateBankAccount?: () => void;
}

export const CreateBankAccountBtn = (props: CreateBankAccountBtnProps) => {
    const [, setDrawerState] = useRecoilState(globalDrawerState);

    return (
        <AppButton
            translateCode='Add bank account'
            icon={<PlusOutlined />}
            type='primary'
            onClick={() => {
                setDrawerState({
                    isOpen: true,
                    titleTransCode: 'Add bank account',
                    content: (
                        <BankAccountForm
                            onSuccess={props.refetchCreateBankAccount}
                        />
                    )
                });
            }}
        />
    );
};
