import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {SegmentOverview} from './SegmentOverview';
import {SegmentDetail} from './SegmentDetail';
import { SEGMENT_URL } from '@/configs';

export function Segment() {
    const { id: segmentId } = useParams();
    const navigate = useNavigate(); 
    const handleViewDetail = (segmentDetailId: string) => {
        navigate(`${SEGMENT_URL}/${segmentDetailId}`);
    };

    if(segmentId)
    {
        return <SegmentDetail/>;
    }

    return <SegmentOverview handleViewDetail={handleViewDetail}/>;
}
