import {
    DatePickerField,
    FlexBox,
    InputNumberFieldCustom,
    TextAreaField,
    managementProfileState,
} from '@esg/ui';
import React, { useCallback } from 'react';
import { SharedTimeProps } from 'rc-picker';
import { useRecoilState } from 'recoil';
import { Dayjs } from '@esg/shared';
import { Alert } from 'antd';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { useAppointmentContext } from '../../@share/AppointmentFormContext';
import { useAvailableAppointmentDate } from '../../@share/hooks/useAvailableAppointmentDate';
import { AppointmentStatus } from './AppointmentStatus';
import { translateCodes } from '@/locales';
import { CustomerSelectField } from '@/components/form/customer/children/select-customer/CustomerSelectField';

export const AppointmentGeneralInfo = () => {
    const {
        appointmentSetting,
        defaultValues: { id },
    } = useAppointmentContext();
    const [profile] = useRecoilState(managementProfileState);
    const unAvailableDate = useAvailableAppointmentDate();
    const [currentLocale] = useRecoilState(currentLocaleState);

    const checkDisableTime = useCallback(
        (current: Dayjs) => {
            const businessHoursDay = profile?.branch.businessHour
                ?.filter((item) => item.isDayOff === true)
                .map((item) => item.dayOfWeek) ?? [];

            return businessHoursDay.includes(current.day());
        },
        [profile]
    );

    return (
        <FlexBox flex='unset' direction='column' gap={24}>
            {id && <AppointmentStatus />}
            <CustomerSelectField isRequired={true} name='customerId' />
            <InputNumberFieldCustom
                name='numberOfGuest'
                label={translateCodes.NUMBER_OF_GUEST}
                required
            />
            <div>
                <DatePickerField
                    name='appointmentDate'
                    label={translateCodes.DATE_AND_TIME}
                    format={'h:mm A ddd, ' + currentLocale?.format}
                    showTime={{
                        minuteStep:
							(appointmentSetting?.timeSlotDuration as SharedTimeProps['minuteStep']) ||
							15,
                    }}
                    allowClear={false}
                    showNow={false}
                    required={true}
                    disabledDate={checkDisableTime}
                />
                {unAvailableDate && (
                    <Alert
                        banner
                        showIcon
                        type='warning'
                        message='Please select a time within the working hours'
                        style={{ marginTop: 8, color: 'red', borderRadius: 8 }}
                    />
                )}
            </div>
            <TextAreaField
                name='notes'
                label={translateCodes.APPOINTMENT_NOTES}
                placeholder={translateCodes.NOTES_PLACEHOLDER}
            />
        </FlexBox>
    );
};
