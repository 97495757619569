import { FormLayout,
    FlexBox,
    FormBase,
    InputField,
    useHttpCommand,
    SelectField,
    useLocationOptions,
    useHttpQuery,
    AvatarUpload,
    RefMethodFormType,
    IconCamera,
} from '@esg/ui';
import { Card } from 'antd';
import React , { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createUser, getAllRole, GetUserDetailResponse, updateUser } from '@esg/business-account';

import { getAllBranches } from '@esg/business-organization/api/branch/getAllBranches';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { useRecoilState } from 'recoil';
import { userRules } from '../rules/userRules';
import { translateCodes } from '@/locales';

interface UserFormProps {
    readonly defaultValues?: GetUserDetailResponse;
    readonly onSuccess?: (response?: GetUserDetailResponse) => void;
    readonly onDelete?: () => void;
}

export const UserForm = (props: UserFormProps) => {
    const { defaultValues } = props;
    const isUpdate = !!defaultValues?.id;
    
    const { t } = useTranslation();
    const { phoneCodeOptions } = useLocationOptions('', '');
    const [currentLocale] = useRecoilState(currentLocaleState);

    const formRef : RefMethodFormType = useRef(null);
    
    const {data: rolesResponse} = useHttpQuery(getAllRole);
    const {data: branchesResponse} = useHttpQuery(getAllBranches);

    const { mutateAsync: createUserMutate, isPending: isLoadingCreate } = useHttpCommand(
        createUser,
        {
            onSuccess: props.onSuccess,
            onError: () => {
                formRef.current?.setError('email', {
                    type: 'validate',
                    message: t(translateCodes.EMAIL_ALREADY_EXISTS),
                });
            },
            showNotificationError: false,
        },
    );

    const { mutateAsync: updateUserMutate, isPending: isLoadingUpdate } = useHttpCommand(
        updateUser,
        {
            onSuccess: props.onSuccess,
            onError: () => {
                formRef.current?.setError('email', {
                    type: 'validate',
                    message: t(translateCodes.EMAIL_ALREADY_EXISTS),
                });
            },
            showNotificationError: false,
        }
    );

    const roleOptions = useMemo(()=> {
        const rolesNotOwner = rolesResponse?.items.filter((role) => role.name !== 'Owner');

        return rolesNotOwner?.map((role) => ({
            label: role.name,
            value: role.id,
        }));
    }, [rolesResponse]);

    const branchOptions = useMemo(()=> branchesResponse?.items.map((branch) => ({
        label: branch.name,
        value: branch.id,
    })), [branchesResponse]);

    const onSubmit = React.useCallback(
        async (values: GetUserDetailResponse) => {
            
            if (isUpdate) {
                return await updateUserMutate({
                    pathData: { id: defaultValues?.id },
                    body: values,
                });
            }
            
            await createUserMutate({ body: values });
        },
        [createUserMutate, defaultValues?.id, isUpdate, updateUserMutate]
    );

    return (
        <FormBase formRef={formRef} onSubmit={onSubmit} defaultValues={{
            ...defaultValues,
            branchIds: defaultValues?.userBranches?.map((userBranch) => userBranch.branchId),
            countryCode: defaultValues?.countryCode ?? currentLocale?.countryCode,
        }}>
            <FormLayout
                onDeleted={props.onDelete}
                isLoadingButton={isLoadingCreate || isLoadingUpdate}
                labelSubmitCode={
                    defaultValues?.id
                        ? translateCodes.SAVE
                        : translateCodes.CREATE
                }
            >
                <Card bordered={false}>
                    <FlexBox direction='column' gap={16}>
                        <AvatarUpload    
                            translateCode={translateCodes.UPLOAD_PHOTO}
                            name='avatar'
                            iconHover={<IconCamera width={20} height={20} />}
                        />
                        <FlexBox gap={16}>
                            <InputField
                                rules={userRules.firstName(t)}
                                name='firstName'
                                label={translateCodes.FIRST_NAME}
                                placeholder={translateCodes.FIRST_NAME_PLACEHOLDER}
                            />
                            <InputField
                                rules={userRules.lastName(t)}
                                name='lastName'
                                label={translateCodes.LAST_NAME}
                                placeholder={translateCodes.LAST_NAME_PLACEHOLDER}
                            />
                        </FlexBox>
                        <FlexBox gap={16}>
                            <InputField
                                addonBefore={
                                    <SelectField
                                        showSearch
                                        allowClear
                                        style={{ width: 80 }}
                                        name='countryCode'
                                        options={phoneCodeOptions}
                                    />
                                }
                                name='phoneNumber'
                                rules={userRules.phone}
                                label={translateCodes.PHONE_NUMBER}
                                placeholder={translateCodes.PHONE_PLACEHOLDER}
                            />
                            <InputField
                                name='email'
                                label={translateCodes.EMAIL}
                                placeholder={translateCodes.EMAIL_PLACEHOLDER_SHORT}
                                rules={userRules.email}
                            />
                        </FlexBox>
                        <FlexBox gap={16}>
                            <SelectField
                                name='roleId'
                                label={translateCodes.ROLE}
                                options={roleOptions}
                                rules={userRules.role}
                                placeholder={translateCodes.SELECT_ROLE}
                            />  
                            <SelectField
                                name='branchIds'
                                mode='multiple'
                                label={translateCodes.LOCATION}
                                placeholder={translateCodes.SELECT_LOCATION}
                                options={branchOptions}
                                rules={userRules.branches}
                            />
                        </FlexBox>
                    </FlexBox>
                </Card>
            </FormLayout>
        </FormBase>
    );
};
