import React from 'react';
import { IconProps } from '../../Types';

export const IconFace = (props: IconProps) => {
    const { color = '#98A2B3', width = 16, height = 16 } = props;
    return (
        <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'  {...props}>
            <path d='M3.54387 12.959C3.94942 12.0035 4.89629 11.3334 5.99967 11.3334H9.99967C11.1031 11.3334 12.0499 12.0035 12.4555 12.959M10.6663 6.33337C10.6663 7.80613 9.47243 9.00004 7.99967 9.00004C6.52692 9.00004 5.33301 7.80613 5.33301 6.33337C5.33301 4.86061 6.52692 3.66671 7.99967 3.66671C9.47243 3.66671 10.6663 4.86061 10.6663 6.33337ZM14.6663 8.00004C14.6663 11.6819 11.6816 14.6667 7.99967 14.6667C4.31778 14.6667 1.33301 11.6819 1.33301 8.00004C1.33301 4.31814 4.31778 1.33337 7.99967 1.33337C11.6816 1.33337 14.6663 4.31814 14.6663 8.00004Z' stroke={color} strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round'/>
        </svg>

    );
};
