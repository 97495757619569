/* eslint-disable @typescript-eslint/no-explicit-any */
import { translateCodes } from '@/locales';

export const customerRules = {
    phone:{
        pattern: {
            value: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
            message: translateCodes.PHONE_NUMBER_IS_INVALID,
        },
    },
    lastName:{
        maxLength: {
            value: 50,
            message: translateCodes.MAX_LENGTH_LAST_NAME,
        },
        minLength: {
            value: 2,
            message: translateCodes.LAST_NAME_MIN_LENGTH,
        },
        pattern: {
            value: /^[^!@#$%^&*(),.?":{}|<>]/,
            message: translateCodes.LAST_NAME_INVALID,
        },
    },
    firstName:{
        maxLength: {
            value: 50,
            message: translateCodes.MAX_LENGTH_FIRST_NAME,
        },
        minLength: {
            value: 2,
            message: translateCodes.FIRST_NAME_MIN_LENGTH,
        },
        pattern: {
            value: /^[^!@#$%^&*(),.?":{}|<>]/,
            message: translateCodes.FIRST_NAME_INVALID,
        },
        required: translateCodes.FIRST_NAME_REQUIRED
    },
    email: {
        pattern: {
            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
            message: translateCodes.EMAIL_INVALID,
        },
        maxLength: {
            value: 255,
            message: translateCodes.MAX_LENGTH_EMAIL,
        },
    },
    address: {
        maxLength: {
            value: 200,
            message: translateCodes.MAX_LENGTH_ADDRESS
        },
    },
    note: {
        maxLength: {
            value: 100,
            message: translateCodes.MAX_LENGTH_NOTE,
        },
    },
};
