import { globalDrawerState, useLocalesFormat } from '@esg/ui';
import { useRecoilState } from 'recoil';
import { AppointmentGetDetailResponse } from '@esg/business-schedule';
import { dayjs } from '@esg/shared';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { GetCustomerDetailResponse } from '@esg/business-crm';
import { AppointmentFormControl } from '@/components';
import { translateCodes } from '@/locales';
import { DrawerTitle } from '@/components/common/DrawerTitle';

interface ShowAppointmentFormParams {
	readonly date?: string;
	readonly facilityId?: string;
	readonly refetch?: () => void;
	readonly defaultEmployeeId?: string;
	readonly initialValues?: Partial<AppointmentGetDetailResponse>;
    readonly isRebook?: boolean;
    readonly customerId?: string;
    readonly customer?: GetCustomerDetailResponse;
}

export const useAppointmentForm = () => {
    const [, setDrawerState] = useRecoilState(globalDrawerState);
    const [currentLocale] = useRecoilState(currentLocaleState);
    const { formatDate } = useLocalesFormat();

    return (params?: ShowAppointmentFormParams) => {
        const { date, facilityId, refetch, defaultEmployeeId, initialValues, customerId, customer } =
			params as ShowAppointmentFormParams;

        const defaultValues = initialValues
            ? {
                ...initialValues,
                appointmentServices: initialValues.appointmentServices!.map((o) => ({
                    ...o,
                    appointmentServiceDetails: o.appointmentServiceDetails.map((s) => ({
                        ...s,
                        employeeIds: s.appointmentServiceEmployees?.map((st) => st.employeeId),
                    })),
                })),
            }
            : {
                appointmentDate: date ?? dayjs().toISOString(),
                appointmentServices: [
                    {
                        appointmentServiceDetails: [
                            {
                                employeeIds: defaultEmployeeId ? [defaultEmployeeId] : undefined,
                                appointmentServiceEmployees: defaultEmployeeId
                                    ? [
                                        {
                                            employeeId: defaultEmployeeId,
                                        },
                                    ]
                                    : [],
                                facilityId,
                            },
                        ],
                    },
                ],
                customer: customer,
                customerId: customerId,
            };

        setDrawerState({
            isOpen: true,
            titleTransCode: initialValues?.id
                ? DrawerTitle(
                    (initialValues as AppointmentGetDetailResponse).appointmentCode,
                    formatDate((initialValues as AppointmentGetDetailResponse).creationTime,  'h:mm A ddd, ' + currentLocale?.format )
                )
                : translateCodes.ADD_APPOINTMENT,
            content: <AppointmentFormControl defaultValues={defaultValues} refetch={refetch} />,
        });
    };
};

export const showAppointmentForm = () => {
    const appointmentFormBtn = document.getElementById('appointment-form-btn')!;
    appointmentFormBtn.click();
};
