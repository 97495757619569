import { customerApi, GetAllCustomerResponseItem, GetCustomerDetailResponse } from '@esg/business-crm';
import {
    Block,
    FlexBox,
    FormBase,
    InputField,
    Spacer,
    Text,
    useHttpCommand,
    FormLayout,
    RefMethodFormType,
    IconArrowDownClient,
    IconArrowUpClient,
    IconCamera,
} from '@esg/ui';
import {
    Card,
} from 'antd';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AvatarUpload } from '@esg/ui/components/avatar';
import { HttpRequestError, viewRequests } from '@esg/framework';
import { AdditionalInfo } from './children';
import { translateCodes } from '@/locales';
import { customerRules } from '@/pages/crm/customer/@share/customerRules';
import'./CustomerForm.scss';
interface CustomerFormProps {
    readonly onSuccess?: (response?: GetCustomerDetailResponse) => void;
    readonly defaultValues?: Partial<GetAllCustomerResponseItem>;
    readonly onDeleted?: () => void;
    readonly isShowMoreDetail?: boolean;
}

export const CustomerForm = (props: CustomerFormProps) => {
    const { t } = useTranslation();
    const formRef: RefMethodFormType = useRef(null);
    const [IsShowMoreDetail, setIsShowMoreDetail] = useState<boolean>(props.isShowMoreDetail ?? true);

    const { mutateAsync: createCustomer, isPending: isLoadingCreate} = useHttpCommand(customerApi.createCustomer, {
        showNotificationError: false,

        onError(error) {
            const errorResponse = error as HttpRequestError;
            switch (errorResponse.message) {
            case 'PHONE_OR_EMAIL_IS_REQUIRED':
                formRef.current?.setError('email', {
                    type: 'validate',
                    message: t(translateCodes.PHONE_OR_EMAIL_REQUIRED),
                });
                formRef.current?.setError('phone', {
                    type: 'validate',
                    message: t(translateCodes.PHONE_OR_EMAIL_REQUIRED),
                });
                break;
            case 'FIRST_NAME_REQUIRED':
                formRef.current?.setError('firstName', {
                    type: 'validate',
                    message: t(translateCodes.FIRST_NAME_REQUIRED),
                });
                break;
            case 'ALL_FIELDS_ARE_REQUIRED':
                formRef.current?.setError('firstName', {
                    type: 'validate',
                    message: t(translateCodes.FIRST_NAME_REQUIRED),
                });
                formRef.current?.setError('email', {
                    type: 'validate',
                    message: t(translateCodes.PHONE_OR_EMAIL_REQUIRED),
                });
                formRef.current?.setError('phone', {
                    type: 'validate',
                    message: t(translateCodes.PHONE_OR_EMAIL_REQUIRED),
                });
                break;
            case 'CUSTOMER_EMAIL_EXISTING':
                formRef.current?.setError('email', {
                    type: 'validate',
                    message: t(translateCodes.EMAIL_ALREADY_EXIST),
                });
                break;
            case 'CUSTOMER_PHONE_EXISTING':
                formRef.current?.setError('phone', {
                    type: 'validate',
                    message: t(translateCodes.PHONE_ALREADY_EXIST),
                });
                break;
            default:
                break;
            }
        },
        onSuccess: (response) => {
            props.onSuccess?.(response);
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.NM_CM_01),
                duration: 1,
            });
        }
    });

    const { mutateAsync: updateCustomer, isPending: isLoadingUpdate } = useHttpCommand(customerApi.updateCustomer, {
        showNotificationError: false,
        onError() {
            formRef.current?.setError('email', {
                type: 'validate',
                message: t(translateCodes.EMAIL_ALREADY_EXIST),
            });
        },
        onSuccess: () => {
            props.onSuccess?.();
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.NM_CM_05),
                duration: 1,
            });
        }
    });

    const onSubmit = React.useCallback(async (values: GetAllCustomerResponseItem) => {
        const isUpdate = !!props.defaultValues?.id;

        if (isUpdate) {
            return await updateCustomer({
                pathData: { id: props.defaultValues?.id },
                body: values,
            });
        }

        await createCustomer({ body: values });

    }, [createCustomer, props, updateCustomer]);

    return (
        <FormBase  formRef={formRef} onSubmit={onSubmit} defaultValues={props.defaultValues}>
            {(form)=>(
                <FormLayout
                    className='additional-info-container'
                    onDeleted={props.onDeleted}
                    isLoadingButton={isLoadingCreate || isLoadingUpdate}
                    labelSubmitCode={
                        props.defaultValues?.id
                            ? translateCodes.SAVE
                            : translateCodes.CREATE
                    }
                >
                    <Block >
                        <Text fontSize={16} fontWeight={700}
                            lineHeight='24px'
                            translateCode={translateCodes.GENERAL_INFORMATION} />
                        <Spacer type='margin' bottom={8} />
                        <Card bordered={false}>
                            <FlexBox direction='column' gap={16}>
                                <AvatarUpload
                                    translateCode={translateCodes.UPLOAD_PHOTO}
                                    name='avatar'
                                    iconHover={<IconCamera width={20} height={20} />}
                                />
                                <FlexBox gap={16}>
                                    <InputField
                                        rules={customerRules.firstName}
                                        name='firstName'
                                        label={t(translateCodes.FIRST_NAME)}
                                        placeholder={t(translateCodes.FIRST_NAME_PLACEHOLDER)}
                                    />
                                    <InputField
                                        name='lastName'
                                        rules={customerRules.lastName}
                                        label={t(translateCodes.LAST_NAME)}
                                        placeholder={t(translateCodes.LAST_NAME_PLACEHOLDER)}
                                    />
                                </FlexBox>
                                
                                <FlexBox gap={8} direction='column'>
                                    <Spacer>
                                        <Text
                                            fontWeight={600}
                                            lineHeight='20px'
                                            color='#344054'
                                        >
                                            {t(translateCodes.CONTACT_INFORMATION)}
                                        </Text>
                                        <span style={{ color: 'red' }}> *</span>
                                    </Spacer>
                                    <FlexBox gap={16} direction='row'>
                                        <InputField
                                            name='phone'
                                            placeholder={t(translateCodes.PHONE_PLACEHOLDER)}
                                            rules={customerRules.phone}
                                        />
                                        <InputField
                                            name='email'
                                            placeholder={t(translateCodes.EMAIL_PLACEHOLDER)}
                                            rules={customerRules.email}
                                        />

                                    </FlexBox>
                                </FlexBox>
                            </FlexBox>
                        </Card>
                            
                        <Spacer type='margin' bottom={12} top={24}>
                            <a>
                                <FlexBox alignItems='center' onClick={()=>setIsShowMoreDetail(!IsShowMoreDetail)} justifyContent='space-between'>

                                    <Text fontSize={16} fontWeight={700} lineHeight='24px'
                                        translateCode={translateCodes.ADDITIONAL_INFO} />
                                    {IsShowMoreDetail ? 
                                        <IconArrowUpClient/> :  <IconArrowDownClient/>
                                    }
                                </FlexBox>
                            </a>
                        </Spacer>
                        {
                            IsShowMoreDetail && <AdditionalInfo form={form} customer={props.defaultValues} />
                        }
                        <Spacer top={24} />
                    </Block>
                </FormLayout>
            
            )}
            
        </FormBase>
    );
};
