import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { Block, FlexBox } from '../../layouts';
import { AppButton } from '../../buttons';

export interface ColumnAction {
    readonly icon: React.ReactNode;
    readonly onClick?: () => void;
    readonly tooltipTitle: string;
    readonly viewDetailUrl?: string;

}

interface ActionsColumnProps {
	readonly actions: ColumnAction[];
}

export const ActionsColumn = (props: ActionsColumnProps) => {
    const { actions } = props;
    const { t } = useTranslation();
    return (
        <Block id='column-actions'>
            <FlexBox alignItems='center' gap={18}>
                {actions.map((action, index) => {
                    if (action.viewDetailUrl) {
                        return(
                            <Tooltip key={index} placement='top' title={t(action.tooltipTitle)}>
                                <Link to={action.viewDetailUrl} >
                                    <AppButton className='app-button-hover' icon={action.icon} />
                                </Link>
                            </Tooltip>
                        );
                    }
                    return (
                        <Block key={index} onClick={action.onClick}>
                            <Tooltip key={index} placement='top' title={t(action.tooltipTitle)}>
                                <span>
                                    <AppButton className='app-button-hover' icon={action.icon} />
                                </span>
                            </Tooltip>
                        </Block>
                    );
                })}
            </FlexBox>
        </Block>
    );
};
