import React from 'react';
import { IconProps } from '../../Types';

export const IconEmail = (props: IconProps) => {
    return (
        <svg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g filter='url(#filter0_d_3133_93739)'>
                <rect x='2.5' y='1.5' width='39' height='39' rx='7.5' stroke='#EAECF0' shapeRendering='crispEdges' />
                <path d='M4.5 15.9444C4.5 11.5885 4.5 9.4105 5.34773 7.74674C6.09341 6.28326 7.28326 5.09341 8.74674 4.34773C10.4105 3.5 12.5885 3.5 16.9444 3.5H27.0556C31.4115 3.5 33.5895 3.5 35.2533 4.34773C36.7167 5.09341 37.9066 6.28326 38.6523 7.74674C39.5 9.4105 39.5 11.5885 39.5 15.9444V26.0556C39.5 30.4115 39.5 32.5895 38.6523 34.2533C37.9066 35.7167 36.7167 36.9066 35.2533 37.6523C33.5895 38.5 31.4115 38.5 27.0556 38.5H16.9444C12.5885 38.5 10.4105 38.5 8.74674 37.6523C7.28326 36.9066 6.09341 35.7167 5.34773 34.2533C4.5 32.5895 4.5 30.4115 4.5 26.0556V15.9444Z' fill='white' />
                <path d='M29.5647 11.6523L22.0808 17.4929L14.4258 11.6523V11.6539L14.435 11.6618V19.8401L21.9944 25.8069L29.5647 20.0706V11.6523Z' fill='#EA4335' />
                <path d='M31.5298 10.233L29.5645 11.6537V20.0719L35.7489 15.3237V12.4634C35.7489 12.4634 34.9982 8.37822 31.5298 10.233Z' fill='#FBBC05' />
                <path d='M29.5645 20.0724V30.991H34.3045C34.3045 30.991 35.6533 30.8521 35.7504 29.3146V15.3242L29.5645 20.0724Z' fill='#34A853' />
                <path d='M14.4354 30.9985V19.8399L14.4258 19.832L14.4354 30.9985Z' fill='#C5221F' />
                <path d='M14.4252 11.6537L12.4706 10.2409C9.00224 8.38617 8.25 12.4698 8.25 12.4698V15.3301L14.4252 19.832V11.6537Z' fill='#C5221F' />
                <path d='M14.4258 11.6562V19.8346L14.4354 19.8425V11.6641L14.4258 11.6562Z' fill='#C5221F' />
                <path d='M8.25 15.332V29.3225C8.34557 30.8615 9.69591 30.9988 9.69591 30.9988H14.436L14.4252 19.8324L8.25 15.332Z' fill='#4285F4' />
            </g>
            <defs>
                <filter id='filter0_d_3133_93739' x='0' y='0' width='44' height='44' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                    <feOffset dy='1' />
                    <feGaussianBlur stdDeviation='1' />
                    <feComposite in2='hardAlpha' operator='out' />
                    <feColorMatrix type='matrix' values='0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0' />
                    <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_3133_93739' />
                    <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_3133_93739' result='shape' />
                </filter>
            </defs>
        </svg>
    );
};

