import React from 'react';
import {
    AppButton, Text, FlexBox, globalDrawerState,
    globalModalState, TableCustom, useHttpCommand, useHttpQuery,
    usePagination, ColumnActions, useLocalesFormat
} from '@esg/ui';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { managementApis } from '@esg/business-management';
import {  TableColumnsType, Tag } from 'antd';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { dayjs } from '@esg/shared';
import {  CreatePromotionRequest, GetPromotionResponseItem, PromotionConditionRequest, UpdatePromotionRequest } from '@esg/business-management/api/promotion';
import { PageLayout } from '@/layouts';
import { translateCodes } from '@/locales';
import { PromotionForm } from '@/components';

export function PromotionOverview() {
    const { formatDate, formatCurrencySymbol } = useLocalesFormat();
    const [currentLocale] = useRecoilState(currentLocaleState);
    const [, setDrawerState] = useRecoilState(globalDrawerState);
    const [, setModalState] = useRecoilState(globalModalState);
    const resetModalState = useResetRecoilState(globalModalState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);

    const { t } = useTranslation();
    const [searchParams, setSearchParams] = usePagination();

    const {
        data: promotionsResponse,
        isFetching,
        isLoading,
        refetch,
    } = useHttpQuery(managementApis.getAllPromotion, {
        query: searchParams,
    });

    const { data: serviceCategories, isFetching: servicesLoading } = useHttpQuery(managementApis.getAllServiceCategory, undefined, {
        staleTime: 0,
    });

    const { data: productCategories, isFetching: productsLoading } = useHttpQuery(managementApis.getAllProductCategory, undefined, {
        staleTime: 0,
    });

    const { mutateAsync: deleteSegmentAsync } = useHttpCommand(managementApis.deletePromotion);
    const { mutateAsync: createPromotionAsync } = useHttpCommand(managementApis.createPromotion, {
        onSuccess: () => {
            resetModalState();
            resetDrawerState();
            refetch();
        }
    });

    const { mutateAsync: updatePromotionAsync } = useHttpCommand(managementApis.updatePromotion, {
        onSuccess: () => {
            resetModalState();
            resetDrawerState();
            refetch();
        }
    });

    const onDelete = React.useCallback(
        async (id: string) => {
            await deleteSegmentAsync(
                { pathData: { id: id } },
                {
                    onSuccess(_) {
                        resetModalState();
                        resetDrawerState();
                        refetch();
                    },
                }
            );
        },
        [deleteSegmentAsync, refetch, resetModalState, resetDrawerState]
    );

    const showModalDelete = React.useCallback((id: string) => {
        setModalState({
            content: (
                <FlexBox direction='column'>
                    <Text fontSize={18} fontWeight={700}>
                        {t(translateCodes.DELETE_PROMOTION)}
                    </Text>
                    <Text fontSize={14}>
                        {t(translateCodes.DELETE_PROMOTION_MSG)}
                    </Text>
                </FlexBox>
            ),
            isOpen: true,
            onOk: () => {
                onDelete(id);
            },
            showModalDelete: true,
        });
    }, [onDelete, setModalState, t]);

    const onEdit = React.useCallback(
        async (values: GetPromotionResponseItem) => {

            const promotionConditionValue =  values.promotionCondition;

            const promotionCondition = {
                objectType: promotionConditionValue?.objectType || 0,
                numberOfTimes: promotionConditionValue?.numberOfTimes || 0,
                objectMode: promotionConditionValue?.objectMode || 0,
                objectValue: JSON.stringify(promotionConditionValue?.objectValue) 
            } as PromotionConditionRequest;

            const payload: UpdatePromotionRequest['body'] = {
                id: values.id,
                name: values.name,
                code: values.code,
                isActive: values.isActive,
                unit: values.unit,
                value: values.value,
                effectiveDate: values.effectiveDate,
                expirationDate: values.expirationDate,
                promotionCondition: promotionCondition,
            };

            await updatePromotionAsync({ body: payload, pathData: { id: values.id } });
        },
        [updatePromotionAsync]
    );

    const onCreate = React.useCallback(
        async (values: GetPromotionResponseItem) => {
            const promotionConditionValue =  values.promotionCondition;

            const promotionCondition = {
                objectType: promotionConditionValue?.objectType || 0,
                numberOfTimes: promotionConditionValue?.numberOfTimes || 0,
                objectMode: promotionConditionValue?.objectMode || 0,
                objectValue: JSON.stringify(promotionConditionValue?.objectValue) 
            } as PromotionConditionRequest;

            const payload: CreatePromotionRequest['body'] = {
                name: values.name,
                code: values.code,
                isActive: values.isActive,
                unit: values.unit,
                value: values.value,
                effectiveDate: values.effectiveDate,
                expirationDate: values.expirationDate,
                promotionCondition: promotionCondition,
            };
            await createPromotionAsync({ body: payload });
        }, [createPromotionAsync]
    );

    const onShowModalEdit = React.useCallback((values: GetPromotionResponseItem) => {
        setDrawerState({
            isOpen: true,
            titleTransCode: 'Edit Promotion',
            content: (
                <PromotionForm
                    onSubmit={onEdit}
                    refetch={refetch}
                    resetDrawerState={resetDrawerState}
                    defaultValues={values}
                    serviceCategories={serviceCategories?.items}
                    productCategories={productCategories?.items}
                />
            ),
        });
    }, [onEdit, productCategories?.items, refetch, resetDrawerState, serviceCategories?.items, setDrawerState]
    );

    const onShowModalCreate = React.useCallback(() => {
        setDrawerState({
            isOpen: true,
            titleTransCode: 'Create new promotion',
            content: (
                <PromotionForm
                    onSubmit={onCreate}
                    refetch={refetch}
                    resetDrawerState={resetDrawerState}
                    serviceCategories={serviceCategories?.items}
                    productCategories={productCategories?.items}
                />
            ),
        });
    }, [onCreate, productCategories?.items, refetch, resetDrawerState, serviceCategories?.items, setDrawerState]
    );

    const columns: TableColumnsType<GetPromotionResponseItem> = [
        {
            title: 'Code',
            dataIndex: 'description',
            key: 'description',
            render: (_, record) => {
                return <Text maxWidth={300} ellipsis
                >{record.code}</Text>;
            },
        },
        {
            title: 'Promotion name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return <Text maxWidth={300} ellipsis
                >{record.name}</Text>;
            }
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount',
            render: (_, record) => {
                return <Text >{`${record.value} ${record.unit === 0 ? '%': formatCurrencySymbol()}`}</Text>;
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: () => {
                return <Text>Service</Text>;
            },
        },
        {
            title: 'Effective date',
            dataIndex: 'effectiveDate',
            key: 'effectiveDate',
            render: (_, record) => {
                return <Text>{formatDate(dayjs(record.effectiveDate), `${currentLocale?.format}`)} { }</Text>;
            },
        },
        {
            title: 'Expiration date',
            dataIndex: 'expirationDate',
            key: 'expirationDate',
            render: (_, record) => {
                return <Text>{formatDate(dayjs(record.expirationDate), `${currentLocale?.format}`)} { }</Text>;
            },
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (_, record) => {
                if(record.isActive)
                {
                    return <Tag color='success'>Active</Tag>;
                }

                return <Tag color='default'>Inactive</Tag>;
            },
        },
        
        {
            title: t(translateCodes.ACTION),
            key: 'action',
            render: (_, record) => {
                return (
                    <ColumnActions
                        onClickDelete={()=>{showModalDelete(record.id);}}
                        onClickEdit={() => onShowModalEdit(record)}
                    />
                );
            },
        },
    ];

    return (
        <PageLayout
            pageTitleTransCode={t(translateCodes.CLIENT_PROMOTION)}
            rightHeaderPage={
                <AppButton
                    icon={<PlusOutlined />}
                    translateCode={t(translateCodes.CREATE_PROMOTION)}
                    type='primary'
                    onClick={onShowModalCreate}
                />
            }
        >
            <TableCustom
                onPageChange={(page) =>
                    setSearchParams({ ...searchParams, page: page.toString() })
                }
                onSearch={(value) =>
                    setSearchParams({ ...searchParams, search: value })
                }
                loading={isFetching || isLoading || servicesLoading || productsLoading }
                titleTableTransCode='Promotion List'
                placeholderSearchTransCode='Search by promotion name'
                columns={columns}
                dataSource={promotionsResponse?.items}
                pageInfo={promotionsResponse?.meta}
                emptyOption={{
                    title: t(translateCodes.ADD_PROMOTION),
                    description: t(translateCodes.EMPTY_PROMOTION_DESCRIPTION),
                    actionButton: (
                        <AppButton
                            icon={<PlusOutlined />}
                            translateCode={t(translateCodes.CREATE_PROMOTION)}
                            type='primary'
                            onClick={onShowModalCreate}
                        />
                    )
                }}
            />
        </PageLayout>
    );
}
