import { PlusOutlined } from '@ant-design/icons';
import { AppButton, globalDrawerState } from '@esg/ui';
import React, { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useQueryClient } from '@tanstack/react-query';
import { getAllUser } from '@esg/business-account';
import { viewRequests } from '@esg/framework';
import { useTranslation } from 'react-i18next';
import { translateCodes } from '@/locales';
import { UserForm } from '@/components';

export default function CreateUserButton() {
    const [, setGlobalDrawer] = useRecoilState(globalDrawerState);
    const resetGlobalDrawer = useResetRecoilState(globalDrawerState);
    const queryClient = useQueryClient();
    const {t} = useTranslation();

    const handleCreateUser = useCallback(() => {
        setGlobalDrawer({
            isOpen: true,
            titleTransCode: translateCodes.CREATE_USER,
            content: <UserForm 
                onSuccess={() => {
                    resetGlobalDrawer();
                    queryClient.refetchQueries({
                        queryKey: [getAllUser.method, getAllUser.baseUrl, getAllUser.path],
                    });
                    viewRequests.showNotification.send({
                        type: 'success',
                        message: t(translateCodes.NM_ACC_01),
                    });
                }}
            />
        });
    },[queryClient, resetGlobalDrawer, setGlobalDrawer, t]);
    
    return (
        <AppButton
            translateCode={translateCodes.CREATE_USER}
            icon={<PlusOutlined />}
            type='primary'
            onClick={handleCreateUser}
        />
    );
}
