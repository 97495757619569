import { GetDetailSaleResponse, SaleDetailGetAllResponseItem, SaleStatus } from '@esg/business-pos';
import { AppButton, IconArrangement } from '@esg/ui';
import React from 'react';

interface ArrangementBtnProps {
    sale: GetDetailSaleResponse;
    readonly showModalEditMultipleSaleDetail: (sale: GetDetailSaleResponse, saleDetails?: SaleDetailGetAllResponseItem[]) => void
}

export default function ArrangementBtn(props: ArrangementBtnProps) {
    const {sale, showModalEditMultipleSaleDetail} = props;

    const saleDetails = sale.saleDetails;
    const isDisabled = sale?.status !== SaleStatus.Unpaid || saleDetails?.length === 0;
    return (
        <AppButton
            width={44}
            onClick={()=>{showModalEditMultipleSaleDetail(sale, saleDetails);}}
            icon={<IconArrangement opacity={isDisabled? 0.6 : 1}/>}
            disabled={isDisabled}
        />
    );
}
