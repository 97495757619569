import React from 'react';
import { AppButton, Text, FlexBox, globalDrawerState, globalModalState, TableCustom, useHttpCommand, useHttpQuery, usePagination, ColumnActions, useLocalesFormat, managementProfileState} from '@esg/ui';
import { LoadingOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { managementApis } from '@esg/business-management';
import { Avatar, TableColumnsType } from 'antd';
import { createSegment, CreateSegmentRequest, GetSegmentResponseItem, SegmentConditionCreateItem, updateSegment, UpdateSegmentRequest } from '@esg/business-management/api/segment';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { dayjs } from '@esg/shared';

import { PageLayout } from '@/layouts';
import { translateCodes } from '@/locales';
import { SegmentForm } from '@/components';

interface SegmentOverviewProps {
    handleViewDetail: (segmentDetailId: string) => void;
}

export function SegmentOverview({ handleViewDetail }: SegmentOverviewProps) {
    const { formatDate } = useLocalesFormat();
    const [currentLocale] = useRecoilState(currentLocaleState);
    const [currentProfile] = useRecoilState(managementProfileState);
    const [, setDrawerState] = useRecoilState(globalDrawerState);
    const [, setModalState] = useRecoilState(globalModalState);
    const resetModalState = useResetRecoilState(globalModalState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);

    const {t} = useTranslation();
    const [searchParams, setSearchParams] = usePagination();

    const {
        data: segmentsResponse,
        isFetching,
        isLoading,
        refetch,
    } = useHttpQuery(managementApis.getAllSegments, {
        query: searchParams,
    });

    const { data: serviceCategories, isFetching: servicesLoading } = useHttpQuery(managementApis.getAllServiceCategory, undefined, {
        staleTime: 0,
    });
    
    const { data: productCategories, isFetching: productsLoading } = useHttpQuery(managementApis.getAllProductCategory, undefined, {
        staleTime: 0,
    });

    const { mutateAsync : deleteSegmentAsync } = useHttpCommand(managementApis.deleteSegment);
    const { mutateAsync: createSegmentAsync } = useHttpCommand(createSegment, {
        onSuccess: () => {
            resetModalState();
            resetDrawerState();
            refetch();
        }
    });

    const { mutateAsync: updateSegmentAsync } = useHttpCommand(updateSegment, {
        onSuccess: () => {
            resetModalState();
            resetDrawerState();
            refetch();
        }
    });

    const onDelete = React.useCallback(
        async (id: string) => {
            await deleteSegmentAsync(
                { pathData: { id: id } },
                {
                    onSuccess(_) {
                        resetModalState();
                        resetDrawerState();
                        refetch();
                    },
                }
            );
        },
        [deleteSegmentAsync, refetch, resetModalState, resetDrawerState]
    );

    const showModalDelete = React.useCallback((id: string) => {
        setModalState({
            content: (
                <FlexBox direction='column'>
                    <Text fontSize={18} fontWeight={700}>
                        {t(translateCodes.DELETE_SEGMENT)}
                    </Text>
                    <Text fontSize={14}>
                        {t(translateCodes.DELETE_SEGMENT_MSG)}
                    </Text>
                </FlexBox>
            ),
            isOpen: true,
            onOk: () => {
                onDelete(id);
            },
            showModalDelete: true,
        });
    }, [onDelete, setModalState, t]);

    const onEdit = React.useCallback(
        async (values: GetSegmentResponseItem) => {
            const payload: UpdateSegmentRequest['body'] = {
                id: values.id,
                name: values.name,
                description: values.description,
                isAutoUpdate: values.isAutoUpdate,
                operator: values.operator || 0,
            };

            await updateSegmentAsync({ body: payload, pathData: {id: values.id} });
        },
        [updateSegmentAsync]
    );

    const onCreate = React.useCallback(
        async (values: GetSegmentResponseItem) => {
            const segmentConditionItems = values.segmentConditions?.filter(c => c.code != undefined);
            const segmentConditionCreateItems: SegmentConditionCreateItem[] = segmentConditionItems?.map(c => ({
                name: c.name,
                code: c.code,
                value: JSON.stringify(c.value),
            })) || [];

            const payload: CreateSegmentRequest['body'] = {
                name: values.name,
                description: values.description,
                isAutoUpdate: values.isAutoUpdate,
                operator: values.operator || 0,
                segmentConditions: segmentConditionCreateItems
            };
            await createSegmentAsync({ body: payload });
        }, [createSegmentAsync]
    );

    const onShowModalEdit = React.useCallback((values: GetSegmentResponseItem) => {
        setDrawerState({
            isOpen: true,
            titleTransCode: 'Edit client segment',
            content: (
                <SegmentForm
                    onSubmit={onEdit}
                    refetch={refetch}
                    resetDrawerState={resetDrawerState}
                    defaultValues={values}
                    serviceCategories={serviceCategories?.items}
                    productCategories={productCategories?.items}
                />
            ),
        });
    }, [onEdit, productCategories?.items, refetch, resetDrawerState, serviceCategories?.items, setDrawerState]
    );

    const onShowModalCreate = React.useCallback(() => {
        setDrawerState({
            isOpen: true,
            titleTransCode: 'Create new segment',
            content: (
                <SegmentForm
                    onSubmit={onCreate}
                    refetch={refetch}
                    resetDrawerState={resetDrawerState}
                    serviceCategories={serviceCategories?.items}
                    productCategories={productCategories?.items}
                />
            ),
        });
    }, [onCreate, productCategories?.items, refetch, resetDrawerState, serviceCategories?.items, setDrawerState]
    );

    const columns: TableColumnsType<GetSegmentResponseItem> = [
        {
            title: 'Segment name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (_, record) => {
                return <Text 
                    cursor='pointer' 
                    color='#004EEB' 
                    fontSize={14} 
                    fontWeight={500}
                    onClick={()=>{handleViewDetail(record.id);}}
                >{record.name}</Text>;
            }
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (_, record) => {
                return <Text maxWidth={300} ellipsis>{record.description || '-' }</Text>;
            },
        },
        {
            title: 'Number of clients',
            dataIndex: 'numberOfClients',
            key: 'numberOfClients',
            render: (_, record) => {
                if(!record.status)
                {
                    return <LoadingOutlined />;
                }
                
                return <Text >{record.numberOfClients}</Text>;
            },
        },
        {
            title: 'Create date',
            dataIndex: 'creationTime',
            key: 'creationTime',
            render: (_, record) => {
                return <Text>{formatDate(dayjs(record.creationTime), `${currentLocale?.format}`)} {}</Text>;
            },
        },
        {
            title: 'Create by',
            dataIndex: 'createBy',
            key: 'createBy',
            render: (_, record) => {
                return <FlexBox gap={12} alignItems='center'>
                    <Avatar size={32} shape='circle' src={currentProfile?.avatar} icon={<UserOutlined />} />
                    <Text>{record.createBy}</Text>
                </FlexBox>;
            },
        },
        {
            title: t(translateCodes.ACTION),
            key: 'action',
            render: (_, record) => {
                return (
                    <ColumnActions
                        onClickDelete={() => {
                            showModalDelete(record.id);
                        }}
                        onClickEdit={() => onShowModalEdit(record)}
                    />
                );
            },
        },
    ];

    return (
        <PageLayout
            pageTitleTransCode={t(translateCodes.CLIENT_SEGMENT)}
            rightHeaderPage={
                <AppButton
                    icon={<PlusOutlined />}
                    translateCode={t(translateCodes.CREATE_SEGMENT)}
                    type='primary'
                    onClick={onShowModalCreate}
                />
            }
        >
            <TableCustom
                onPageChange={(page) =>
                    setSearchParams({ ...searchParams, page: page.toString() })
                }
                onSearch={(value) =>
                    setSearchParams({ ...searchParams, search: value })
                }
                loading={isFetching || isLoading || servicesLoading || productsLoading}
                titleTableTransCode='Segment List'
                placeholderSearchTransCode='Search by segment name'
                columns={columns}
                dataSource={segmentsResponse?.items}
                pageInfo={segmentsResponse?.meta}
                emptyOption={{
                    title: t(translateCodes.ADD_SEGMENT),
                    description: t(translateCodes.EMPTY_SEGMENT_DESCRIPTION),
                    actionButton: (
                        <AppButton
                            icon={<PlusOutlined />}
                            translateCode={t(translateCodes.CREATE_SEGMENT)}
                            type='primary'
                            onClick={onShowModalCreate}
                        />
                    )
                }}
            />
        </PageLayout>
    );
}
