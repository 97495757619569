import {  HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface DeletePromotionRequest extends HttpRequestData {
}

export const deletePromotion = httpUtil.createHttpRequestMeta<
    DeletePromotionRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/promotions/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
