import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';
import { GetSegmentResponseItem } from './getAllSegments';

export interface UpdateSegmentRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        name: string;
        description: string;
        isAutoUpdate?: boolean;
        operator?: number;
    };
}

export const updateSegment = httpUtil.createHttpRequestMeta<
    UpdateSegmentRequest,
    GetSegmentResponseItem
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/segments/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
