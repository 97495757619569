
import React from 'react';
import { IconProps } from '../../Types';

export const IconTerminateCustomer = (props: IconProps) => {
    return (
        <svg 
            width='28' 
            height='28' 
            viewBox='0 0 28 28' 
            fill='none' 
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path d='M0.5 14C0.5 6.54416 6.54416 0.5 14 0.5C21.4558 0.5 27.5 6.54416 27.5 14C27.5 21.4558 21.4558 27.5 14 27.5C6.54416 27.5 0.5 21.4558 0.5 14Z' fill='#F9FAFB' />
            <path d='M0.5 14C0.5 6.54416 6.54416 0.5 14 0.5C21.4558 0.5 27.5 6.54416 27.5 14C27.5 21.4558 21.4558 27.5 14 27.5C6.54416 27.5 0.5 21.4558 0.5 14Z' stroke='#EAECF0' />
            <path 
                d='M17 11L11 17M11 11L17 17' 
                stroke='#667085' 
                strokeWidth='1.5' 
                strokeLinecap='round' 
                strokeLinejoin='round' 
            />
        </svg>

    );
};
