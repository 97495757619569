import {
    Text,
    FlexBox,
    SwitchField,
    IconEmail,
    IconSms
} from '@esg/ui';
import React from 'react';
import { Card } from 'antd';
import { CampaignChannelItem, CampaignChannelType } from '@esg/business-management/api/campaign';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { DefaultOptionType } from 'antd/es/cascader';

interface ContentCampaignFormProps {
    readonly isUpdate?: boolean;
    form: UseFormReturn<FieldValues, unknown, FieldValues | undefined>;
    segmentOptions?: DefaultOptionType[]; 
    promotionOptions?: DefaultOptionType[];
}

export const ContentCampaignForm = (props: ContentCampaignFormProps) => {
    const {form} = props;
    const emailChannelKey = form.watch('campaignChannels').findIndex((item: CampaignChannelItem) => item.type === CampaignChannelType.Mail);
    const smsChannelKey = form.watch('campaignChannels').findIndex((item: CampaignChannelItem) => item.type === CampaignChannelType.Sms);

    return (
        <FlexBox direction='column' gap={8}>
            <Text
                fontWeight={600}
                lineHeight='20px'
                color='#344054'
            >
                Channels
            </Text>
            <Card className='card-body'  >
                <FlexBox alignItems='center' justifyContent='space-between' gap={8}>
                    <FlexBox alignItems='center' justifyContent='space-between' gap={8}>
                        <IconEmail/>
                        <FlexBox direction='column' gap={2}>
                            <Text
                                fontWeight={600}
                                lineHeight='20px'
                                color='#344054'
                            >
                                Email
                            </Text>
                            <Text
                            >
                                Reach customers via email
                            </Text>
                        </FlexBox>
                    </FlexBox>
                    <SwitchField  
                        className='switch-channel'
                        name={`campaignChannels[${emailChannelKey}].isActive`}
                        containerStyle={
                            {
                                flex: 'none'
                            }
                        }
                    />
                </FlexBox>
            </Card>
            <Card className='card-body'  >
                <FlexBox alignItems='center' justifyContent='space-between' gap={8}>
                    <FlexBox alignItems='center' justifyContent='space-between' gap={8}>
                        <IconSms/>
                        <FlexBox direction='column' gap={2}>
                            <Text
                                fontWeight={600}
                                lineHeight='20px'
                                color='#344054'
                            >
                                Sms
                            </Text>
                            <Text
                            >
                                Reach customers via sms
                            </Text>
                        </FlexBox>
                    </FlexBox>
                    <SwitchField  
                        className='switch-channel'
                        name={`campaignChannels[${smsChannelKey}].isActive`}
                        containerStyle={
                            {
                                flex: 'none'
                            }
                        }
                    />
                </FlexBox>
            </Card>
        </FlexBox>
    );
};