import './EmployeeMembers.scss';
import { EmployeeGetDetailResponse, EmployeeUpdateRequest, resourceApis } from '@esg/business-resource-management';
import { globalDrawerState, globalModalState } from '@esg/ui/state';
import { Segmented, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import {
    AppAvatar,
    Block,
    ColumnActions,
    FlexBox,
    Spacer,
    TableCustom,
    Text,
    useHttpQuery,
    usePagination,
} from '@esg/ui';
import { JOB_STATUS } from '@esg/shared';
import { CreateEmployeeBtn } from './children';
import { EmployeeDeleteConfirm, useEmployeeDelete } from './@share';
import { EmployeeEditForm } from './employeeDetail/children/EmployeeEditForm';
import { translateCodes } from '@/locales';
import { PageLayout } from '@/layouts/page-layout';
import { RESOURCE_MANAGEMENT_EMPLOYEE_MEMBER_URL } from '@/configs';

enum Tabs {
    EmployeeActive,
    EmployeeSeparated,
}

export const EmployeeMembers = () => {
    const { t } = useTranslation();

    const [searchParams, setSearchParams] = usePagination();
    const [active, setActive] = useState<Tabs>(Tabs.EmployeeActive);

    const [, setModalState] = useRecoilState(globalModalState);
    const [,setDrawerState] = useRecoilState(globalDrawerState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);

    const { data, refetch, isLoading } = useHttpQuery(resourceApis.getAllEmployees, {
        query: {
            ...searchParams,
            includeSeparated: true,
        },
    });

    const dataEmployeeActive = data?.items.filter(s => s.jobStatus === JOB_STATUS.ACTIVE);
    const dataEmployeeSeparated = data?.items.filter(s => !dataEmployeeActive?.includes(s));
    const countEmployee = active === Tabs.EmployeeActive ? dataEmployeeActive?.length : dataEmployeeSeparated?.length || 0;
    const dataEmployee = active === Tabs.EmployeeActive ? dataEmployeeActive : dataEmployeeSeparated;

    const onDelete = useEmployeeDelete(refetch);
    const handleDelete = React.useCallback(
        (employee: EmployeeGetDetailResponse) => {
            setModalState({
                content: (
                    <EmployeeDeleteConfirm {...{ employee }} />
                ),
                isOpen: true,
                onOk: () => {
                    onDelete(employee.id);
                },
                showModalDelete: true,
            });
        },
        [onDelete, setModalState]
    );

    const onEdit = React.useCallback(
        (employee: EmployeeGetDetailResponse) => {
            setDrawerState({
                isOpen: true,
                titleTransCode: translateCodes.EDIT_EMPLOYEE,
                content: (
                    <EmployeeEditForm
                        onDeleted={() => handleDelete(employee)}
                        refetch={refetch}
                        defaultValues={employee as EmployeeUpdateRequest['body']}
                    />
                ),
            });
		
        },[handleDelete, refetch, setDrawerState]);

    const employeesColumn: ColumnsType<EmployeeGetDetailResponse> = [
        {
            title: t(translateCodes.EMPLOYEE_ID),
            dataIndex: 'employeeCode',
            key: 'employeeCode',
        },
        {
            title: t(translateCodes.NAME),
            dataIndex: 'fullName',
            key: 'name',
            render: (_, value) => {
                return (
                    <FlexBox justifyContent='flex-start' alignItems='center' gap={8}>
                        <AppAvatar
                            size='md'
                            data={value}
                        />
                        <Text fontWeight='bold' ellipsis maxWidth={200}>{value.fullName}</Text>
                    </FlexBox>
                );
            },
        },
        {
            title: t(translateCodes.EMAIL),
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: t(translateCodes.PHONE),
            dataIndex: 'phone',
            render: (_, item) => {
                return item?.phone || '-';
            },
        },
        {
            title: t(translateCodes.JOB_TYPE),
            key: 'jobType',
            dataIndex: 'jobType',
            render: (_, item) => {
                return item?.jobType?.name || '-';
            },
        },
        {
            title: t(translateCodes.ACTION),
            key: 'action',
            render: (_, record) => {
                return (
                    <ColumnActions
                        viewDetailUrl={`${RESOURCE_MANAGEMENT_EMPLOYEE_MEMBER_URL}/${record.id}`}
                        onClickDelete={() => {
                            handleDelete(record);
                        }}
                        onClickEdit={() => {
                            onEdit(record);
                        }}
                    />
                );
            },
        },
    ];

    return (
        <PageLayout
            pageTitleTransCode={translateCodes.EMPLOYEE_MEMBERS}
            rightHeaderPage={<CreateEmployeeBtn refetchEmployees={()=>{
                refetch();
                resetDrawerState();
            }} />}
            middleHeaderPage={
                <Segmented
                    size='large'
                    value={active}
                    options={[
                        { 
                            value: Tabs.EmployeeActive, 
                            label: <Spacer width={130}>{t(translateCodes.ACTIVE)}</Spacer> },
                        {
                            value: Tabs.EmployeeSeparated,
                            label: <Spacer width={130}>{t(translateCodes.SEPARATED)}</Spacer> 
                        },
                    ]}
                    onChange={(value) => setActive(value as Tabs)}
                />
            }
        >
            <Block className='body'>
                <TableCustom
                    disableFilter={true}
                    loading={isLoading}
                    onPageChange={(page) =>
                        setSearchParams({ ...searchParams, page: page.toString() })
                    }
                    onSearch={(value) => setSearchParams({ ...searchParams, search: value })}
                    titleTableTransCode={translateCodes.EMPLOYEE_LIST}
                    subTitleTable={<Tag>{`${countEmployee} employees`}</Tag>}
                    placeholderSearchTransCode={translateCodes.SEARCH_PLACEHOLDER}
                    dataSource={dataEmployee}
                    columns={employeesColumn}
                    emptyOption={{
                        title: t(translateCodes.ADD_YOURS_EMPLOYEE),
                        description: t(translateCodes.EMPTY_EMPLOYEE_DESCRIPTION),
                        actionButton: (
                            <CreateEmployeeBtn refetchEmployees={()=>{
                                refetch();
                                resetDrawerState();
                            }} />
                        )
                    }}
                />
            </Block>
        </PageLayout>
    );
};
