import React from 'react';
import { IconProps } from '../../Types';

export const IconCancel = (props: IconProps) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            {...props}
        >
            <g clipPath='url(#clip0_2136_51805)'>
                <path d='M4.10866 4.10768L15.892 15.891M18.3337 9.99935C18.3337 14.6017 14.6027 18.3327 10.0003 18.3327C5.39795 18.3327 1.66699 14.6017 1.66699 9.99935C1.66699 5.39698 5.39795 1.66602 10.0003 1.66602C14.6027 1.66602 18.3337 5.39698 18.3337 9.99935Z'
                    stroke={props.color}
                    strokeWidth='1.66667'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_2136_51805'>
                    <rect width='20' height='20' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

IconCancel.defaultProps = {
    className: '',
    onClick: () => { },
    color: '#475467',
};
