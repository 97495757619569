import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';
import { GetSegmentResponseItem } from './getAllSegments';

interface GetSegmentDetailRequest extends HttpRequestData {
    readonly pathData: {
        readonly id: string;
    };
}

export const getDetailSegment = httpUtil.createHttpRequestMeta<GetSegmentDetailRequest, GetSegmentResponseItem>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/segments/:id',
    method: 'GET',
    authentication: 'bearer'
});