import {
    Block,
    FlexBox,
    Spacer,
    Text,
} from '@esg/ui';

import React from 'react';

interface CustomerOverviewItemProps {
    title?: string;
    value?: string;
}

export const CustomerOverviewItem = ({title, value}: CustomerOverviewItemProps) => {

    return (
        <Block>
            <FlexBox direction='column' gap={1}>
                <Spacer height={5}/>
                <Text  fontSize={14} maxWidth={200} translateCode={title} />
                <Text fontSize={18} fontWeight='bold' maxWidth={200}> {value ?? '-'} </Text>
            </FlexBox>
        </Block>
    );
};
