import React, { useEffect } from 'react';
import { dayjs } from '@esg/shared';
import { GetCustomerDetailResponse } from '@esg/business-crm';
import { ColumnAvatarGroup, TableCustom, useHttpQuery, usePagination, Text, Block, ListNameColumn, ActionsColumn, globalModalState, useHttpCommand, IconRefresh, managementProfileState, useLocalesFormat } from '@esg/ui';
import { TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';
import { GetDetailSaleResponse, SaleStatus, resellSale, getAllSale } from '@esg/business-pos';
import { EmployeeGetDetailResponse } from '@esg/business-resource-management';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { translateCodes } from '@/locales';
import useOpenSaleFrom from '@/pages/fast-pos/sale/hook/useOpenSaleFrom';
import { saleEvents } from '@/event/saleEvent';
import { CustomerResellConfirm } from '@/components/customer/CustomerResellConfirm';

interface CustomerSaleTableProps {
    customer: GetCustomerDetailResponse;
    refetchOverview: () => void;
}

export const CustomerSaleTable = ({ customer, refetchOverview }: CustomerSaleTableProps) => {
    const { t } = useTranslation();
    const { formatDate, formatCurrency } = useLocalesFormat();
    const [searchParams, setSearchParams] = usePagination({
        pageSize: '10',
        orderBy: 'code',
    });
    const [, setModalState] = useRecoilState(globalModalState);
    const resetGlobalModal = useResetRecoilState(globalModalState);
    const [currentProfile] = useRecoilState(managementProfileState);
    const { onShowFormSale } = useOpenSaleFrom();

    const branchId = currentProfile?.branch.id;

    const { data: saleResponse, isFetching: isSaleFetching, refetch } = useHttpQuery(getAllSale, {
        query: {
            status: SaleStatus.Paid,
            customerId: customer?.id,
            orderBy: 'code',
            ...searchParams,
        },
    });

    useEffect(() => {
        const clearRefetchSaleList = saleEvents.onRefetchSaleList.listen(() => {
            refetch();
            refetchOverview();
        });

        return () => { clearRefetchSaleList?.(); };
    }, [refetch, refetchOverview]);

    const { mutateAsync: onResell } = useHttpCommand(resellSale, {
        onSuccess(sale) {
            resetGlobalModal();
            onShowFormSale(sale);
        },
    });

    const handleResell = React.useCallback(
        (pos: GetDetailSaleResponse) => {
            const saleCode = pos?.code;
            setModalState({
                content: (
                    <CustomerResellConfirm {...{ saleCode }} />
                ),
                isOpen: true,
                onOk: () => {
                    onResell({
                        body: {
                            branchId: branchId,
                            id: pos.id,
                        }
                    });
                },
                showModalConfirm: true,
            });
        },
        [branchId, onResell, setModalState]
    );

    const saleActions = (sale: GetDetailSaleResponse) => {
        return [
            {
                icon: <IconRefresh />,
                onClick: () => handleResell(sale),
                tooltipTitle: t(translateCodes.RESELL),
            },
        ];

    };

    const isLoading = isSaleFetching;

    const employeeColumn = (sale: GetDetailSaleResponse) => {
        const employees: EmployeeGetDetailResponse[] = [];
        sale.saleDetails?.forEach((saleDetail) => {
            saleDetail.saleDetailEmployees?.forEach((saleDetailEmployee) => {
                if (employees.some(o => o.id === saleDetailEmployee?.employee?.id)) return;
                employees.push(saleDetailEmployee.employee!);
            });
        });

        return (
            <ColumnAvatarGroup
                items={employees}
                maxCount={2}
                prefixEnd={
                    employees?.length === 1 ?
                        employees[0].fullName ?? '' :
                        `${employees?.length} employee`}
            />
        );
    };

    const columns: TableColumnsType<GetDetailSaleResponse> = [
        {
            title: t(translateCodes.ID_SALE),
            dataIndex: 'code',
            key: 'id',
            sorter: true,
            onHeaderCell: () => ({
                onClick: () => {
                    const order = searchParams.orderDirection === 'desc' ? 'asc' : 'desc';
                    setSearchParams({ ...searchParams, orderDirection: order });
                }
            }),
            render: (_, record) => {
                return (
                    <a onClick={() => onShowFormSale(record)}>
                        {record?.code}
                    </a>
                );
            }
        },
        {
            title: t(translateCodes.EMPLOYEES),
            key: 'employee',
            render: (_, record) => employeeColumn(record),
        },
        {
            title: t(translateCodes.DATE_AND_TIME),
            dataIndex: 'date',
            key: 'date',
            render: (_, record) => {
                const createdAt = dayjs(record?.date);
                const checkoutAtTime = formatDate(record?.checkoutAt, 'hh:mm A');
                const createdTime = formatDate(createdAt, 'hh:mm A');
                const createdDate = formatDate(createdAt);

                return (
                    <Block>
                        <p>{createdTime} - {record?.checkoutAt ? checkoutAtTime : ''}</p>
                        <p>{createdDate}</p>
                    </Block>
                );
            }
        },
        {
            title: t(translateCodes.SERVICES_TIME),
            key: 'service',
            render: (_, record) => {
                const names = record?.saleDetails?.map((item) => item?.objectName ?? '');
                return <ListNameColumn names={names} />;
            }
        },
        {
            title: t(translateCodes.TOTAL_AMOUNT),
            key: 'totalAmount',
            dataIndex: 'totalAmount',
            render: (totalAmount) => <Text fontWeight={600}>{formatCurrency(totalAmount)}</Text>,

        },
        {
            title: t(translateCodes.ACTION),
            render: (_, record) => {
                return (
                    <ActionsColumn
                        actions={saleActions(record)}
                    />
                );
            },
        },
    ];

    return (
        <TableCustom
            onPageChange={(page) =>
                setSearchParams({
                    ...searchParams,
                    page: page.toString(),
                })
            }
            // onFilter={()=>{}}
            // onExportExcel ={()=>{}}
            onSearch={(value) =>
                setSearchParams({ ...searchParams, search: value })
            }
            placeholderSearchTransCode={translateCodes.CLIENT_DETAIL_PLACEHOLDER_SEARCH}
            loading={isLoading}
            titleTableTransCode={translateCodes.SALE_LIST}
            columns={columns}
            dataSource={saleResponse?.items ?? []}
            pageInfo={saleResponse?.meta}
        />
    );
};

