import {
    Switch,
    TableColumnsType
} from 'antd';
import React, {useCallback} from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import {
    ColumnActions,
    FlexBox,
    Spacer,
    TableCustom,
    Text,
    globalDrawerState,
    globalModalState,
    useHttpCommand,
    useHttpQuery,
} from '@esg/ui';
import { paymentMethodApi, GetAllPaymentMethodResponseItem, paymentMethods } from '@esg/business-setting';
import { useTranslation } from 'react-i18next';
import { CreatePaymentMethodBtn } from './children';
import { PageLayout } from '@/layouts/page-layout';
import { PaymentMethodForm } from '@/components/form/payment-method';
import { translateCodes } from '@/locales';
import { PaymentMethodImage } from '@/components';
interface UpdatePaymentMethodValues {
    id?: string;
    name: string;
    isActive: boolean;
}

export const PaymentMethod = () => {
    const {t} = useTranslation();
    const [, setGlobalDrawer] = useRecoilState(globalDrawerState);
    const [, setModalGlobalState] = useRecoilState(globalModalState);
    const resetModalState = useResetRecoilState(globalModalState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);

    const { data: bankAccount, refetch } = useHttpQuery(paymentMethodApi.getAllPaymentMethod);
    const { mutateAsync: updatePaymentMethod } = useHttpCommand(paymentMethodApi.updatePaymentMethod);
    const { mutateAsync: deletePaymentMethod } = useHttpCommand(paymentMethodApi.deletePaymentMethod, {
        onSuccess() {
            resetModalState();
            resetDrawerState();
            refetch();
        },
    });

    const showDeleteConfirm = useCallback((id: string) => {
        setModalGlobalState({
            isOpen: true,
            showModalDelete: true,
            content: (
                <Spacer top='xs'>
                    <Text fontSize={18} fontWeight={700}>{t(translateCodes.DELETE_PAYMENT_METHOD)}</Text>
                    <Spacer type='margin' top={8} />
                    <Text>{t(translateCodes.DELETE_PAYMENT_METHOD_MSG)}</Text>
                </Spacer>
            ),
            onOk: async () => {
                await deletePaymentMethod(
                    { pathData: { id: id } },
                );
            },
        });
    },[deletePaymentMethod, setModalGlobalState, t]);
    
    const onEdit = useCallback(
        (values: GetAllPaymentMethodResponseItem) => {
            setGlobalDrawer({
                isOpen: true,
                titleTransCode: translateCodes.EDIT_METHOD,
                content: <PaymentMethodForm 
                    defaultValues={values} 
                    onSuccess={refetch} 
                />,
                footer: null
            });
        },
        [setGlobalDrawer, refetch]
    );

    const onUpdateStatus = useCallback(
        async (request: UpdatePaymentMethodValues) => {
            await updatePaymentMethod(
                {
                    pathData: { id: request.id},
                    body: {
                        id: request.id,
                        name: request.name,
                        isActive: request.isActive
                    }
                },
                {
                    onSuccess(_) {
                        refetch();
                    },
                }
            );
        },
        [refetch, updatePaymentMethod]
    );
    
    const columns: TableColumnsType<GetAllPaymentMethodResponseItem> = [
        {
            title: t(translateCodes.PAYMENT_METHOD),
            dataIndex: 'Payment Method',
            render: (_, record) => {
                return (
                    <>
                        <FlexBox>
                            <PaymentMethodImage paymentMethodName={record.name} />
                            <Spacer right='xs' />
                            {record.name}
                        </FlexBox>
                    </>
                );
            },
        },
        {
            width: 116,
            title: 'Status',
            render: (_, record) => {
                return (
                    paymentMethods.includes(record.name)
                        ?
                        <Switch defaultChecked onChange={(value) => onUpdateStatus({
                            ...record,
                            isActive: value
                        })} value={record.isActive} />
                        :
                        <ColumnActions
                            onClickDelete={() => {
                                showDeleteConfirm(record.id);
                            }}
                            onClickEdit={() => onEdit(record)}
                        />
                );
            },
        },
    ];
    
    return (
        <PageLayout
            pageTitleTransCode={translateCodes.PAYMENT_METHOD}
            rightHeaderPage={<CreatePaymentMethodBtn refetchCreatePaymentMethod={refetch} />}
        >
            <TableCustom
                showHeader={false}
                columns={columns}
                dataSource={bankAccount?.items ?? []}
            />
        </PageLayout>
    );
};
