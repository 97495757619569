import {
    FormBase,
    InputField,
    SwitchField,
    Text,
    FormLayout,
    Spacer,
    FlexBox,
    DatePickerField,
    InputNumberField,
    useLocalesFormat} from '@esg/ui';
import React from 'react';
import { GetAllProductCategoryResponseItem, GetAllServiceCategoryResponseItem } from '@esg/business-management';
import { GetPromotionResponseItem, UnitApply, } from '@esg/business-management/api/promotion';
import { Card, Select } from 'antd';
import { useRecoilState } from 'recoil';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { promotionRules } from '../rules';
import {PromotionConditionForm}  from './PromotionConditionForm';
import { translateCodes } from '@/locales';

interface PromotionFormProps {
    readonly onDelete?: () => void;
    readonly refetch: () => void;
    readonly resetDrawerState: () => void;
    readonly defaultValues?: GetPromotionResponseItem;
    readonly onSubmit?: (value: GetPromotionResponseItem) => void;
    readonly serviceCategories?: GetAllServiceCategoryResponseItem[];
    readonly productCategories?: GetAllProductCategoryResponseItem[];
}

export const PromotionForm = (props: PromotionFormProps) => {
    const [currentLocale] = useRecoilState(currentLocaleState);
    const {formatCurrencySymbol } = useLocalesFormat();
    const {serviceCategories, productCategories} = props;

    return (
        <FormBase
            onSubmit={props.onSubmit}
            defaultValues={{...props.defaultValues}}
        >
            {
                (form) => (
                    <FormLayout
                        labelSubmitCode={
                            props.defaultValues?.id
                                ? translateCodes.SAVE
                                : translateCodes.CREATE
                        }
                    >
                        <Spacer bottom={8}>
                            <Text
                                fontWeight={600}
                                lineHeight='20px'
                                color='#344054'
                            >
                                General information
                            </Text>
                        </Spacer>
                        <Card className='card-body' >
                            <InputField
                                name='name'
                                label='Promotion name'
                                placeholder='Enter segment name'
                                rules={promotionRules.promotionName}
                            />
                            <Spacer bottom={8}/>
                            <FlexBox gap={16}>
                                <InputField
                                    name='code'
                                    label='Promotion code'
                                    placeholder='Automatically generate by system'
                                    disabled={!!props.defaultValues?.id}
                                    rules={props.defaultValues?.id ? promotionRules.promotionName : undefined}
                                />
                                <InputNumberField
                                    style={{ flex: 1 }}
                                    name='value'
                                    label='Discount amount'
                                    placeholder='Enter amount of discount'
                                    addonBefore={<Select
                                        onChange={(value) => {
                                            form.setValue('unit', value, { shouldDirty: true });
                                        }}
                                        value={form.watch('unit')}
                                    >
                                        <Select.Option value={UnitApply.Percent}>%</Select.Option>
                                        <Select.Option value={UnitApply.Amount}>{formatCurrencySymbol()}</Select.Option>
                                    </Select>}
                                    min={0}
                                />
                            </FlexBox>
                            <Spacer bottom={8}/>
                            <FlexBox gap={16}>
                                <DatePickerField
                                    label='Effective date'
                                    placeholder='Select date'
                                    formatValue='day'
                                    format={currentLocale?.format}
                                    name='effectiveDate'
                                    rules={promotionRules.valueRequire}
                                />
                                <DatePickerField
                                    label='Expiration date'
                                    placeholder='Select date'
                                    formatValue='day'
                                    format={currentLocale?.format}
                                    name='expirationDate'
                                    rules={promotionRules.valueRequire}
                                />
                            </FlexBox>
                        </Card>
                        <Spacer bottom={8} top={12}>
                            <Text
                                fontWeight={600}
                                lineHeight='20px'
                                color='#344054'
                            >
                                Condition
                            </Text>
                        </Spacer>
                        <Card className='card-body'>
                            <PromotionConditionForm 
                                form={form}
                                serviceCategories={serviceCategories}
                                productCategories={productCategories}
                            />
                        </Card>
                        <Spacer top={12}>
                            <SwitchField
                                name='isActive'
                                rightDescription='Active'
                            />
                        </Spacer>
                    </FormLayout >
                )
            }

        </FormBase>
    );
};

PromotionForm.defaultProps = {
    defaultValues: {
        unit: UnitApply.Percent,
        promotionCondition: {
            objectMode: 0,
            objectType: 0,
            numberOfTimes: 1,
            objectValue: []
        }
    },
};

