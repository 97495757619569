import React, { useCallback, useEffect, useState } from 'react';
import { createSaleDetail, CreateSaleDetailRequest, getAllSale, SaleDetailGetAllResponseItem, SaleStatus, updateSaleDetail } from '@esg/business-pos';
import { GetAllProductCategoryResponseItem, GetAllServiceCategoryResponseItem } from '@esg/business-management';
import { viewRequests } from '@esg/framework';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { Block, globalModalState, useHttpCommand, useHttpQuery, usePagination } from '@esg/ui';
import { SegmentedValue } from 'antd/es/segmented';
import { dayjs } from '@esg/shared';
import { CreateFastSaleBtn } from '../CreateFastSaleBtn';
import { DailySalesBtn } from '../DailySaleBtn';
import { FastSaleMainContent } from './FastSaleMainContent';
import './FastSaleLayout.scss';
import MenuOrder from '@/components/form/sale/children/menu-order/MenuOrder';
import { InitDataSaleForm } from '@/components/form/sale/SaleForm';
import { currentSaleState } from '@/states';
import { saleHelper } from '@/helpers';
import { translateCodes } from '@/locales';
import { saleEvents } from '@/event/saleEvent';

interface FilterSaleParams {
    readonly employeeIds?: string[];
    readonly date?: string;
    readonly serviceIds?: string[];
    readonly isDisplay: boolean;
}

interface FastSaleContentProps {
    serviceCategories?: GetAllServiceCategoryResponseItem[];
    productCategories?: GetAllProductCategoryResponseItem[];
}

export const FastSaleLayout: React.FC<FastSaleContentProps> = ({
    serviceCategories,
    productCategories
}) => {
    const { t } = useTranslation();
    const [currentSale, setCurrentSale] = useRecoilState(currentSaleState);
    const resetModalState = useResetRecoilState(globalModalState);

    const [activeTabs] = useState<SegmentedValue>(SaleStatus.Unpaid);
    const [paginationParams] = usePagination({
        orderBy: 'creationTime',
    });
    const [filterParams] = useState<FilterSaleParams>({ date: dayjs().startOf('day').toISOString(), isDisplay: true});

    const { data: saleResponse, isFetching, refetch } = useHttpQuery(getAllSale, {
        query: {
            status: activeTabs,
            ...paginationParams,
            ...filterParams,
        },
    });

    const { mutateAsync: createSaleDetailMutate, isPending: loadingCreate } = useHttpCommand(createSaleDetail, {
        onSuccess: (response) => {
            const saleDetails = [...(currentSale?.saleDetails ?? []), response];
            const newSale = saleHelper.updateCurrentSale(currentSale!, saleDetails);
            setCurrentSale(newSale);
        }
    });

    const { mutateAsync: createInvoiceSaleDetailMutate } = useHttpCommand(createSaleDetail, {
        onSuccess: () => {
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.MM_FP_04),
            });

            saleEvents.onRefetchSaleList.emit();
        }
    });

    const onCreateSaleDetail = useCallback(async (saleDetailValue: CreateSaleDetailRequest['body']) => {
        if(!currentSale)
        {
            createInvoiceSaleDetailMutate({ body: saleDetailValue });
            return ;
        }
        
        await createSaleDetailMutate({ body: saleDetailValue });
    }, [createInvoiceSaleDetailMutate, createSaleDetailMutate, currentSale]);

    const { mutateAsync: updateSaleDetailMutate, isPending: loadingUpdate } = useHttpCommand(updateSaleDetail, {
        onSuccess: (response) => {
            resetModalState();
            const saleDetails = currentSale?.saleDetails?.map(o => o.id === response?.id ? response : o) ?? [];
            const newSale = saleHelper.updateCurrentSale(currentSale!, saleDetails);
            setCurrentSale(newSale);
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.MM_FP_02),
            });
        }
    });

    const onUpdateSaleDetail = useCallback(async (saleDetail: SaleDetailGetAllResponseItem) => {
        await updateSaleDetailMutate({ pathData: { id: saleDetail.id }, body: saleDetail });
    }, [updateSaleDetailMutate]);

    const initDataSaleForm = {
        serviceCategories,
        productCategories
    } as InitDataSaleForm;

    const renderActionsView = () => (
        <>
            <DailySalesBtn />
            <CreateFastSaleBtn />
        </>
    );

    useEffect(() => {
        const clearRefetchSaleList = saleEvents.onRefetchSaleList.listen(() => {
            refetch();
        });

        return () => { clearRefetchSaleList?.(); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderFastSaleTabView = () => {
        if (!saleResponse?.items) {
            return null;
        }
        return (
            <FastSaleMainContent
                sales={saleResponse!.items}
                loadingCreate={loadingCreate}
                loadingUpdate={loadingUpdate}
                isFetching={isFetching}
            />
        );
    };

    return (
        <Block className='fast-sale-content-wrapper'>
            <Block className='fast-sale-content-menu-wrapper'>
                <MenuOrder
                    showMenu={true}
                    initDataSaleForm={initDataSaleForm}
                    updateSaleDetail={onUpdateSaleDetail}
                    createSaleDetail={onCreateSaleDetail}
                    width='100%'
                    actions={renderActionsView()}
                    isFastSale={true}
                />
            </Block>
            {renderFastSaleTabView()}
        </Block>
    );
};
