import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { AppButton, useHttpCommand} from '@esg/ui';
import { managementApis } from '@esg/business-management';
import { CustomerForCreateSegmentResponse } from '@esg/business-management/api/customer-segment';

export interface AddSegmentBtnProps{
    readonly segmentId?: string ;
    readonly onShowModalCreate: (data: CustomerForCreateSegmentResponse[]) => void
}

export default function AddSegmentBtn(props: AddSegmentBtnProps) {
    const { mutateAsync: onAddSegmentBtn, isPending } = useHttpCommand(managementApis.getAllCustomerForCreateSegments, {
        onSuccess: (response) => {
            props.onShowModalCreate(response.items);
        }
    });

    const handleCreateSale = async () => {
        await onAddSegmentBtn({
            body: {
                segmentId: props.segmentId || '',
                customerIds: []
            },
            query: {
                pageSize: 20,
                page: 1,
                order: '',
                orderBy: ''
            }
        });
    };

    return (
        <AppButton
            loading={isPending}
            icon={<PlusOutlined />}
            translateCode='Add Client'
            onClick={handleCreateSale}
        />
    );
}
