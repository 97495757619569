import { Block, FormBase, FormLayout, managementProfileState, Spacer, Text, useHttpCommand, WorkingTime } from '@esg/ui';

import { notification, Tabs, TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {useState, useCallback} from 'react';
import { branchApis, BranchGetAllResponseItem } from '@esg/business-organization';
import { PageLayout } from '@/layouts';
import { translateCodes } from '@/locales';
import { BranchFormSettingFooter, BranchGeneralSettingForm } from '@/components';

export const BranchSetting = () => {
    const { t } = useTranslation();
    const [currentProfile] = useRecoilState(managementProfileState);

    const [tabActive, SetTabActive] = useState('general');

    const { mutateAsync: editBranch, isPending } = useHttpCommand(
        branchApis.updateBranch,
        {
            onSuccess() {
                window.location.reload();
            },
            onError() {
                notification.error({
                    message: t(translateCodes.BRANCH_UPDATE_FAILURE),
                    placement: 'bottomLeft'
                });
            }
        }
    );

    const handleUpdateBranch = useCallback(
        async (data: BranchGetAllResponseItem) => {
            await editBranch({
                pathData: {
                    id: currentProfile?.branch.id,
                },
                body: {
                    ...data,
                    organizationId: currentProfile?.organization.id,
                },
            });
        },
        [currentProfile, editBranch]
    );

    const tabItems: TabsProps['items'] = [
        {
            key: 'general',
            label: (
                <Spacer>{t(translateCodes.GENERAL)}</Spacer>
            ),
        },
        {
            key: 'workingTime',
            label: t(translateCodes.WORKING_TIME),
        },
    ];

    return (
        <PageLayout
            pageTitleTransCode={translateCodes.BRANCH_SETTING_NAME}
        >
            <Tabs 
                defaultActiveKey='general' 
                items={tabItems} 
                style={{ width: '100%' }}
                onChange={(value) => SetTabActive(value)}
            />
            <FormBase 
                onSubmit={handleUpdateBranch} 
                defaultValues={{
                    ...currentProfile?.branch,
                    languages: currentProfile?.branch.languages ?? 'en',
                    countryCode: currentProfile?.branch.countryCode ?? 'US',
                }}
            >
                {(form) => (
                    <FormLayout
                        width='100%'
                        labelSubmitCode='Update'
                        footer={<BranchFormSettingFooter loading={isPending}/>}
                        className='branchSettingForm'
                    > 
                        {tabActive == 'general' && <BranchGeneralSettingForm/>}
                        {tabActive == 'workingTime' && (  
                            <>
                                <Text fontSize={18} fontWeight={600} className='workingTimeTitle'>Business Hour<span style={{ color: '#ff0000'}}> *</span></Text>
                                <Spacer height={16}/>
                                <Block className='workingTimeTable'>
                                    <WorkingTime
                                        name='businessHour'
                                        control={form.control}
                                        watch={form.watch}
                                        useAddBreakTime={false}
                                    />
                                </Block>
                            </>
                        )}
                    </FormLayout>)}
            </FormBase>
        </PageLayout>
         
    );
};
