import React from 'react';

import { IconProps } from '../../Types';

export const IconTrashActionCustomer = (props: IconProps) => {
    return (
        <svg onClick={props.onClick} width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M2.39637 15.0964C2.43466 14.7518 2.4538 14.5795 2.50594 14.4185C2.55219 14.2756 2.61753 14.1397 2.70021 14.0143C2.79339 13.873 2.91596 13.7504 3.16112 13.5053L14.1664 2.50005C15.0868 1.57957 16.5792 1.57957 17.4997 2.50005C18.4202 3.42052 18.4202 4.91291 17.4997 5.83338L6.49445 16.8386C6.2493 17.0838 6.12672 17.2063 5.98541 17.2995C5.86005 17.3822 5.72408 17.4475 5.58121 17.4938C5.42018 17.5459 5.24789 17.5651 4.90331 17.6033L2.08301 17.9167L2.39637 15.0964Z' stroke='#667085' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round'/>
        </svg>
    );
};
