import { FlexBox, FormBase, FormLayoutModal, InputNumberField, Text, useLocalesFormat } from '@esg/ui';
import React from 'react';
import { DiscountUnit, SaleDetailGetAllResponseItem, SaleDetailEmployee } from '@esg/business-pos/api/sale-detail';
import { Select } from 'antd/lib';
import { useForm } from 'react-hook-form';
import { EmployeeGetDetailResponse } from '@esg/business-resource-management';
import { EmployeeSelectField } from '@/components/form/employee/children/employee-select/EmployeeSelectField';
import { translateCodes } from '@/locales';
import './EditSaleDetailForm.scss';

interface EditSaleDetailFormProps {
    readonly employees?: EmployeeGetDetailResponse[];
    readonly defaultValues: SaleDetailGetAllResponseItem;
    readonly handleSubmit?: (value: SaleDetailGetAllResponseItem) => void;
    readonly onCancel?: () => void;
    readonly isLoading?: boolean;
}

export default function EditSaleDetailForm(props: EditSaleDetailFormProps) {
    const { employees = [] } = props;
    const { formatCurrencySymbol } = useLocalesFormat();
    const addonBeforeDiscount = (form: ReturnType<typeof useForm>) => {
        return (
            <Select 
                onChange={(value) => form.setValue('discountUnit', value, {
                    shouldDirty: true
                })}
                value={form.watch('discountUnit')}
            >
                <Select.Option value={DiscountUnit.Percent}><Text maxWidth={50}>%</Text></Select.Option>
                <Select.Option value={DiscountUnit.Amount}><Text maxWidth={50}>{formatCurrencySymbol()}</Text></Select.Option>
            </Select>
        );
    };

    return (
        <FormBase 
            onSubmit={props.handleSubmit} defaultValues={{
                ...props.defaultValues,
                discountUnit: props.defaultValues?.discountUnit ?? DiscountUnit.Percent
            }}
        >
            {(form) => (
                <FormLayoutModal 
                    useSubmitButton={true}
                    onCancel={props.onCancel}
                    okTextCode={translateCodes.SAVE}
                    isLoadingButton={props.isLoading}

                >
                    <FlexBox direction='column' gap={16}>
                        <EmployeeSelectField
                            mode='multiple'
                            name='saleDetailEmployees'
                            type='default'
                            label='Employee'
                            value={form.watch('saleDetailEmployees')?.map((o:SaleDetailEmployee) => o.employeeId)}
                            handleChangedEmployee={(value) => {
                                const employeeIds = Array.isArray(value) ? value : [value];
                                form.setValue('saleDetailEmployees', employeeIds.map(o => ({
                                    employeeId: o
                                })));
                            }} 
                            employeeOptions={employees?.map(o=> ({
                                ...o,
                                label: o.fullName,
                                value: o.id,
                            }))}
                        />
                        <FlexBox gap={16}>
                            <InputNumberField
                                style={{ flex: 1 }}
                                name='quantity'
                                min={1}
                                label='Quantity'
                            />
                            <InputNumberField
                                style={{ flex: 1 }}
                                name='objectPrice'
                                label='Price'
                                min={0}
                                addonBefore={formatCurrencySymbol()}
                            />
                        </FlexBox>
                        <InputNumberField
                            style={{ flex: 1 }}
                            name='discount'
                            min={0}
                            label='Discount'
                            addonBefore={addonBeforeDiscount(form)}
                        />
                    </FlexBox>
                </FormLayoutModal>
            )}
        </FormBase>
    );
}
