import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

export interface CreateCustomerSegmentRequest extends HttpRequestData {
    readonly body: {
        customerIds?: CustomerSegmentCreateItem[];
        segmentId: string
    };
}

export interface CustomerSegmentCreateItem {
    customerIds?: string[];
}

export const createCustomerSegment = httpUtil.createHttpRequestMeta<
    CreateCustomerSegmentRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/customer-segments',
    method: 'POST',
    authentication: 'bearer',
});

