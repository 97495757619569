import { GetAllServiceResponseItem } from '@esg/business-management';
import { CreateSaleDetailRequest, GetDetailSaleResponse } from '@esg/business-pos';
import { FlexBox, managementProfileState, Text, useLocalesFormat } from '@esg/ui';
import { Card } from 'antd';
import React, {useCallback, useMemo} from 'react';
import { useRecoilState } from 'recoil';

interface MenuServiceItemProps {
    readonly service?: GetAllServiceResponseItem;
    readonly onOrderItem?: (saleDetailValue: CreateSaleDetailRequest['body']) => void;
    readonly sale: GetDetailSaleResponse;
}

export default function MenuServiceItem(props: MenuServiceItemProps) {
    const [currentProfile] = useRecoilState(managementProfileState);
    const { formatCurrency } = useLocalesFormat();
    const { service, onOrderItem, sale } = props;

    const serviceBranch = useMemo(
        () =>
            service?.serviceBranches.find(
                (o) => o.branchId === currentProfile?.branch.id
            ),
        [currentProfile, service]
    );

    const priceService = serviceBranch?.price ?? 0;

    const handleOrderItem = useCallback(
        (service?: GetAllServiceResponseItem) => {
            const taxAmount = priceService * (serviceBranch?.taxSetting?.value ?? 0) / 100;

            if (!service) return;

            onOrderItem?.({
                objectId: service.id,
                objectType: 'SERVICE',
                quantity: 1,
                objectPrice: priceService,
                objectPriceOrigin: priceService,
                objectName: service.name,
                totalAmount: priceService,
                saleId: sale?.id,
                serviceId: service.id,
                taxAmount: taxAmount,
            });
        },
        [onOrderItem, priceService, sale?.id, serviceBranch?.taxSetting?.value]
    );

    return (
        <Card
            size='small'
            bordered={true}
            key={service?.id}
            onClick={() => handleOrderItem(service)}
            className='cursor-pointer menu-order-item-slide'
            
        >
            <FlexBox
                justifyContent='space-between'
                flex={1}
            >
                <FlexBox direction='column' flex={1}>
                    <FlexBox gap={8}>
                        <Text
                            fontSize={14}
                            fontWeight={600}
                            textTransform='capitalize'
                        >
                            {service?.name}
                        </Text>
                    </FlexBox>
                    <FlexBox gap={8}>
                        <Text>{service?.duration} mins</Text>
                    </FlexBox>
                </FlexBox>
                <FlexBox
                    direction='column'
                    alignItems='end'
                    gap={8}
                    flex='none'
                >
                    <Text fontWeight={600}>{formatCurrency(priceService)}</Text>
                </FlexBox>
            </FlexBox>
        </Card>
    );
}
