import {
    AppAvatar,
    AvatarItem,
    Block,
    ColumnAvatarGroup,
    FlexBox,
    SelectField,
    Text,
    TextProps,
} from '@esg/ui';
import React, { useCallback } from 'react';
import { BaseOptionType, DefaultOptionType } from 'antd/es/select';
import { Checkbox, Tooltip } from 'antd';
import { ExclamationCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SelectProps } from 'antd/lib';
import { useTranslation } from 'react-i18next';
import { DisplayValueType } from 'rc-select/lib/BaseSelect';
import { UseControllerProps } from 'react-hook-form';
import { LabelInValueType } from 'rc-select/lib/Select';
import { translateCodes } from '@/locales';
import './EmployeeSelectField.scss';

export interface EmployeeOptionsProps {
  value: string;
  label: string;
  avatar?: string;
  isNoProvider?: boolean;
  isOverlap?: boolean;
}
interface EmployeeSelectFieldProps extends SelectProps {
  name: string;
  label?: string;
  labelStyle?: TextProps;
  type?: 'Appointment' | 'default';
  optionRender?: (option: BaseOptionType) => React.ReactNode;
  handleChangedEmployee?: (value: string[]) => void;
  width?: string | number;
  maxCountAvatar?: number;
  employeeOptions?: EmployeeOptionsProps[];
  allEmployee?: boolean;
  rules?: UseControllerProps['rules'];
  placeholder?: string;
}

export const EmployeeSelectField = (props: EmployeeSelectFieldProps) => {
    const { t } = useTranslation();

    const {
        placeholder =translateCodes.SELECT_EMPLOYEE,
        maxCountAvatar = 2,
        employeeOptions = [],
        handleChangedEmployee,
        type,
        width,
        allEmployee,
        rules,
        mode
    } = props;

    const employeeOptionsRender = (option: BaseOptionType) => {
        const { label, isNoProvider } = option;
        const employee = employeeOptions.find((item) => item.value === option.value);

        if (type === 'default') {
            return (
                <FlexBox gap={12} alignItems='center'>
                    <Checkbox checked={props.value?.includes(option.value)} />
                    <FlexBox gap={12} alignItems='center'>
                        <AppAvatar data={employee} size='sm' icon={<UserOutlined />} />
                        <Text>{option.label}</Text>
                    </FlexBox>
                </FlexBox>
            );
        }

        const isNotValid = option.isOverlap || option.isNoProvider;
        return (
            <Tooltip
                title={
                    option.isOverlap
                        ? t(translateCodes.IS_NOT_AVAILABLE)
                        : isNoProvider
                            ? t(translateCodes.DOES_NOT_PROVIDER_THIS_SERVICE, {
                                employeeName: employee?.label,
                            })
                            : ''
                }
            >
                <span>
                    <FlexBox gap={12} alignItems='center'>
                        <Block overflow='unset' className='employee-avatar'>
                            <AppAvatar size='sm' data={option} />
                            {isNotValid && <ExclamationCircleOutlined className='warning-icon' />}
                        </Block>
                        <Text>{label}</Text>
                    </FlexBox>
                </span>
            </Tooltip>
        );
    };
    const employeeLabelRender = useCallback((label: LabelInValueType) => {
        const employeeSelected = employeeOptions.find((o) => o.value == label.value);   
        if(!employeeSelected) return null;   
        const combined: AvatarItem[] = [
            {
                id: employeeSelected.value,
                src: employeeSelected.avatar,
                fullName: employeeSelected.label,
                avatar: employeeSelected.avatar,
            }
        ];

        return (
            <ColumnAvatarGroup
                items={combined}
                maxCount={0}
                prefixEnd={`${label.label}`}
            />
        );
    }, [employeeOptions]);

    const maxTagPlaceholder = useCallback(
        (omittedValues: DisplayValueType[], selectOptions: DefaultOptionType[]) => {
            // Chuyển đổi omittedValues sang AvatarItem[]
            const combined: AvatarItem[] = omittedValues
                .map((o) => {
                    const match = selectOptions.find((ov) => ov.value === o.value);
                    return match
                        ? {
                            id: match.value,
                            src: match.avatar,
                            fullName: match.label,
                            avatar: match.avatar,
                        }
                        : null;
                })
                .filter(Boolean) as AvatarItem[]; // Lọc bỏ các giá trị null

            const isAll = omittedValues.length === selectOptions.length;
            const isOne = omittedValues.length === 1;

            return (
                <span>
                    {isAll ? (
                        t(translateCodes.ALL_EMPLOYEES)
                    ) : (
                        <ColumnAvatarGroup
                            size='small'
                            items={combined}
                            maxCount={maxCountAvatar}
                            prefixEnd={
                                isOne
                                    ? `${omittedValues[0].label}` || ''
                                    : `${omittedValues.length} employees`
                            }
                        />
                    )}
                </span>
            );
        },
        [maxCountAvatar, t]
    );

    return (
        <SelectField
            {...props}
            className={`customEmployeeSelect ${allEmployee ? 'placeholderSelectAll' : ''}`}
            placeholder={allEmployee ? translateCodes.ALL_EMPLOYEES : placeholder}
            labelRender={mode ==='multiple' ? undefined : employeeLabelRender}
            menuItemSelectedIcon={type === 'default' ? null : undefined}
            popupClassName='popup-employee-select'
            style={{ width: width }}
            options={employeeOptions}
            listHeight={376}
            showSearch={true}
            optionRender={({ data }) => employeeOptionsRender(data)}
            onChange={handleChangedEmployee}
            maxTagCount={0}
            maxTagPlaceholder={(value) => maxTagPlaceholder(value, employeeOptions)}
            rules={rules}
        />
    );
};
