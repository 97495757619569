import React from 'react';
import { IconProps } from '../../Types';

export const IconPinMinimize = (props: IconProps) => {
    return (
        <svg
            {...props}
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path d='M10.0005 12.5003L10.0005 18.3337M6.66719 6.09044V7.86595C6.66719 8.0393 6.66719 8.12597 6.65017 8.20888C6.63508 8.28243 6.61011 8.3536 6.57595 8.42047C6.53745 8.49583 6.4833 8.56352 6.37501 8.69888L5.06686 10.3341C4.51208 11.0275 4.23469 11.3743 4.23438 11.6661C4.2341 11.9199 4.34949 12.16 4.54783 12.3183C4.7759 12.5003 5.21994 12.5003 6.10802 12.5003H13.893C14.7811 12.5003 15.2251 12.5003 15.4532 12.3183C15.6516 12.16 15.7669 11.9199 15.7667 11.6661C15.7664 11.3743 15.489 11.0275 14.9342 10.3341L13.626 8.69888C13.5177 8.56352 13.4636 8.49583 13.4251 8.42047C13.3909 8.3536 13.366 8.28243 13.3509 8.20888C13.3339 8.12597 13.3339 8.0393 13.3339 7.86595V6.09044C13.3339 5.9945 13.3339 5.94653 13.3393 5.89922C13.3441 5.8572 13.3521 5.8156 13.3632 5.7748C13.3758 5.72886 13.3936 5.68432 13.4292 5.59525L14.2691 3.49551C14.5141 2.88294 14.6367 2.57665 14.5856 2.33078C14.5409 2.11577 14.4131 1.92708 14.2301 1.80575C14.0208 1.66699 13.6909 1.66699 13.0311 1.66699H6.9699C6.31014 1.66699 5.98026 1.66699 5.77094 1.80575C5.5879 1.92708 5.46016 2.11577 5.41548 2.33078C5.36439 2.57665 5.4869 2.88294 5.73193 3.49551L6.57182 5.59525C6.60745 5.68432 6.62527 5.72886 6.6378 5.7748C6.64894 5.8156 6.65695 5.8572 6.66176 5.89922C6.66719 5.94653 6.66719 5.9945 6.66719 6.09044Z' stroke='#667085' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
};

