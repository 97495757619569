import React from 'react';

import { IconProps } from '../../Types';

export const IconPen = (props: IconProps) => {
    const {color = '#475467',width = 19, height = 19} = props;
    return (
        <svg
            className={props.className}
            onClick={props.onClick}
            width={width} height={height} viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path color={props.color} d='M1.39637 15.0963C1.43466 14.7517 1.4538 14.5794 1.50594 14.4184C1.55219 14.2755 1.61753 14.1396 1.70021 14.0142C1.79339 13.8729 1.91596 13.7503 2.16112 13.5052L13.1664 2.49992C14.0868 1.57945 15.5792 1.57945 16.4997 2.49993C17.4202 3.4204 17.4202 4.91279 16.4997 5.83326L5.49445 16.8385C5.2493 17.0836 5.12672 17.2062 4.98541 17.2994C4.86005 17.3821 4.72408 17.4474 4.58121 17.4937C4.42018 17.5458 4.24789 17.5649 3.90331 17.6032L1.08301 17.9166L1.39637 15.0963Z' stroke={color} strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
};
