import React from 'react';
import {  Block, IconSaleEmpty, Text  } from '@esg/ui';
import './FastSaleLayout.scss';
import { useTranslation } from 'react-i18next';
import { translateCodes } from '@/locales';

export const FastSaleTabEmpty: React.FC = () => {
    const { t } = useTranslation();
    
    return (
        <Block className='fast-sale-tab-empty'>
            <IconSaleEmpty />
            <Block>
                <Text display='block' textAlign='center' fontWeight={600}>
                    {t(translateCodes.CART_NO_ITEM)}
                </Text>
                <Text color='#7F8FA4'>
                    {t(translateCodes.CART_NO_ITEM_DESC_FAST_SALE)}
                </Text>
            </Block>
        </Block>
    );
};
