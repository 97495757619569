
import React, { useEffect, useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import './SaleGeneralInfo.scss';
import { FlexBox, Spacer, Text } from '@esg/ui';
import { saleEvents } from '@/event/saleEvent';
interface SaleGeneralFastTipProps {
    handleUpdateFastTip: (value: number) => void;
    saleId: string;
    disabled: boolean;
}

const tipValues = [5, 10, 15, 20];

export const SaleGeneralFastTip: React.FC<SaleGeneralFastTipProps> = ({ handleUpdateFastTip, saleId, disabled }) => {
    const [fastTip, setFastTip] = useState<number | undefined>(undefined);

    const onChangeFastTip = (value: number) => {
        handleUpdateFastTip(value);
        setFastTip(value);
    };

    useEffect(() => {
        setFastTip(undefined);
    }, [saleId]);

    useEffect(() => {
        const clearRefetchFastTipSale = saleEvents.onRefetchFastTipSale.listen(() => {
            setFastTip(undefined);
        });

        return () => { clearRefetchFastTipSale?.(); };
    }, []);

    return (
        <Spacer bottom={8}>
            <FlexBox
                direction='row'
                className={`fast-tip-radio-group ${disabled ? 'disabled' : ''}`}
            >
                {tipValues.map((tipOption, index) => <FlexBox
                    flex={1}
                    key={index}
                    onClick={() => !disabled && onChangeFastTip(tipOption)}
                    alignItems='center'
                    justifyContent='center'
                    cursor={disabled ? 'not-allowed' : 'pointer'}
                    className={`fast-tip-radio-item ${fastTip === tipOption ? 'checked' : ''}`}
                >
                    {fastTip === tipOption && <CheckOutlined />}
                    <Text
                        className={`fast-tip-radio-item-value ${fastTip === tipOption ? 'checked' : ''}`}
                        fontWeight={600}
                        lineHeight='20px'
                        fontSize={14}
                    >
                        {` ${tipOption}%`}
                    </Text>
                </FlexBox>)}
            </FlexBox>
        </Spacer>
    );
};
