import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';
import { SaleDetailGetAllResponseItem } from '.';

interface UpdateMultipleSaleDetailRequest extends HttpRequestData {
    readonly body: {
        readonly saleId: string;
        readonly saleDetails : SaleDetailGetAllResponseItem[];
    }
}

export interface UpdateMultipleSaleDetailResponse {
    readonly saleId: string;
    readonly saleDetails: SaleDetailGetAllResponseItem[]
}

export const updateMultipleSaleDetail = httpUtil.createHttpRequestMeta<
    UpdateMultipleSaleDetailRequest,
    UpdateMultipleSaleDetailResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/sale-details/multiple/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
