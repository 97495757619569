import { DatePickerField, FlexBox, FormBase, FormModalFilter, SelectFieldCheckBox, useHttpQuery } from '@esg/ui';
import React from 'react';
import { useRecoilState } from 'recoil';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { useTranslation } from 'react-i18next';
import { managementApis } from '@esg/business-management';
import { resourceApis } from '@esg/business-resource-management';
import { AppointmentStatus } from '@esg/shared';
import { FilterCustomerAppointmentParams } from '../CustomerAppointmentTable';
import { translateCodes } from '@/locales';

interface FilterCustomerAppointmentFormProps {
    readonly defaultValues?: FilterCustomerAppointmentParams;
    readonly handleSubmit?: (value: FilterCustomerAppointmentParams) => void;
    readonly onCancel?: () => void;
}

export default function FilterCustomerAppointmentForm(props: FilterCustomerAppointmentFormProps) {
    const [currentLocale] = useRecoilState(currentLocaleState);
    const { t } = useTranslation();
    const { data: servicesData } = useHttpQuery(managementApis.getAllServices);
    const { data: employees } = useHttpQuery(resourceApis.getAllEmployees);

    const employeeOptions = employees?.items.map((c) => ({
        label: c.fullName,
        value: c.id,
    }));

    const serviceOptions = servicesData?.items.map((c) => ({
        label: c.name,
        value: c.id,
    }));

    const statusOptions = [
        {
            label: AppointmentStatus[AppointmentStatus.Booked],
            value: AppointmentStatus.Booked,
        },
        {
            label: AppointmentStatus[AppointmentStatus.Cancelled],
            value: AppointmentStatus.Cancelled,
        },
        {
            label: AppointmentStatus[AppointmentStatus.CheckedIn],
            value: AppointmentStatus.CheckedIn,
        },
        {
            label: AppointmentStatus[AppointmentStatus.Completed],
            value: AppointmentStatus.Completed,
        },
        {
            label: AppointmentStatus[AppointmentStatus.NoShow],
            value: AppointmentStatus.NoShow,
        },
        {
            label: AppointmentStatus[AppointmentStatus.Confirmed],
            value: AppointmentStatus.Confirmed,
        }
    ];

    return (
        <FormBase onSubmit={props.handleSubmit} defaultValues={{
            ...props.defaultValues,
        }}>
            {(form) => (
                <FormModalFilter 
                    onCancel={props.onCancel}
                    onReset={() => {
                        form.reset({
                            date: null,
                            serviceIds: [],
                            employeeIds: [],
                            statuses: [],
                        });
                    }}
                >
                    <FlexBox direction='column' gap={16}>
                        <SelectFieldCheckBox
                            name='employeeIds'
                            label={translateCodes.EMPLOYEE}
                            mode='multiple'
                            options={employeeOptions}
                            placeholderCode={translateCodes.SELECT_EMPLOYEE}
                            allData={`${t(translateCodes.ALL)} ${t(translateCodes.EMPLOYEE)}`}
                        />
                        <SelectFieldCheckBox
                            name='serviceIds'
                            label={translateCodes.SERVICE}
                            mode='multiple'
                            options={serviceOptions}
                            placeholderCode={translateCodes.SELECT_SERVICE}
                            allData={`${t(translateCodes.ALL)} ${t(translateCodes.SERVICE)}`}
                        />
                        <DatePickerField
                            name='date'
                            format={ currentLocale?.format }
                            label={translateCodes.CREATED_ON}
                            placeholder={translateCodes.SELECT_DATE}
                        />
                        <SelectFieldCheckBox
                            name='statuses'
                            label={translateCodes.STATUS}
                            mode='multiple'
                            options={statusOptions}
                            placeholderCode={translateCodes.STATUS}
                            allData={`${t(translateCodes.ALL)} ${t(translateCodes.STATUS)}`}
                        />
                    </FlexBox>
                </FormModalFilter>
            )}
        </FormBase>
    );
}
