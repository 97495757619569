import { FlexBox, Text } from '@esg/ui';
import { Tag, Tooltip } from 'antd';
import React from 'react';

interface ListNameColumnProps {
    readonly names?: (string | undefined)[];
    readonly limit?: number;
    readonly tooltip?: boolean;
}

export const ListNameColumn = (props: ListNameColumnProps) => {
    const { names = [], limit = 2, tooltip = true } = props;

    return (
        <FlexBox
            justifyContent='flex-start' 
            gap={2} 
            alignItems='center' 
            flexWrap='wrap'
        >
            {names?.slice(0, limit)?.map((o, index) => (
                <Tag key={index}>
                    <Text maxLines={1} maxWidth={75} color='#475467' ellipsis>{o}</Text>
                </Tag>
            ))}
            {names.length > limit && tooltip && (
                <Tooltip
                    color='white'
                    title={names?.slice(limit)?.map((o, index) => (
                        <Tag key={index} style={{ margin: '0 1px' }}>
                            <Text maxLines={1} maxWidth={75} color='#475467' ellipsis>{o}</Text>
                        </Tag>
                    ))}
                >
                    <Tag>{'+' + (names.length - limit)}</Tag>
                </Tooltip>
            )}
        </FlexBox>
    );
};
