import {  GetAllCustomerResponseItem, customerApi } from '@esg/business-crm';
import { resourceApis } from '@esg/business-resource-management';
import { FlexBox, SelectField, InputField,Text, useHttpQuery, Block, useHttpCommand, DatePickerField, TextAreaField } from '@esg/ui';
import { Card, Radio } from 'antd';
import React, { useMemo } from 'react';
import { FieldValues, useForm, useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { useRecoilState } from 'recoil';
import { EmployeeSelectField } from '../../employee/children/employee-select/EmployeeSelectField';
import { CustomerInfo } from '../../Appointment/children/appointment-info/appointment-customer-info/CustomerInfo';
import { translateCodes } from '@/locales';
import { genderOptions, ReferredTypeEnum, referredTypeOptions } from '@/constants';
import { customerRules } from '@/pages/crm/customer/@share/customerRules';

interface AdditionalInfoProps {
    customer?: Partial<GetAllCustomerResponseItem>;
    form: UseFormReturn<FieldValues, unknown, FieldValues | undefined>
}

export const AdditionalInfo = (props: AdditionalInfoProps) => {
    const { watch, register, setValue, formState } = useFormContext();
    const { t } = useTranslation();
    const [currentLocale] = useRecoilState(currentLocaleState);
    const { mutateAsync: createCustomerGroupAsync } = useHttpCommand(
        customerApi.createCustomerGroup,
    );
    const { mutateAsync: createCustomerSourceAsync } = useHttpCommand(
        customerApi.createCustomerSource,
    );

    const { data: customers } = useHttpQuery(customerApi.getAllCustomers);
    const { data: customerGroups, isFetching: groupLoading, refetch: refetchGroup } = useHttpQuery(customerApi.getAllCustomersGroup, { query: { active: true } });
    const { data: customerSources, isFetching: sourceLoading, refetch: refetchSource } = useHttpQuery(customerApi.getAllCustomersSource, { query: { active: true } });
    const { data: employees } = useHttpQuery(resourceApis.getAllEmployees);

    const customerGroupOptions = useMemo(() =>customerGroups?.items?.map((item) => ({
        label: item.name,
        value: item.id,
    })) ?? [], [customerGroups]);

    const customerSourceOptions = useMemo(() => customerSources?.items?.map((item) => ({
        label: item.name,
        value: item.id,
    })) ?? [], [customerSources]);

    const createCustomerGroup = async (form: ReturnType<typeof useForm>, value?: string) => {
        await createCustomerGroupAsync({
            body: { name: value ?? '', active: true }
        }, 
        {
            onSuccess: (response) => {
                refetchGroup();
                form.setValue('customerGroupId', response?.id);
            }
        });
    };

    const createCustomerSource = async (form: ReturnType<typeof useForm>, value?: string) => {
        await createCustomerSourceAsync({
            body: { name: value ?? '', active: true }
        }, 
        {
            onSuccess: (response) => {
                refetchSource();
                form.setValue('customerSourceId', response?.id);
            }
        });
    };

    const referredByType = watch('referredByType') ?? ReferredTypeEnum.Employee;
    register('referredByType');

    return (
        <Block>
            <Card bordered={false}>
                <FlexBox direction='column' gap={16}>
                    <InputField
                        name='address'
                        label={t(translateCodes.ADDRESS)}
                        placeholder={t(translateCodes.ADDRESS_PLACEHOLDER)}
                        rules={customerRules.address}
                    />
                    <FlexBox gap={16}>
                        <SelectField
                            allowClear
                            options={genderOptions}
                            name='gender'
                            label={t(translateCodes.GENDER)}
                            placeholder={t(translateCodes.GENDER_PLACEHOLDER)}
                        />
                        <DatePickerField
                            label={translateCodes.DATE_OF_BIRTH}
                            name='dateOfBirth'
                            placeholder={translateCodes.DATE_OF_BIRTH_PLACEHOLDER}
                            format={currentLocale?.format}
                        />
                    </FlexBox>                            
                    <FlexBox gap={16} >
                        <SelectField
                            loading={groupLoading}
                            filterOption={(input, option) => (option?.label?.toString() ?? '').includes(input)}
                            allowClear
                            name='customerGroupId'
                            label={t(translateCodes.CLIENT_GROUP)}
                            placeholder={t(translateCodes.CLIENT_GROUP_PLACEHOLDER)}
                            options={customerGroupOptions}
                            onCreate={async (value) => createCustomerGroup(props.form, value)}
                            createTextCode={translateCodes.CREATE_CLIENT_GROUP}
                            typeCreate='input'
                            style={{ width: 260 }}
                        />
                        <SelectField
                            style={{ width: 260 }}
                            loading={sourceLoading}
                            filterOption={(input, option) => (option?.label?.toString() ?? '').includes(input)}
                            allowClear
                            options={customerSourceOptions}
                            name='customerSourceId'
                            label={t(translateCodes.CLIENT_SOURCE)}
                            placeholder={t(translateCodes.CLIENT_SOURCE_PLACEHOLDER)}
                            onCreate={async (value) => createCustomerSource(props.form, value)}
                            createTextCode={translateCodes.CREATE_CLIENT_SOURCE}
                            typeCreate='input'
                        />
                    </FlexBox>
                    <FlexBox direction='column'>
                        <Text fontWeight={600}translateCode={translateCodes.REFERRED_BY} color='#344054'lineHeight='30px'/>  
                        <FlexBox gap={12} alignItems='center'>
                            <Radio.Group
                                name='referredByType'
                                onChange={(e) => {
                                    setValue('referredByType', e.target.value);
                                    setValue('employeeReferralId', null);
                                    setValue('customerReferralId', null);
                                }}
                                defaultValue={formState.defaultValues?.referredByType ?? ReferredTypeEnum.Employee}
                                options={referredTypeOptions}
                                buttonStyle='solid'
                                optionType='button'
                                style={{ display: 'flex' }}
                            />
                            {referredByType == ReferredTypeEnum.Employee ?
                                <EmployeeSelectField
                                    allowClear
                                    handleChangedEmployee={(value) => setValue('employeeReferralId', value)} 
                                    value={watch('employeeReferralId')}
                                    name='employeeReferralId'
                                    employeeOptions={employees?.items.map(o=> ({
                                        ...o,
                                        label: o.fullName,
                                        value: o.id,
                                    }))}
                                    label={undefined}
                                    placeholder={translateCodes.FIND_EMPLOYEE}
                                />
                                :
                                <SelectField
                                    filterOption={(input, option) => (option?.label?.toString()?.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())}
                                    options={customers?.items?.filter((item) => item.id !== props.customer?.id).map((o) => ({
                                        ...o,
                                        label: o.fullName,
                                        value: o.id,
                                    })) || []}
                                    optionFilterProp='label'
                                    showSearch
                                    allowClear
                                    placeholder={translateCodes.FIND_CLIENT}
                                    listHeight={513}
                                    optionRender={({ data }) => {
                                        const customer = data as GetAllCustomerResponseItem;
                                        return <CustomerInfo {...{ customer, type: 'select' }} />;
                                    }}
                                    name='customerReferralId'
                                    style={{ width: 390 }}
                                    onChange={(value) => setValue('customerReferralId', value)} 

                                />                               
                            }
                        </FlexBox>
                    </FlexBox>
                    <TextAreaField
                        name='note'
                        rules={customerRules.note}
                        label={t(translateCodes.NOTE)}
                        placeholder={t(translateCodes.NOTE_PLACEHOLDER)}
                    />
                </FlexBox>
            </Card>
        </Block>
    );
};