import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';
import { env } from '../../configs';

interface GetAllCampaignRequest extends HttpRequestData {
    readonly query: PaginationRequest; // Phân trang
}

export interface CampaignConditionValue {
    objectType?: number;
    numberOfTimes?: number;
    objectMode?: number;
    objectValue?: string;
}

export interface CampaignChannelItem {
    campaignId?: string;
    emailTemplateId?: string; 
    isActive?: boolean;
    type?: CampaignChannelType
}

export interface CampaignConditionRequest {
    id?: string;
    objectType: number; 
    numberOfTimes: number;
    objectMode: number; 
    objectValue?: string; 
}

export enum UnitApply {
    Percent = 0,
    Amount = 1
}

export enum CampaignType
{
    Promotion = 0,
    Notification = 1
}

export enum CampaignTarget
{
    AllClient = 0,
    ReturnClient = 1,
    NewClient = 2,
    SegmentClient = 3
}

export enum CampaignScheduleType
{
    Immediately = 0,
    Later = 1,
}

export enum CampaignStatus
{
    Running = 0,
    Draft = 1,
    Scheduled = 2,
    Paused = 3,
    Completed = 4,
}

export enum CampaignChannelType
{
    Sms = 0,
    Mail = 1
}

export interface GetCampaignResponseItem {
    id?: string;
    name?: string;
    code?: string;
    type?: CampaignType;
    target?: CampaignTarget;
    status?: CampaignStatus;
    targetValue?: string[];
    promotionId?: string;
    createdBy?: string;
    creationTime?: string;
    dateApply?: string;
    timeApply?: string;
    delivered ?: number;
    failed ?: number;
    opened ?: number;
    scheduleType ?: CampaignScheduleType;
    campaignChannels?: CampaignChannelItem[];
}

export const getAllCampaign = httpUtil.createHttpRequestMeta<
    GetAllCampaignRequest,
    PaginationResponse<GetCampaignResponseItem>
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/campaigns',
    method: 'GET',
    authentication: 'bearer',
});