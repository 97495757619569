import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';
import { GetPromotionResponseItem } from './getAllPromotion';

interface GetPromotionDetailRequest extends HttpRequestData {
    readonly pathData: {
        readonly id: string; 
    };
}

export const getDetailPromotion = httpUtil.createHttpRequestMeta<
    GetPromotionDetailRequest,
    GetPromotionResponseItem
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/promotions/:id', 
    method: 'GET', 
    authentication: 'bearer',
});