import React from 'react';
import { IconProps } from '../../Types';

export const IconPhone = (props: IconProps) => {
    const { color = '#98A2B3', height = 14, width = 15 } = props;
    return (
        <svg width={width} height={height} viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M4.58685 4.90211C5.05085 5.86853 5.68337 6.77429 6.48443 7.57534C7.28548 8.37639 8.19124 9.00892 9.15765 9.47291C9.24078 9.51283 9.28234 9.53278 9.33494 9.54811C9.52184 9.6026 9.75135 9.56346 9.90963 9.45012C9.95417 9.41823 9.99228 9.38012 10.0685 9.30391C10.3016 9.07084 10.4181 8.95431 10.5353 8.87812C10.9772 8.59079 11.5469 8.59079 11.9889 8.87812C12.106 8.95431 12.2226 9.07084 12.4556 9.30391L12.5856 9.43383C12.9398 9.78812 13.117 9.96527 13.2132 10.1555C13.4046 10.5339 13.4046 10.9807 13.2132 11.3591C13.117 11.5494 12.9399 11.7265 12.5856 12.0808L12.4805 12.1859C12.1274 12.539 11.9508 12.7155 11.7108 12.8504C11.4445 13 11.0308 13.1075 10.7253 13.1066C10.45 13.1058 10.2619 13.0524 9.8856 12.9456C7.86333 12.3716 5.95509 11.2886 4.36311 9.69666C2.77112 8.10467 1.68814 6.19643 1.11416 4.17417C1.00735 3.79787 0.953949 3.60972 0.953131 3.33442C0.952222 3.02894 1.0598 2.61528 1.20941 2.34894C1.34424 2.10892 1.52078 1.93238 1.87386 1.5793L1.97895 1.47421C2.33325 1.11992 2.5104 0.942768 2.70065 0.846538C3.07903 0.655158 3.52587 0.655158 3.90424 0.846538C4.0945 0.942768 4.27164 1.11991 4.62594 1.47421L4.75585 1.60412C4.98892 1.83719 5.10546 1.95373 5.18165 2.07091C5.46898 2.51284 5.46898 3.08256 5.18165 3.52449C5.10546 3.64167 4.98892 3.75821 4.75585 3.99128C4.67964 4.06749 4.64154 4.10559 4.60965 4.15013C4.4963 4.30842 4.45717 4.53793 4.51165 4.72483C4.52698 4.77742 4.54694 4.81899 4.58685 4.90211Z' stroke={color} strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round'/>
        </svg>
    );
};

