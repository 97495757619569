import { getDetailSale } from '@esg/business-pos';
import { AppointmentGetDetailResponse, schedulerApis } from '@esg/business-schedule';
import { AppointmentStatus, getAppointmentStatusAction, getAppointmentStatusLabel, getAppointmentTagColor } from '@esg/shared';
import { AppButton, Block, ColumnAvatarGroup, FlexBox, globalModalState, IconPen, IconTrash, Text, useHttpCommand, useLocalesFormat } from '@esg/ui';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { Badge, Card, Divider, Tag, theme } from 'antd';
import React, { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { GetAllServiceResponseItem } from '@esg/business-management/api';
import { viewRequests } from '@esg/framework';
import { useTranslation } from 'react-i18next';
import useOpenSaleFrom from '@/pages/fast-pos/sale/hook/useOpenSaleFrom';
import { useAppointmentForm } from '@/hooks/useAppointmentForm';
import { getAppointmentSummary, getEmployeesAppointment } from '@/components/form/Appointment/@share/appointmentUtils';
import { AppointmentDeleteConfirm } from '@/components/appointment/AppointmentDeleteConfirm';
import { translateCodes } from '@/locales/translateCodes';
import './CustomerProductDetailTable.scss';

const { useToken } = theme;

interface AppointmentUpcomingItemProps {
    appointmentResponse: AppointmentGetDetailResponse;
    serviceResponse: GetAllServiceResponseItem[];
    refetchAppointmentUpcoming: () => void;
}

export function AppointmentUpcomingItem({refetchAppointmentUpcoming, appointmentResponse, serviceResponse}: AppointmentUpcomingItemProps) {
    const { formatDate, formatCurrency } = useLocalesFormat();
    const [currentLocale] = useRecoilState(currentLocaleState);
    const { token } = useToken();
    const { onShowFormSale } = useOpenSaleFrom();
    const showAppointmentForm = useAppointmentForm();
    const { mutateAsync: getCurrentSale } = useHttpCommand(getDetailSale);
    const [, setModalGlobalState] = useRecoilState(globalModalState);
    const resetModal = useResetRecoilState(globalModalState);
    const { t } = useTranslation();

    const { mutateAsync: updateAppointmentStatus, isPending } = useHttpCommand(schedulerApis.changeAppointmentStatus, {
        onSuccess() {
            refetchAppointmentUpcoming();
        },
    });

    const { mutateAsync: DeleteAppointment } = useHttpCommand(schedulerApis.deleteAppointment,{
        onSuccess() {
            refetchAppointmentUpcoming();
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.NM_SC_03),
            });
        },
    });

    const { price } = getAppointmentSummary(appointmentResponse.appointmentServices, serviceResponse ?? []);
    const { minutes } = getAppointmentSummary(appointmentResponse.appointmentServices, serviceResponse ?? []);
    const servicesName = appointmentResponse.appointmentServices.map((s) =>
        s.appointmentServiceDetails.map((o) => o.service?.name).join(', ')
    ).join(', ');

    const employeeRender = (appointment: AppointmentGetDetailResponse) => {
        const employees = getEmployeesAppointment(appointment);
        return (
            <ColumnAvatarGroup
                items={employees ?? []}
                maxCount={2}
                prefixEnd={
                    employees?.length === 1 ?   
                        employees[0].fullName ?? '' :
                        `${employees?.length} employees`}
            />
        );
    };
    
    const changeAppointmentStatus = useCallback(
        async (appointment: AppointmentGetDetailResponse) => {
            if (appointment.status === AppointmentStatus.Completed
                    || appointment.status === AppointmentStatus.CheckedIn) {
                const sale = await getCurrentSale({
                    pathData: {
                        id: appointment.saleId!,
                    }
                });
    
                return void onShowFormSale(sale, { paidFromScheduler: true });
            }
    
            await updateAppointmentStatus({
                pathData: {
                    id: appointment.id
                },
                body: {
                    id: appointment.id,
                    status: getAppointmentStatusAction(appointment.status).nextValue
                },
            });
        },
        [getCurrentSale, onShowFormSale, updateAppointmentStatus]
    );

    const onDelete = React.useCallback(
        async (id: string) => {
            await DeleteAppointment(
                {
                    pathData: {
                        id: id,
                    },
                },
            );
        },
        [DeleteAppointment]
    );
    
    const showDeleteConfirm = (appointment: AppointmentGetDetailResponse) => {
        setModalGlobalState({
            content: <AppointmentDeleteConfirm item={appointment} />,
            isOpen: true,
            onOk: () => {
                if (appointment.id) {
                    onDelete(appointment.id);
                }
                resetModal();
            },
            showModalDelete: true,
        });
    };
        
    return (
        <Card className='hoverable-card'>
            <FlexBox direction='column' gap={4} >
                <FlexBox direction='row' justifyContent='space-between'>
                    <Block className='upcoming-tag-status'>
                        <Text fontSize={14} fontWeight='bold' color={token.colorPrimary}>{appointmentResponse.appointmentCode}</Text>
                        <Text color='#344054' fontSize={14} fontWeight={400}>{formatDate(appointmentResponse.appointmentDate, ' ddd, ' + currentLocale?.format + ' h:mm A ')} </Text>
                        <Tag color={getAppointmentTagColor(appointmentResponse.status)}>
                            {getAppointmentStatusLabel(appointmentResponse.status)}
                        </Tag>
                    </Block>
                    <Block className='upcoming-action'>
                        <AppButton size='small' loading={isPending} ghost type='primary'  onClick={() => changeAppointmentStatus(appointmentResponse)} style={{ fontSize: 14, fontWeight: 'bold',color: token.colorPrimary }} >{getAppointmentStatusAction(appointmentResponse.status)?.label}</AppButton>
                        <Divider type='vertical' style={{ height: '100%' }} />
                        <AppButton className='app-button-hover' icon={<IconPen width={16} height={16} />} onClick={() => showAppointmentForm({ initialValues: appointmentResponse, refetch: refetchAppointmentUpcoming })} />
                        <AppButton className='app-button-hover' icon={<IconTrash width={16} height={16} />} onClick={() => showDeleteConfirm(appointmentResponse)} />
                    </Block>
                </FlexBox>
                <FlexBox direction='column' gap={8}>
                    <Text fontSize={18} fontWeight={600}>{servicesName}</Text>
                    <FlexBox direction='row'>
                        <Block className='upcoming-info'>
                            {employeeRender && <>
                                {employeeRender(appointmentResponse)}
                                <Badge className='badge-custom' status='default' /></>
                            }
                            <Text color='#344054' fontSize={14} fontWeight={400}>{minutes} mins </Text>
                            <Badge className='badge-custom' status='default' />
                            <Text color='#344054' fontSize={14} fontWeight={400}>{formatCurrency(price)}</Text>
                        </Block>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </Card>
    );
}
