import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

export interface CreateSegmentRequest extends HttpRequestData {
    readonly body: {
        name: string;
        description: string;
        isAutoUpdate?: boolean;
        operator?: number;
        segmentConditions?: SegmentConditionCreateItem[];
    };
}

export interface SegmentConditionCreateItem {
    id?: string;
    name?: string;
    code?: number;
    value?: string
}

export const createSegment = httpUtil.createHttpRequestMeta<
    CreateSegmentRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/segments',
    method: 'POST',
    authentication: 'bearer',
});
