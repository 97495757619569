import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';
import { CampaignChannelItem, CampaignScheduleType, CampaignStatus, CampaignTarget, CampaignType } from './getAllCampaign';

export interface SaveDraftCampaignRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        name?: string;
        type?: CampaignType;
        target?: CampaignTarget;
        status?: CampaignStatus;
        targetValue?: string;
        promotionId?: string;
        dateApply?: string;
        timeApply?: string;
        campaignChannels?: CampaignChannelItem[];
        scheduleType ?: CampaignScheduleType;
    };
}

export const saveDraftCampaign = httpUtil.createHttpRequestMeta<
    SaveDraftCampaignRequest,
    void
>({
    baseUrl: env.apiUrlBase, 
    path: '/api/v1/campaigns/draft',
    method: 'PATCH', 
    authentication: 'bearer',
});