import { FlexBox, FormBase, FormLayoutModal, Spacer, Text} from '@esg/ui';
import React from 'react';
import {  SaleDetailGetAllResponseItem, SaleDetailEmployee } from '@esg/business-pos/api/sale-detail';
import { Card} from 'antd/lib';
import { EmployeeGetDetailResponse } from '@esg/business-resource-management';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EmployeeSelectField } from '@/components/form/employee/children/employee-select/EmployeeSelectField';
import { translateCodes } from '@/locales';
import './EditSaleDetailForm.scss';

interface EditMultipleSaleDetailFormProps {
    readonly employees?: EmployeeGetDetailResponse[];
    readonly defaultValues: SaleDetailGetAllResponseItem[];
    readonly handleSubmit?: (value: MultipleSaleDetailSubmit) => void;
    readonly onCancel?: () => void;
    readonly isLoading?: boolean;
    readonly saleId: string;
}

export interface MultipleSaleDetailSubmit {
    saleId: string;
    saleDetails : SaleDetailGetAllResponseItem[];
}

export default function EditMultipleSaleDetailForm(props: EditMultipleSaleDetailFormProps) {
    const { t } = useTranslation();
    const { employees = [], defaultValues, saleId, handleSubmit, onCancel } = props;
    const serviceQuantity = defaultValues.filter((saleDetail) => saleDetail.objectType === 'SERVICE').length;
    const productQuantity = defaultValues.filter((saleDetail) => saleDetail.objectType === 'PRODUCT').length;

    const renderEmployeeCard = (
        saleDetail: SaleDetailGetAllResponseItem, 
        form: UseFormReturn<FieldValues, unknown, FieldValues | undefined>
    ) => {
        const identityKey = form.watch('saleDetails').findIndex((item: SaleDetailGetAllResponseItem) => item.id === saleDetail.id);
        return (
            <Spacer bottom={8} key={identityKey}>
                <FlexBox direction='column' gap={16}>
                    <Card className='edit-multiple-sale-detail-card'>
                        <Spacer bottom={6}>
                            <FlexBox gap={16}>
                                <Text fontSize={14} fontWeight={700} lineHeight='20px' color='#101828' textTransform='capitalize'>
                                    {saleDetail.objectName}
                                    {saleDetail.quantity > 1 && <Text fontSize={14} fontWeight={400} textTransform='lowercase'> x{saleDetail.quantity}</Text>}
                                </Text>
                            </FlexBox>
                        </Spacer>
                        <EmployeeSelectField
                            mode='multiple'
                            name={`saleDetails[${identityKey}].saleDetailEmployees`}
                            type='default'
                            label='Employees'
                            labelStyle={{ color: '#667085', fontWeight: 500, fontSize: 14 }}
                            value={form.watch(`saleDetails[${identityKey}].saleDetailEmployees`)?.map(
                                (o: SaleDetailEmployee) => o.employeeId
                            )}
                            handleChangedEmployee={(value) => {
                                const employeeIds = Array.isArray(value) ? value : [value];
                                form.setValue(
                                    `saleDetails[${identityKey}].saleDetailEmployees`,
                                    employeeIds.map((employeeId) => ({
                                        employeeId,
                                    }))
                                );
                            }}
                            employeeOptions={employees.map((employee) => ({
                                ...employee,
                                label: employee.fullName,
                                value: employee.id,
                            }))}
                        />
                    </Card>
                </FlexBox>
            </Spacer>
        );
    };

    const renderObjectTypeSaleDetailGroup = ( 
        label: string,
        objectType: string,
        objectQuantity: number,
        form: UseFormReturn<FieldValues, unknown, FieldValues | undefined>,
        
    ) => {
        if(!objectQuantity) return null;

        return (
            <Spacer bottom={12}>
                <Spacer bottom={6}>
                    <Text fontSize={16} fontWeight={700} lineHeight='24px' color='#344054' >{label}
                        <Text fontSize={16} fontWeight={400} lineHeight='24px'>{` (${objectQuantity})`}</Text >
                    </Text>
                </Spacer>
                {
                    form.watch('saleDetails')
                        ?.filter((saleDetail: SaleDetailGetAllResponseItem) => saleDetail.objectType === objectType)
                        ?.map((saleDetail: SaleDetailGetAllResponseItem) => (renderEmployeeCard(saleDetail, form)))
                }
            </Spacer>
        );
    };

    return (
        <FormBase
            onSubmit={handleSubmit}
            defaultValues={{ saleDetails: defaultValues, saleId: saleId }}
        >
            {(form) => (
                <FormLayoutModal
                    useSubmitButton={true}
                    onCancel={onCancel}
                    okTextCode={translateCodes.SAVE}
                    isLoadingButton={props.isLoading}
                >   
                    {renderObjectTypeSaleDetailGroup(t(translateCodes.SERVICE), 'SERVICE', serviceQuantity, form)}
                    {renderObjectTypeSaleDetailGroup(t(translateCodes.PRODUCT), 'PRODUCT', productQuantity, form)}
                </FormLayoutModal>
            )}
        </FormBase>
    );
}
