import { AppButton, FlexBox, IconRefresh, IconTrash } from '@esg/ui';
import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppointmentGetDetailResponse } from '@esg/business-schedule';
import { AppointmentFormBtn } from '@/components';
import { translateCodes } from '@/locales';

interface AppointmentColumnActionsProps {
    onClickDelete?: () => void;
    isShowRebook?: boolean;
    appointment?: AppointmentGetDetailResponse;
    refetch?: () => void;
}

export const AppointmentColumnActions = (props: AppointmentColumnActionsProps) => {
    const { onClickDelete , isShowRebook, appointment, refetch } = props;
    const { t } = useTranslation();

    return (
        <FlexBox alignItems='center' gap={18}>
            {isShowRebook && (
                <Tooltip placement='top' title={t(translateCodes.REBOOK)}>
                    <AppointmentFormBtn
                        className='app-button-hover'
                        isRebook={true}
                        initialValues={appointment}
                        refetch={refetch}
                        icon={<IconRefresh className='icon-refresh'/>} />
                </Tooltip>
            )}
            <Tooltip
                placement='top'
                title={t(translateCodes.DELETE)}
            >
                <AppButton className='app-button-hover' onClick={onClickDelete} icon={<IconTrash className='icon-delete'/>} />
            </Tooltip>
        </FlexBox>
       
    );
};
