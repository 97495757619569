import { AppButton, FlexBox, Text, globalDrawerState, useLocalesFormat } from '@esg/ui';
import React, {useMemo} from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { AppointmentService } from '@esg/business-schedule';
import { useResetRecoilState } from 'recoil';
import { AppointmentStatus } from '@esg/shared';
import { getAppointmentSummary } from '../appointmentUtils';
import { useAppointmentContext } from '../AppointmentFormContext';
import { useAppointmentBusiness } from './useAppointmentBusiness';
import { useValidSubmit } from './useValidSubmit';
import { translateCodes } from '@/locales';

export const useAptFormFooter = () => {
    const { t } = useTranslation();
    const { watch} = useFormContext();
    const { handleSubmitApt } = useAppointmentBusiness();
    const {services, defaultValues} = useAppointmentContext();
    const isDisable = useValidSubmit();
    const { formatCurrency } = useLocalesFormat();
    const hideAppointmentForm = useResetRecoilState(globalDrawerState);

    const appointmentServices = watch('appointmentServices');

    const { minutes, price } = useMemo(() =>
        getAppointmentSummary(appointmentServices as AppointmentService[], services)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ,[JSON.stringify(appointmentServices), services]);

    return (
        <FlexBox className='form-footer' gap={48}>
            <FlexBox alignItems='baseline' gap={8}>
                <Text fontWeight={700} fontSize={20}>
                    {t(translateCodes.TOTAL)}: {formatCurrency(price)}
                </Text>
                <Text fontSize={16}>
                    {minutes} {t(translateCodes.MINUTE_DURATION)}
                </Text>
            </FlexBox>
            <FlexBox gap={12}>
                <AppButton
                    disabled={isDisable}
                    width='100%'
                    translateCode={
                        defaultValues?.id
                            ? translateCodes.CANCEL
                            : translateCodes.CREATE_VS_CHECK_IN
                    }
                    ghost
                    type={isDisable ? 'default' : 'primary'}
                    onClick={
                        defaultValues?.id
                            ? () => {
                                hideAppointmentForm();
                            }
                            : handleSubmitApt(AppointmentStatus.CheckedIn)
                    }
                />
                <AppButton
                    disabled={isDisable}
                    width='100%'
                    translateCode={defaultValues?.id ? translateCodes.SAVE : translateCodes.CREATE}
                    type={isDisable? 'default' : 'primary'}
                    onClick={handleSubmitApt()}
                />
            </FlexBox>
        </FlexBox>
    );
};
