import React from 'react';
import { IconProps } from '../../Types';

export const IconShopping = (props: IconProps) => {
    return (
        <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M12.334 6.50001V4.00001C12.334 2.15906 10.8416 0.666672 9.00063 0.666672C7.15968 0.666672 5.6673 2.15906 5.6673 4.00001V6.5M1.99397 7.62664L1.49397 12.96C1.35181 14.4764 1.28072 15.2346 1.53234 15.8202C1.75338 16.3347 2.14073 16.7601 2.63232 17.0282C3.1919 17.3333 3.95344 17.3333 5.47651 17.3333H12.5248C14.0478 17.3333 14.8094 17.3333 15.3689 17.0282C15.8605 16.7601 16.2479 16.3347 16.4689 15.8202C16.7205 15.2346 16.6495 14.4764 16.5073 12.96L16.0073 7.62664C15.8872 6.34613 15.8272 5.70587 15.5392 5.22181C15.2856 4.79549 14.9109 4.45426 14.4628 4.24154C13.954 4.00001 13.3109 4.00001 12.0248 4.00001L5.97651 4.00001C4.69037 4.00001 4.04731 4.00001 3.53848 4.24154C3.09035 4.45426 2.71564 4.79549 2.46202 5.22181C2.17404 5.70587 2.11402 6.34613 1.99397 7.62664Z' stroke='#667085' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
};

IconShopping.defaultProps = {
    className: '',
    onClick: () => { },
    color: '#475467',
};
