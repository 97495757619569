import {  HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface DeleteCampaignRequest extends HttpRequestData {
}

export const deleteCampaign = httpUtil.createHttpRequestMeta<
    DeleteCampaignRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/campaigns/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
