import React from 'react';
import { IconProps } from '../../Types';

export const IconMail = (props: IconProps) => {
    const { color = '#98A2B3', width = 16, height = 16 } = props;
    return (
        <svg width={width} height={height} viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M1.33301 2.66663L6.77629 6.47692C7.21707 6.78547 7.43746 6.93974 7.67718 6.9995C7.88894 7.05228 8.11041 7.05228 8.32217 6.9995C8.56189 6.93974 8.78228 6.78547 9.22306 6.47692L14.6663 2.66663M4.53301 11.3333H11.4663C12.5864 11.3333 13.1465 11.3333 13.5743 11.1153C13.9506 10.9236 14.2566 10.6176 14.4484 10.2413C14.6663 9.81345 14.6663 9.2534 14.6663 8.13329V3.86663C14.6663 2.74652 14.6663 2.18647 14.4484 1.75864C14.2566 1.38232 13.9506 1.07636 13.5743 0.884613C13.1465 0.666626 12.5864 0.666626 11.4663 0.666626H4.53301C3.4129 0.666626 2.85285 0.666626 2.42503 0.884613C2.0487 1.07636 1.74274 1.38232 1.55099 1.75864C1.33301 2.18647 1.33301 2.74652 1.33301 3.86663V8.13329C1.33301 9.2534 1.33301 9.81345 1.55099 10.2413C1.74274 10.6176 2.0487 10.9236 2.42503 11.1153C2.85285 11.3333 3.4129 11.3333 4.53301 11.3333Z' stroke={color} strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round'/>
        </svg>

    );
};