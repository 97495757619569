import { AppButton, FlexBox } from '@esg/ui';
import React from 'react';
import { useFormContext } from 'react-hook-form';
interface BranchFormSettingFooterProps{
    loading?: boolean;
}
export const BranchFormSettingFooter = (props: BranchFormSettingFooterProps) => {
    const {formState, reset} = useFormContext();

    const isFormDirty = formState.isDirty;
    return (
        <FlexBox justifyContent='flex-start' gap={20}>
            <AppButton
                width={120}
                translateCode='Cancel'
                type='default'
                onClick={()=> reset()}
                disabled={!isFormDirty}
            />
            <AppButton
                width={120}
                translateCode='Save'
                type={isFormDirty ? 'primary' : 'default'}
                htmlType='submit'
                loading={props.loading}
                disabled={!isFormDirty}
            />
        </FlexBox>
    );
};
