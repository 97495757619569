import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';
import { env } from '../../configs';

interface GetAllCustomerSegmentRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export interface CustomerSegmentGetDetailResponse {
    id?: string;
    name?: string;
    customerId?: string;
    customer?: CustomerGetDetailResponse;
    segmentId: string;
    branchId?: string;
    creationTime?: string;
}

export interface CustomerGetDetailResponse {
    id: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    customerCode?: string;
    avatar?: string;
    phone?: string;
    email?: string;
    customerLabel?: number;
    created?: string;
    customerReferral?: CustomerInfo;
}

export interface CustomerInfo {
    id: string;
    firstName?: string;
    lastName?: string;
}

export const getAllCustomerSegments = httpUtil.createHttpRequestMeta<
    GetAllCustomerSegmentRequest,
    PaginationResponse<CustomerSegmentGetDetailResponse>
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/customer-segments',
    method: 'GET',
    authentication: 'bearer',
});
