import React from 'react';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { GetDetailSaleResponse } from '@esg/business-pos';
import {AppButton, FlexBox, Header, IconActivityHistory, IconCancelSale, IconMinimize, IconMore, IconPinMinimize, Text } from '..';

interface HeaderDrawerProps {
    sale?: GetDetailSaleResponse;
    drawerTitle?: React.ReactNode;
    drawerSubtitle? :React.ReactNode;
    onMinimize?: () => void;
    handleSaveUnpaid?: () => Promise<void>
    onClose?: (e: React.MouseEvent | React.KeyboardEvent) => void;
    readonly isFastSale?: boolean;
    readonly showModalOrderActivities?: () => void;
    readonly showModalConfirmCancel?: (sale: GetDetailSaleResponse) => void;
}

export const HeaderDrawer = (props: HeaderDrawerProps) => {
    const { t } = useTranslation();
    const {isFastSale} = props;

    const items = [
        {
            key: 'activity',
            label: (
                <FlexBox gap={12} alignItems='center' onClick={() => { props.showModalOrderActivities?.(); }}>
                    <IconActivityHistory />
                    <Text translateCode='Activity history' />
                </FlexBox>
            ),
        },
        {
            key: 'close',
            label: (
                <FlexBox gap={12} alignItems='center' onClick={props.handleSaveUnpaid}>
                    <IconPinMinimize />
                    <Text translateCode='Pin to minimize' />
                </FlexBox>
            ),
        },
        {
            key: 'cancel',
            label: (
                <FlexBox gap={12} alignItems='center' onClick={()=>{props.showModalConfirmCancel?.(props.sale!);}}>
                    <IconCancelSale />
                    <Text translateCode='Cancel sale' color='#D92D20'/>
                </FlexBox>
            ),
        },
    ];
    
    return (
        <FlexBox direction='row'>
            {typeof props.drawerTitle === 'string' ? (
                <FlexBox direction='column' alignItems='flex-start' justifyContent='center'>
                    <Header level={2}>
                        {t(props?.drawerTitle ?? '')}
                    </Header>
                    {props.drawerSubtitle &&
                        <Text color='#344054' fontSize={12} fontWeight={400}>
                            {typeof props.drawerSubtitle === 'string' ?
                                t(props?.drawerSubtitle ?? '')
                                : props.drawerSubtitle}
                        </Text>}
                </FlexBox>
            ) : (
                props.drawerTitle
            )}
            {
                isFastSale && <FlexBox gap={12} direction='row' justifyContent='flex-end' alignItems='center'>  
                    <Dropdown menu={{ items }} placement='bottomLeft' arrow>
                        <AppButton className='fast-activity-button'>
                            <IconMore />
                        </AppButton>
                    </Dropdown>
                </FlexBox>
                || <FlexBox gap={12} direction='row' justifyContent='flex-end' alignItems='center'>
                    {props?.onMinimize && <span className='minimizeButton'><IconMinimize onClick={props?.onMinimize} /></span>}
                    <CloseOutlined onClick={props?.onClose} className='closeButton' />
                </FlexBox>
            }
           
        </FlexBox>
    );
};

