import { Button, ButtonProps } from 'antd';
import React from 'react';
import { Text } from '@esg/ui';

import { useTranslation } from 'react-i18next';
import { Block } from '../layouts';

export interface AppButtonProps extends ButtonProps {
    readonly translateCode?: string;
    readonly width?: React.CSSProperties['width'];
    readonly height?: React.CSSProperties['height'];
    readonly padding?: React.CSSProperties['padding'];
    readonly textAlign?: React.CSSProperties['textAlign'];
    readonly borderColor?: React.CSSProperties['borderColor'];
}

export const AppButton = (props: AppButtonProps) => {
    const { children, translateCode, width, height, padding, textAlign, borderColor, ...rest } = props;
    const { t } = useTranslation();

    const style = React.useMemo((): React.CSSProperties => {
        return {
            width,
            padding,
            textAlign,
            borderColor,
            height
        };
    }, [borderColor, padding, textAlign, width, height]);

    return (
        <Button
            classNames={{
                icon: 'app-button-icon',
            }}
            className={`app-button ${props.className}`}
            style={style}
            {...rest}
        >
            {translateCode ? (
                <Block display='inline-block'><Text fontSize={14}>{t(translateCode)}</Text></Block>
            ) : (
                children
            )}
        </Button>
    );
};
