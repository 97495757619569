import { Paragraph, Spacer, Text } from '@esg/ui';
import React from 'react';
import { translateCodes } from '@/locales';

export const CustomerResellConfirm = ({ saleCode }: { saleCode?: string }) => {
    return (
        <Spacer top='xs'>
            <Text fontWeight='bold' translateCode={translateCodes.RESELL} />
            <Spacer bottom='xs' />
            <Paragraph>
                <Text translateCode={translateCodes.RESELL_MSG} />
                <Text fontWeight='bold'>{saleCode}</Text> ?
            </Paragraph>
        </Spacer>
    );
};
