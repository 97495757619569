import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { GetDetailSaleResponse } from '@esg/business-pos/api/sale';
import { env } from '../../configs';

interface GetProductDetailClientDetailRequest extends HttpRequestData {
    readonly query: {
        readonly productId: string;
        readonly customerId: string;
    };
}

export interface GetProductDetailClientDetailResponse {
    id?: string,
    code?: string;
    saleDate?: string;
    quantity?: number;
    amount?: number;
    saleDetailEmployees?: SaleDetailEmployeeInfo[];
}
export interface SaleDetailEmployeeInfo {
    employeeId?: string;
    employee: EmployeeInfo;
}
export interface EmployeeInfo {
    id?: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    avatar?: string;
    phone?: string;
    email?: string;
}

export const getProductDetailClientDetailReport = httpUtil.createHttpRequestMeta<
    GetProductDetailClientDetailRequest,
    PaginationResponse<GetDetailSaleResponse>
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/clients-detail/detail-by-product',
    authentication: 'bearer',
});