import React from 'react';
import { Text, FlexBox, useHttpQuery, Spacer, ColumnActions, TableCustom, usePagination, AppButton, useLocalesFormat, globalModalState, useHttpCommand, Block, ExternalLink } from '@esg/ui';
import { useTranslation } from 'react-i18next';
import { managementApis } from '@esg/business-management';
import { Avatar, Image, TableColumnsType } from 'antd';

import { Link, useParams } from 'react-router-dom';
import { CreateCustomerSegmentRequest, CustomerForCreateSegmentResponse, CustomerSegmentCreateItem, CustomerSegmentGetDetailResponse } from '@esg/business-management/api/customer-segment';
import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { dayjs } from '@esg/shared';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import AddSegmentBtn from './children/AddSegmentBtn';
import { translateCodes } from '@/locales';
import { CUSTOMER_DETAIL_URL, SEGMENT_URL } from '@/configs';
import { resourceManagementTheme } from '@/pages/resource-management/@shared';
import { CustomerSegmentForm } from '@/components';

export function SegmentDetail() {
    const { id: segmentId } = useParams();
    const [currentLocale] = useRecoilState(currentLocaleState);
    const [searchParams, setSearchParams] = usePagination();
    const { formatDate } = useLocalesFormat();
    const [globalModal,  setModalState] = useRecoilState(globalModalState);
    const resetModalState = useResetRecoilState(globalModalState);

    const {t} = useTranslation();

    const {
        data: segmentResponse,
        refetch : refetchOverview
    } = useHttpQuery(managementApis.getDetailSegment, {
        pathData: {
            id: segmentId,
        },
    });

    const {
        data: customerSegmentsResponse,
        isFetching,
        isLoading,
        refetch
    } = useHttpQuery(managementApis.getAllCustomerSegments, {
        query: {
            segmentId : segmentId
        }
    });

    const { mutateAsync : deleteSegmentAsync } = useHttpCommand(managementApis.deleteCustomerSegment);
    const { mutateAsync : addCustomerSegmentAsync } = useHttpCommand(managementApis.createCustomerSegment, {
        onSuccess: () => {
            resetModalState();
            refetch();
            refetchOverview();
        }
    });

    const onDelete = React.useCallback(
        async (id: string) => {
            await deleteSegmentAsync(
                { pathData: { id: id } },
                {
                    onSuccess(_) {
                        resetModalState();
                        refetch();
                        refetchOverview();
                    },
                }
            );
        },
        [deleteSegmentAsync, refetch, refetchOverview, resetModalState]
    );

    const showModalDelete = React.useCallback((record: CustomerSegmentGetDetailResponse) => {
        setModalState({
            content: (
                <FlexBox direction='column'>
                    <Text fontSize={18} fontWeight={700}>
                        {t(translateCodes.DELETE_CUSTOMER)}
                    </Text>
                    <Text fontSize={14}>
                        {t(translateCodes.DELETE_CUSTOMER_MSG)}:
                        <Text fontWeight='bold'> {record.customer?.fullName}</Text>
                    </Text>
                </FlexBox>
            ),
            isOpen: true,
            onOk: () => {
                onDelete(record?.id || '');
            },
            showModalDelete: true,
        });
    }, [onDelete, setModalState, t]);

    const onSubmitAddSegment = React.useCallback(
        async (value: CustomerSegmentCreateItem[]) => {
            const payload: CreateCustomerSegmentRequest['body'] = {
                customerIds: value,
                segmentId: segmentId || ''
            };
            await addCustomerSegmentAsync({ body: payload });
        },
        [addCustomerSegmentAsync, segmentId]
    );

    const onShowModalCreate = React.useCallback((customers: CustomerForCreateSegmentResponse[]) => {
        setModalState({
            ...globalModal,
            isOpen: true,
            titleTransCode: 'Create new segment client',
            content: (
                <CustomerSegmentForm
                    onSubmit={onSubmitAddSegment}
                    refetch={refetch}
                    resetModalState={resetModalState}
                    customers={customers}
                    segmentId={segmentId||''}
                />
            ),
        });
    }, [globalModal, onSubmitAddSegment, refetch, resetModalState, segmentId, setModalState]);

    const columns: TableColumnsType<CustomerSegmentGetDetailResponse> = [
        {
            title: 'Client ID',
            dataIndex: 'customerCode',
            key: 'customerCode',
            sorter: (a, b) => (a.customer?.customerCode || '').localeCompare(b.customer?.customerCode || ''),
            render: (_, record) => {
                return <ExternalLink 
                    color='#004EEB' 
                    fontSize={14} 
                    fontWeight={500}
                    href={`${CUSTOMER_DETAIL_URL}/${record.customer?.id}`}
                    target='_blank'
                    // onClick={()=>{handleViewDetail(record.id);}}
                >{record.customer?.customerCode}</ExternalLink>;
            }
        },
        {
            title: 'Client name',
            dataIndex: 'customerName',
            key: 'customerName',
            render: (_, record) => {
                return (<FlexBox alignItems='center' gap={8}>
                    <Avatar size={32} shape='circle' src={record.customer?.avatar}  icon={<UserOutlined />} />
                    <Block display='flex'>
                        <FlexBox direction='column'>
                            <Text maxWidth={300} ellipsis fontSize={14} lineHeight='20px' fontWeight={400}>{record.customer?.fullName}</Text>
                            {
                                record.customer?.customerLabel === 0 
                                    ? <Text fontSize={14} lineHeight='20px' fontWeight={400} color='#079455'>New Client</Text>
                                    : record.customer?.customerLabel === 1
                                        ? <Text fontSize={14} lineHeight='20px' fontWeight={400} color='#155EEF'>Returning Client</Text>
                                        :null
                            }
                        </FlexBox>
                    </Block>
                </FlexBox>);
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (_, record) => {
                return <Text >{record.customer?.email}</Text>;
            },
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (_, record) => {
                return <Text >{record.customer?.phone}</Text>;
            },
        },
        {
            title: 'Added date',
            dataIndex: 'addedDate',
            key: 'addedDate',
            render: (_, record) => {
                return <Text>{formatDate(dayjs(record.creationTime), `${currentLocale?.format}`)} {}</Text>;
            },
        },
        {
            title: t(translateCodes.ACTION),
            key: 'action',
            render: (_, record) => {
                return (
                    <ColumnActions
                        onClickDelete={() => {
                            showModalDelete(record);
                        }}
                    />
                );
            },
        },
    ];

    const SegmentActionView = () => {
        return(
            <AddSegmentBtn onShowModalCreate={onShowModalCreate} segmentId={segmentId}/>
        );
    };

    const SegmentOverview = () => {
        return(
            <Text color='#D0D5DD' fontWeight={400}>Total clients:<Text fontWeight={700}>{` ${segmentResponse?.numberOfClients}`}</Text></Text>
        );
    };

    return (
        <Spacer size={24} >
            <FlexBox className='customer-detail' direction='column' >
                <Spacer height={8} />
                <FlexBox gap={20} >
                    <Link to={SEGMENT_URL}>
                        <Text translateCode={translateCodes.SEGMENT_LIST} fontWeight={500} fontSize={14} color='#475467' /> 
                    </Link>
                    <Image
                        preview={false}
                        src={resourceManagementTheme.arrowRightIcon}
                        width={14}
                        height={14}
                    />
                    <Text translateCode = {segmentResponse?.name} fontWeight={600} fontSize={14} color='#0C6FF3'/>
                </FlexBox>
                <Spacer height={16} />
            </FlexBox>
            <TableCustom
                onPageChange={(page) =>
                    setSearchParams({ ...searchParams, page: page.toString() })
                }
                onSearch={(value) =>
                    setSearchParams({ ...searchParams, search: value })
                }
                loading={isFetching || isLoading }
                placeholderSearchTransCode='Search by client name'
                columns={columns}
                dataSource={customerSegmentsResponse?.items}
                pageInfo={customerSegmentsResponse?.meta}
                emptyOption={{
                    title: t(translateCodes.ADD_SEGMENT),
                    description: t(translateCodes.EMPTY_SEGMENT_DESCRIPTION),
                    actionButton: (
                        <AppButton
                            icon={<PlusOutlined />}
                            translateCode={t(translateCodes.CREATE_SEGMENT)}
                            type='primary'
                        />
                    )
                }}
                rightActionRender={SegmentActionView}
                leftHeaderRender={SegmentOverview}
            />
        </Spacer>
    );
}
