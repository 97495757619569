import { PlusOutlined } from '@ant-design/icons';
import { AppButton, globalDrawerState } from '@esg/ui';
import React from 'react';
import { useRecoilState } from 'recoil';
import { EmployeeForm } from './EmployeeForm';
import { translateCodes } from '@/locales';

interface CreateEmployeeBtnProps {
	refetchEmployees?: () => void;
}

export const CreateEmployeeBtn = (props: CreateEmployeeBtnProps) => {
    const [, setDrawerState] = useRecoilState(globalDrawerState);

    return (
        <AppButton
            onClick={() => {
                setDrawerState({
                    isOpen: true,
                    titleTransCode: translateCodes.CREATE_EMPLOYEE,
                    content: <EmployeeForm {...{ refetchEmployees: props.refetchEmployees }} />,
                });
            }}
            translateCode={translateCodes.CREATE_EMPLOYEE}
            type='primary'
            icon={<PlusOutlined />}
        />
    );
};
