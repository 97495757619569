import React from 'react';
import { useParams } from 'react-router-dom';
import {CampaignOverview} from './CampaignOverview.tsx';
import {CampaignDetail} from './CampaignDetail.tsx';

export function Campaign() {
    const { id: campaignId } = useParams();

    if(campaignId)
    {
        return <CampaignDetail/>;
    }

    return <CampaignOverview />;
}
