import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface GetOverviewClientDetailRequest extends HttpRequestData {
    readonly pathData: {
        readonly clientId: string;
    };
}

export interface GetOverviewClientDetailResponse {
    totalSale: number;
    totalRevenue: number;
    totalAppointment: number;
}

export const getOverviewClientDetailReport = httpUtil.createHttpRequestMeta<
    GetOverviewClientDetailRequest,
    GetOverviewClientDetailResponse
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/clients-detail/overview/:clientId',
    authentication: 'bearer',
});