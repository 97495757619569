import React from 'react';
import { useParams } from 'react-router-dom';
import {PromotionOverview} from './PromotionOverview';
import {PromotionDetail} from './PromotionDetail';

export function Promotion() {
    const { id: promotionId } = useParams();

    if(promotionId)
    {
        return <PromotionDetail/>;
    }

    return <PromotionOverview />;
}
