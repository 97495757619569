import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';
import { GetCampaignResponseItem } from './getAllCampaign';

interface GetCampaignDetailRequest extends HttpRequestData {
    readonly pathData: {
        readonly id: string; 
    };
}

export const getDetailCampaign = httpUtil.createHttpRequestMeta<
    GetCampaignDetailRequest,
    GetCampaignResponseItem
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/campaigns/:id', 
    method: 'GET', 
    authentication: 'bearer',
});