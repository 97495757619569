import React from 'react';
import { IconProps } from '../../Types';

export const IconCharge = (props: IconProps) => {
    return (
        <svg
            {...props}
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M18.3337 8.33268H1.66699M9.16699 11.666H5.00033M1.66699 6.83268L1.66699 13.166C1.66699 14.0994 1.66699 14.5661 1.84865 14.9227C2.00844 15.2363 2.2634 15.4912 2.57701 15.651C2.93353 15.8327 3.40024 15.8327 4.33366 15.8327L15.667 15.8327C16.6004 15.8327 17.0671 15.8327 17.4236 15.651C17.7372 15.4912 17.9922 15.2363 18.152 14.9227C18.3337 14.5661 18.3337 14.0994 18.3337 13.166V6.83268C18.3337 5.89926 18.3337 5.43255 18.152 5.07603C17.9922 4.76243 17.7372 4.50746 17.4236 4.34767C17.0671 4.16602 16.6004 4.16602 15.667 4.16602L4.33366 4.16602C3.40024 4.16602 2.93353 4.16602 2.57701 4.34767C2.2634 4.50746 2.00844 4.76243 1.84865 5.07603C1.66699 5.43255 1.66699 5.89926 1.66699 6.83268Z'
                stroke={props.color}
                strokeWidth='1.66667'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

IconCharge.defaultProps = {
    className: '',
    onClick: () => { },
    color: '#475467',
};
