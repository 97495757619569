import { managementApis } from '@esg/business-management';
import {
    FlexBox,
    InputField,
    SelectField,
    SwitchField,
    TextAreaField,
    subDrawerState,
    useHttpQuery,
    useLocalesFormat,
} from '@esg/ui';
import { Card } from 'antd';
import React, { useMemo } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useFormContext } from 'react-hook-form';
import { taxApi } from '@esg/business-setting';
import { useTranslation } from 'react-i18next';
import { serviceRules } from '../../rules';
import { ServiceCategoryForm, TaxForm } from '../..';
import { translateCodes } from '@/locales';

export const InfoServiceForm = () => {
    const { data: serviceCategories , refetch: refetchServiceCategory } = useHttpQuery(managementApis.getAllServiceCategory);
    const { data: tax, refetch: refetchTax } = useHttpQuery(taxApi.taxGetAll);
    const [, setSubDrawerState] = useRecoilState(subDrawerState);
    const resetSubDrawerState = useResetRecoilState(subDrawerState);
    const { formatCurrencySymbol } = useLocalesFormat();
    const { t } = useTranslation();
    const { setValue } = useFormContext();

    const serviceCategoryOptions = useMemo(() => serviceCategories?.items?.map((item) => ({
        label: item.name,
        value: item.id,
    })) ?? []
    , [serviceCategories]);

    const taxOptions = useMemo(() => tax?.items.map((i) => ({
        label: i.name,
        value: i.id
    })) ?? []
    , [tax]);

    const showCreateServiceCategory = ()=>{
        setSubDrawerState({
            isOpen: true,
            titleTransCode: t(translateCodes.CREATE_SERVICE_CATEGORY),
            content: (
                <ServiceCategoryForm
                    refetch={refetchServiceCategory}
                    resetDrawerState={resetSubDrawerState}
                    onSuccess={(res) => {
                        setValue('serviceCategoryId', res?.id);
                    }}
                />
            ),
        });
    };

    const showCreateTaxForm = () => {
        setSubDrawerState({
            isOpen: true,
            titleTransCode: t(translateCodes.CREATE_TAX),
            content: (
                <TaxForm
                    onSuccess={(res) => {
                        refetchTax();
                        setValue('tax', res?.value);
                        resetSubDrawerState();
                    }}
                />
            ),
        });
    };

    return (
        <Card bordered={false}>
            <FlexBox direction='column' gap={16}>
                <InputField
                    rules={serviceRules.name(t)}
                    name='name'
                    label={translateCodes.NAME}
                    placeholder={translateCodes.ENTER_SERVICE_NAME}
                />
                <FlexBox gap={16} justifyContent='space-between'>
                    <SelectField
                        showSearch
                        filterOption={(input, option) => (option?.label?.toString() ?? '').includes(input)}
                        loading={!serviceCategories}
                        rules={serviceRules.serviceCategory}
                        name='serviceCategoryId'
                        label={translateCodes.SERVICE_CATEGORY}
                        placeholder={translateCodes.SELECT_CATEGORY}
                        options={serviceCategoryOptions}
                        onCreate={showCreateServiceCategory}
                    />
                    <InputField
                        addonBefore='mins'
                        rules={serviceRules.duration}
                        name='duration'
                        label={translateCodes.DURATION_MINUTE}
                        placeholder={translateCodes.ENTER_DURATION}
                    />
                </FlexBox>
                <FlexBox gap={16} justifyContent='space-between'>
                    <InputField
                        addonBefore={formatCurrencySymbol()}
                        type='number'
                        rules={serviceRules.price}
                        name='price'
                        label={translateCodes.PRICE}
                        placeholder={translateCodes.ENTER_PRICE}
                    />
                    <SelectField
                        addonBefore='%'
                        addonBeforeWidth='20%'
                        showSearch
                        loading={!tax}
                        filterOption={(input, option) => (option?.label?.toString() ?? '').includes(input)}
                        name='taxSettingId'
                        label={translateCodes.TAX}
                        placeholder={translateCodes.SELECT_TAX}
                        options={taxOptions}
                        onCreate={showCreateTaxForm}
                    />
                </FlexBox>
                <TextAreaField
                    name='description'
                    label={translateCodes.DESCRIPTION}
                    placeholder={translateCodes.ENTER_DESCRIPTION}
                    rules={serviceRules.descriptions(t)}
                />
                <SwitchField
                    directionLabel='row-reverse'
                    label={translateCodes.ALLOW_ONLINE_BOOKING}
                    name='isAllowOnlineBooking'
                    description={translateCodes.ALLOW_ONLINE_BOOKING_DESCRIPTION}
                />
            </FlexBox>
        </Card>
    );
};
