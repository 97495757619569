import { useFormContext } from 'react-hook-form';
import { dayjs } from '@esg/shared';
import { useMemo } from 'react';
import { AppointmentService } from '@esg/business-schedule';
import { getServicesSummary } from '../appointmentUtils';
import { useAppointmentContext } from '../AppointmentFormContext';
import { useBusinessHour } from '@/hooks/useBusinessHour';

export const useAvailableAppointmentDate = () => {
    const { watch } = useFormContext();
    const { services } = useAppointmentContext();
    const date = watch('appointmentDate');
    const appointmentServices = watch('appointmentServices') as AppointmentService[];

    const {
        branchWorkingTime: [startTime, endTime],
    } = useBusinessHour(date);

    const isOver = useMemo(() => {
        const serviceTimes = appointmentServices?.map((as) =>
            getServicesSummary(as.appointmentServiceDetails, services)
        ) || [];
        const longestTime = Math.max(...serviceTimes.map((r) => r.minutes));

        return dayjs(date).add(longestTime, 'minute').diff(endTime, 'minute') > 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(appointmentServices), date, endTime, services]);

    const isAvailableTime = useMemo(
        () => dayjs(date).diff(startTime, 'minute') >= 0 && endTime.diff(dayjs(date), 'minute') > 0,
        [date, endTime, startTime]
    );

    return !isAvailableTime || isOver;
};
