import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';

import { ActivityAction, ActivityType } from '@esg/shared/enum/CustomerActivityEnum';
import { env } from '../../configs';

interface GetAllCustomerActivityRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export interface GetAllCustomerActivityResponseItem {
    readonly id: string;
    readonly customerId: string;
    readonly branchId?: string;
    readonly activityAction: ActivityAction;
    readonly activityType: ActivityType;
    readonly activityDetails: activityDetail;
    readonly creationTime: string;
    readonly creatorId: string;
}
export interface activityDetail {
    readonly customerName: string;
    readonly actionBy: string;
    readonly objectId: string;
    readonly code: string;
}
export const getAllCustomerActivity = httpUtil.createHttpRequestMeta<GetAllCustomerActivityRequest, PaginationResponse<GetAllCustomerActivityResponseItem>>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/customer-activities',
    method: 'GET',
    authentication: 'bearer'
});