import React from 'react';
import {
    AppButton, Text, FlexBox, globalDrawerState,
    globalModalState, TableCustom, useHttpCommand, useHttpQuery,
    usePagination, ColumnActions,
    InternalLink} from '@esg/ui';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { managementApis } from '@esg/business-management';
import {  TableColumnsType, Tag } from 'antd';
import {  CampaignChannelType, CampaignStatus, CampaignType, CreateCampaignRequest, GetCampaignResponseItem, SaveDraftCampaignRequest, UpdateCampaignRequest } from '@esg/business-management/api/campaign';
import { PageLayout } from '@/layouts';
import { translateCodes } from '@/locales';
import { CampaignForm } from '@/components';
import { CAMPAIGN_URL } from '@/configs';
export function CampaignOverview() {
    const [, setDrawerState] = useRecoilState(globalDrawerState);
    const [, setModalState] = useRecoilState(globalModalState);
    const resetModalState = useResetRecoilState(globalModalState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = usePagination();
    const {
        data: campaignResponse,
        isFetching,
        isLoading,
        refetch,
    } = useHttpQuery(managementApis.getAllCampaign, {
        query: searchParams,
    });

    const {
        data: segmentsResponse,
        isLoading: segmentLoading,
    } = useHttpQuery(managementApis.getAllSegments, {
        query: searchParams,
    });

    const {
        data: promotionsResponse,
        isLoading: promotionLoading,
    } = useHttpQuery(managementApis.getAllPromotion, {
        query: searchParams,
    });

    const { mutateAsync: deleteSegmentAsync } = useHttpCommand(managementApis.deleteCampaign);
    const { mutateAsync: saveDraftCampaignAsync } = useHttpCommand(managementApis.saveDraftCampaign, {
        onSuccess: () => {
            resetModalState();
            resetDrawerState();
            refetch();
        }
    });

    const { mutateAsync: createCampaignAsync } = useHttpCommand(managementApis.createCampaign, {
        onSuccess: () => {
            resetModalState();
            resetDrawerState();
            refetch();
        }
    });

    const { mutateAsync: updateCampaignAsync } = useHttpCommand(managementApis.updateCampaign, {
        onSuccess: () => {
            resetModalState();
            resetDrawerState();
            refetch();
        }
    });

    const onDelete = React.useCallback(
        async (id: string) => {
            await deleteSegmentAsync(
                { pathData: { id: id } },
                {
                    onSuccess(_) {
                        resetModalState();
                        resetDrawerState();
                        refetch();
                    },
                }
            );
        },
        [deleteSegmentAsync, refetch, resetModalState, resetDrawerState]
    );

    const showModalDelete = React.useCallback((id: string) => {
        setModalState({
            content: (
                <FlexBox direction='column'>
                    <Text fontSize={18} fontWeight={700}>
                        {t(translateCodes.DELETE_CAMPAIGN)}
                    </Text>
                    <Text fontSize={14}>
                        {t(translateCodes.DELETE_CAMPAIGN_MSG)}
                    </Text>
                </FlexBox>
            ),
            isOpen: true,
            onOk: () => {
                onDelete(id);
            },
            showModalDelete: true,
        });
    }, [onDelete, setModalState, t]);

    const onEdit = React.useCallback(
        async (values: GetCampaignResponseItem) => {
            const payload: UpdateCampaignRequest['body'] = {
                id: values.id,
                name: values.name,
                type: values.type,
                target: values.target,
                status: values.status,
                targetValue: JSON.stringify(values.targetValue),
                promotionId: values.promotionId,
                dateApply: values.dateApply,
                timeApply: values.timeApply,
                campaignChannels: values.campaignChannels,
            };
            await updateCampaignAsync({ body: payload, pathData: { id: values.id } });
        },
        [updateCampaignAsync]
    );

    const onCreate = React.useCallback(
        async (values: GetCampaignResponseItem) => {
            const payload: CreateCampaignRequest['body'] = {
                id: values.id,
                name: values.name,
                type: values.type,
                target: values.target,
                status: values.status,
                targetValue: JSON.stringify(values.targetValue),
                promotionId: values.promotionId,
                dateApply: values.dateApply,
                timeApply: values.timeApply,
                campaignChannels: values.campaignChannels,
            };
            await createCampaignAsync({ body: payload });
        }, [createCampaignAsync]
    );

    const onSaveDraft = React.useCallback(
        async (values: GetCampaignResponseItem) => {
            const payload: SaveDraftCampaignRequest['body'] = {
                id: values.id,
                name: values.name,
                type: values.type,
                target: values.target,
                status: CampaignStatus.Draft,
                targetValue: JSON.stringify(values.targetValue),
                promotionId: values.promotionId,
                dateApply: values.dateApply,
                timeApply: values.timeApply,
                campaignChannels: values.campaignChannels,
            };
            await saveDraftCampaignAsync({ body: payload });
        }, [saveDraftCampaignAsync]
    );

    const onShowModalEdit = React.useCallback((values: GetCampaignResponseItem) => {
        setDrawerState({
            isOpen: true,
            titleTransCode: 'Edit Campaign',
            content: (
                <CampaignForm
                    onSubmit={onEdit}
                    refetch={refetch}
                    resetDrawerState={resetDrawerState}
                    defaultValues={values}
                    segments={segmentsResponse?.items || []}
                    promotions={promotionsResponse?.items || []}
                    onSaveDraft={onSaveDraft}
                />
            ),
        });
    }, [onEdit, onSaveDraft, promotionsResponse?.items, refetch, resetDrawerState, segmentsResponse?.items, setDrawerState]
    );
    const onShowModalCreate = React.useCallback(() => {
        setDrawerState({
            isOpen: true,
            titleTransCode: 'Create new campaign',
            content: (
                <CampaignForm
                    onSubmit={onCreate}
                    refetch={refetch}
                    resetDrawerState={resetDrawerState}
                    segments={segmentsResponse?.items || []}
                    promotions={promotionsResponse?.items || []}
                    onSaveDraft={onSaveDraft}
                />
            ),
        });
    }, [onCreate, onSaveDraft, promotionsResponse?.items, refetch, resetDrawerState, segmentsResponse?.items, setDrawerState]
    );
    const columns: TableColumnsType<GetCampaignResponseItem> = [
        {
            title: 'ID',
            dataIndex: 'code',
            key: 'code',
            render: (_, record) => {
                return <InternalLink href={`${CAMPAIGN_URL}/${record.id}`} >{record.code}</InternalLink>;
            },
        },
        {
            title: 'Campaign name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return <Text maxWidth={300} ellipsis>{record.name}</Text>;
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => {
                if(record.status == CampaignStatus.Running)
                {
                    return <Tag color='success'>Running</Tag>;
                }
                if (record.status == CampaignStatus.Completed) {
                    return <Tag color='default'>Completed</Tag>;
                }
                if (record.status == CampaignStatus.Draft) {
                    return <Tag color='purple'>Draft</Tag>;
                }
                if (record.status == CampaignStatus.Scheduled) {
                    return <Tag color='blue'>Scheduled</Tag>;
                }
                return <Tag color='volcano'>Paused</Tag>;
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (_, record) => {
                if(record.type == CampaignType.Notification)
                {
                    return <Tag>Notification</Tag>;
                }

                if (record.type == CampaignType.Promotion) {
                    return <Tag>Promotion</Tag>;
                }
                return null;
            },
        },
        {
            title: 'Channels',
            dataIndex: 'channel',
            key: 'channel',
            render: (_, record) => {
                const activeMail = record.campaignChannels?.find(c => c.type == CampaignChannelType.Mail)?.isActive;
                const activeSms = record.campaignChannels?.find(c => c.type == CampaignChannelType.Sms)?.isActive;
                return <FlexBox gap={6}>
                    {activeMail && <Tag>Email</Tag>}
                    {activeSms && <Tag>Sms</Tag>}
                </FlexBox>;
            },
        },
        {
            title: 'Delivered',
            dataIndex: 'delivered',
            key: 'delivered',
            render: (_, record) => {
                return <Text>{record.delivered}</Text>;
            },
        },
        {
            title: 'Failed',
            dataIndex: 'failed',
            key: 'failed',
            render: (_, record) => {
                return <Text>{record.failed}</Text>;
            },
        },
        {
            title: 'Opened',
            dataIndex: 'opened',
            key: 'opened',
            render: (_, record) => {
                return <Text>{record.opened}</Text>;
            },
        },
        {
            title: t(translateCodes.ACTION),
            key: 'action',
            render: (_, record) => {
                return (
                    <ColumnActions
                        onClickDelete={()=>{showModalDelete(record.id || '');}}
                        onClickEdit={() => onShowModalEdit(record)}
                    />
                );
            },
        },
    ];
    return (
        <PageLayout
            pageTitleTransCode={t(translateCodes.CLIENT_CAMPAIGN)}
            rightHeaderPage={
                <AppButton
                    icon={<PlusOutlined />}
                    translateCode={t(translateCodes.CREATE_CAMPAIGN)}
                    type='primary'
                    onClick={onShowModalCreate}
                />
            }
        >
            <TableCustom
                onPageChange={(page) =>
                    setSearchParams({ ...searchParams, page: page.toString() })
                }
                onSearch={(value) =>
                    setSearchParams({ ...searchParams, search: value })
                }
                loading={isFetching || isLoading || segmentLoading || promotionLoading }
                titleTableTransCode='Campaign List'
                placeholderSearchTransCode='Search by campaign name'
                columns={columns}
                dataSource={campaignResponse?.items}
                pageInfo={campaignResponse?.meta}
                emptyOption={{
                    title: t(translateCodes.ADD_CAMPAIGN),
                    description: t(translateCodes.EMPTY_CAMPAIGN_DESCRIPTION),
                    actionButton: (
                        <AppButton
                            icon={<PlusOutlined />}
                            translateCode={t(translateCodes.CREATE_CAMPAIGN)}
                            type='primary'
                            onClick={onShowModalCreate}
                        />
                    )
                }}
            />
        </PageLayout>
    );
}