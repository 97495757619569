import {  HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface DeleteSegmentRequest extends HttpRequestData {
}

export const deleteSegment = httpUtil.createHttpRequestMeta<
    DeleteSegmentRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/segments/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
