import { AppAvatar, Block, FlexBox, ListNameColumn, TableCustom, Text, useHttpQuery, useLocalesFormat, usePagination } from '@esg/ui';
import { Dayjs } from 'dayjs';
import React, { useMemo } from 'react';
import { AppointmentStatus } from '@esg/shared';
import { TableColumnsType, Tag } from 'antd';
import { flatten } from 'lodash';
import { getRevenuePaymentDetailReport } from '@esg/business-report/api/revenue-report';
import { GetDetailSaleResponse, SaleDetailGetAllResponseItem } from '@esg/business-pos';
import { useTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';
import { translateCodes } from '@/locales';
import SaleEmployeeColumn from '@/pages/fast-pos/sale/children/SaleEmployeeColumn';
import { saleHelper } from '@/helpers';

interface RevenueDetailTableProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly appointmentStatus?: AppointmentStatus;
    readonly serviceIds?: string[];
    readonly employeeIds?: string[];
    readonly categoryId?: string;
    readonly paymentMethodSettingId?: string;
}

export function RevenuePaymentMethodDetailTable(props: RevenueDetailTableProps) {
    const { t } = useTranslation();
    const { formatCurrency, formatDate, formatCurrencySymbol } = useLocalesFormat();
    const { dateRange } = props;
    const [searchParams, setSearchParams] = usePagination({
        pageSize: '10',
        orderBy: 'code'
    });

    const { data: revenuePaymentDetailReport, isFetching } = useHttpQuery(getRevenuePaymentDetailReport, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
            paymentMethodSettingId: props.paymentMethodSettingId,
            ...searchParams,
        },
    });

    const columns: TableColumnsType<GetDetailSaleResponse> = useMemo(()=>([
        {
            title: 'Id',
            dataIndex: 'code',
            sorter: true,
            onHeaderCell: () => ({
                onClick: () => {
                    const order = searchParams.orderDirection === 'desc' ? 'asc' : 'desc';
                    setSearchParams({ ...searchParams, orderDirection: order });
                }
            }),
        },
        {
            title: t(translateCodes.CLIENT),
            dataIndex: 'customer',
            render: (_, record) => {
                return (<FlexBox alignItems='center' gap={8}>
                    <AppAvatar data={record.customer} icon={record.customer ? undefined : <UserOutlined /> }/>
                    <Text maxLines={1} maxWidth={100} ellipsis={true} translateCode={record.customer ? record.customer.fullName : translateCodes.WALK_IN} />
                </FlexBox>);
            },
        },
        {
            title: t(translateCodes.EMPLOYEES),
            dataIndex: 'saleDetails',
            render: (saleDetails: SaleDetailGetAllResponseItem[]) => <SaleEmployeeColumn saleDetails={saleDetails} />
        },
        {
            title: t(translateCodes.TIME),
            dataIndex: 'date',
            render: (_, record) => {
                const createdTime = formatDate(record.date,'hh:mm A');
                const createdDate = formatDate(record.date);
                return `${createdTime} - ${createdDate}`;
            },
        },
        {

            title: t(translateCodes.SERVICES_TIME),
            dataIndex: 'saleDetails',
            render: (saleDetails?: SaleDetailGetAllResponseItem[]) => {
                const listService = flatten(saleDetails?.map(a => a.service));
                const names = listService.filter(o => o?.name !== null && o?.name !== undefined).map(o => o?.name);
                const namesUnique = [...new Set(names)];
                return <ListNameColumn names={namesUnique} limit={2} />;
            },
        },
        {
            title: t(translateCodes.TIP),
            dataIndex: 'tip',
            render: (_, record) => {
                return `${record.tip} ${record.tipUnit == 0 ? '%' : formatCurrencySymbol()}`;
            },
        },
        {
            title: t(translateCodes.DISCOUNT),
            dataIndex: 'Discount',
            render: (_, record) => saleHelper.calcDiscountAmount(record.saleDetails ?? []),
        },
        {
            title: t(translateCodes.TOTAL),
            dataIndex: 'totalAmount',
            render: (_, record) => {
                return (
                    <Text>
                        {formatCurrency(record.totalAmount)}
                    </Text>
                );
            },
        },
    ]),[formatCurrency, formatCurrencySymbol, formatDate, searchParams, setSearchParams, t]);

    return (
        <Block width={1300}>
            <TableCustom
                onPageChange={(page) =>
                    setSearchParams({ ...searchParams, page: page.toString() })
                }
                onSearch={(value) =>
                    setSearchParams({ ...searchParams, search: value })
                }
                pageInfo={revenuePaymentDetailReport?.meta}
                titleTableTransCode={translateCodes.BILL_LIST}
                placeholderSearchTransCode={translateCodes.CLIENT_PLACEHOLDER_SEARCH}
                showHeader={true}
                loading={isFetching}
                columns={columns}
                dataSource={revenuePaymentDetailReport?.items}
                subTitleTable={<Tag color='processing'>{`${revenuePaymentDetailReport?.meta.totalItems} ${t(translateCodes.BILLS)}`}</Tag>}
            />
        </Block>
    );
}
