import { FlexBox, FormBase, FormLayoutModal, useHttpCommand, InputField, SwitchField } from '@esg/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GetAllCustomerGroupResponseItem, customerApi } from '@esg/business-crm';
import { translateCodes } from '@/locales';

interface CreateGroupFormProps {
    readonly onCancel?: () => void;
    readonly onSuccess?: (response?: GetAllCustomerGroupResponseItem) => void;
    readonly group?: GetAllCustomerGroupResponseItem;
    readonly activeDefault?: boolean;
}

export default function CreateGroupForm(props: CreateGroupFormProps) {
    const { t } = useTranslation();
    const isUpdate = !!props.group?.id;
    const { onSuccess } = props;

    const { mutateAsync: createCustomerGroup, isPending: isLoadingCreate } = useHttpCommand(
        customerApi.createCustomerGroup, { onSuccess }
    );

    const { mutateAsync: updateCustomerGroup, isPending: isLoadingUpdate } = useHttpCommand(
        customerApi.updateCustomerGroup, { onSuccess }
    );

    const submit = async (values: GetAllCustomerGroupResponseItem) => {
        isUpdate ? await updateCustomerGroup({
            pathData: { id: props.group?.id },
            body: values,
        }) : await createCustomerGroup({ body: props.activeDefault ? { ...values, active: true } : values });

    };

    return (
        <FormBase onSubmit={submit} defaultValues={props.group}>
            {() => (
                <FormLayoutModal
                    isLoadingButton={isLoadingCreate || isLoadingUpdate}
                    useSubmitButton={true}
                    onCancel={props.onCancel}
                    okTextCode={isUpdate ? translateCodes.SAVE : translateCodes.CREATE}
                >
                    <FlexBox direction='column' gap={16} alignItems='flex-start'>
                        <InputField
                            name='name'
                            label={t(translateCodes.GROUP_NAME)}
                            placeholder='eg: New client'
                            rules={{required: translateCodes.GROUP_NAME_REQUIRED}}
                        />
                        {!props.activeDefault && <FlexBox gap={8} alignItems='center'>
                            <SwitchField
                                defaultChecked={props.group?.active}
                                name='active'
                                description={t(translateCodes.ACTIVE)}
                                directionLabel='row-reverse'
                            />
                        </FlexBox>}
                    </FlexBox>
                </FormLayoutModal>
            )}
        </FormBase>
    );
}
