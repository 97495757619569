import './ColumnActions.scss';
import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { translateCodes } from '../../../locales';
import { IconEye, IconPen, IconTrash } from '../../icons';
import { Block, FlexBox } from '../../layouts';
import { AppButton } from '../../buttons';

interface ColumnActionsProps {
	viewDetailUrl?: string;
	onClickEdit?: () => void;
	onClickDelete?: () => void;
    onChangeStatus?: ()=> void;
    status?: string;
}

export const ColumnActions = (props: ColumnActionsProps) => {
    const { viewDetailUrl, onClickEdit, onClickDelete, onChangeStatus } = props;
    const { t } = useTranslation();
    return (
        <Block id='column-actions'>
            <FlexBox alignItems='center' gap={18}>
                {viewDetailUrl && (
                    <Tooltip placement='top' title={t(translateCodes.VIEW)}>
                        <Link to={viewDetailUrl} >
                            <AppButton className='app-button-hover' icon={<IconEye className='icon-view'/>} />
                        </Link>
                    </Tooltip>
                )}
                {onClickEdit && (
                    <Tooltip placement='top' title={t(translateCodes.EDIT)}>
                        <AppButton
                            onClick={onClickEdit}
                            className='app-button-hover'
                            icon={<IconPen
                                className='icon-edit'
                            />} 
                        />
                    </Tooltip>
                )}
                {onClickDelete && (
                    <Tooltip
                        placement='top'
                        title={t(translateCodes.DELETE)}
                    >
                        <AppButton
                            className='app-button-hover'
                            onClick={onClickDelete}
                            icon={<IconTrash className='icon-delete'/>} 
                        />
                    </Tooltip>
                )}
                {onChangeStatus && (
                    <Tooltip
                        placement='top'
                        title={t(translateCodes.EDIT)}
                    >
                        <span style={{color:'#0B5DCC'}} onClick={onChangeStatus}>
                            {props.status}
                        </span>
                    </Tooltip>
                )}
            </FlexBox>
        </Block>
    );
};
