import {
    Text,
    FlexBox} from '@esg/ui';
import React from 'react';
import { Card } from 'antd';

export const ScheduleCampaignForm = () => {

    return (
        <FlexBox direction='column' gap={8}>
            <Text
                fontWeight={600}
                lineHeight='20px'
                color='#344054'
            >
                Schedule & Send
            </Text>
            <Card className='card-body' />
        </FlexBox>
    );
};