import React from 'react';
import { IconProps } from '../../Types';

export const IconArrowDownClient = (props: IconProps) => {
    return (
        <svg 
            width='16' 
            height='7' 
            viewBox='0 0 10 6' 
            fill='none' 
            xmlns='http://www.w3.org/2000/svg'
            {...props} 
        >
            <path 
                d='M1 1L5 5L9 1' 
                stroke='#98A2B3' 
                strokeWidth='1.33333' 
                strokeLinecap='round' 
                strokeLinejoin='round' 
            />
        </svg>
    );
};

IconArrowDownClient.defaultProps = {
    className: '',
    onClick: () => {},
    color: '#475467',
};
