export enum AppointmentStatus {
    Confirmed = 0,
	Booked = 1,
    CheckedIn = 2,
	Completed = 3,
	NoShow = 4,
	Cancelled = 5,
}

export const getAppointmentStatusLabel = (value: AppointmentStatus) => {
    switch (value) {
    case AppointmentStatus.Confirmed:
        return 'Confirmed';
    case AppointmentStatus.Booked:
        return 'Booked';
    case AppointmentStatus.Cancelled:
        return 'Cancelled';
    case AppointmentStatus.CheckedIn:
        return 'Checked in';
    case AppointmentStatus.Completed:
        return 'Completed';
    case AppointmentStatus.NoShow:
        return 'No show';
    default:
        return null;
    }
};

export const getAppointmentBackgroundColor = (value: AppointmentStatus) => {
    switch (value) {
    case AppointmentStatus.Confirmed:
        return '#ECFDF3';
    case AppointmentStatus.Booked:
        return '#FFF0DB';
    case AppointmentStatus.Completed:
        return '#F2F4F7';
    case AppointmentStatus.NoShow:
        return '#FFDEE7';
    case AppointmentStatus.CheckedIn:
        return '#E7F1FE';
    default:
        return '#FFFAEB';
    }
};

export const getAppointmentBorderColor = (value: AppointmentStatus) => {
    switch (value) {
    case AppointmentStatus.Confirmed:
        return '#47CD89';
    case AppointmentStatus.Booked:
        return '#FFC56E';
    case AppointmentStatus.Completed:
        return '#98A2B3';
    case AppointmentStatus.CheckedIn:
        return '#3D8CF5';
    case AppointmentStatus.NoShow:
        return '#FD386D';
    default:
        return '#B54708';
    }
};

export const getAppointmentLabelColor = (value: AppointmentStatus) => {
    switch (value) {
    case AppointmentStatus.Confirmed:
        return '#067647';
    case AppointmentStatus.Booked:
        return '#084CA5';
    case AppointmentStatus.Completed:
        return '#344054';
    case AppointmentStatus.NoShow:
        return '#B54708';
        
    default:
        return '#ECFDF3';
    }
};
export const getAppointmentTagColor = (value: AppointmentStatus) => {
    switch (value) {
    case AppointmentStatus.Confirmed:
        return 'green';
    case AppointmentStatus.Booked:
        return 'orange';
    case AppointmentStatus.CheckedIn:
        return 'blue';
    case AppointmentStatus.Cancelled:
        return 'volcano';
    case AppointmentStatus.NoShow:
        return 'magenta';
        
    default:
        return '';
    }
};

export const getAppointmentStatusAction = (value: AppointmentStatus) => {
    switch (value) {
    case AppointmentStatus.Booked:
        return {
            label: 'Confirm',
            currentValue: value,
            nextValue: AppointmentStatus.Confirmed,
            allowAction: true
        };
    case AppointmentStatus.CheckedIn:
        return {
            label: 'Check out',
            currentValue: value,
            nextValue: AppointmentStatus.Completed,
            allowAction: true

        };
    case AppointmentStatus.Completed:
        return {
            label: 'View sale',
            currentValue: value,
            nextValue: value,
        };
    case AppointmentStatus.NoShow:
        return {
            label: 'Undo no show',
            currentValue: value,
            nextValue: AppointmentStatus.Booked,
        };
    case AppointmentStatus.Cancelled:
        return {
            label: 'Undo cancel',
            currentValue: value,
            nextValue: AppointmentStatus.Booked,
        };
    default:
        return {
            label: 'Check in',
            currentValue: value,
            nextValue: AppointmentStatus.CheckedIn,
            allowAction: true
        };
    }
};
