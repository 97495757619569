import { DownOutlined } from '@ant-design/icons';
import { GetCustomerDetailResponse } from '@esg/business-crm';
import { GetAllCustomerActivityResponseItem } from '@esg/business-crm/api/customer-activity';
import { managementApis } from '@esg/business-management';
import { AppointmentGetDetailResponse } from '@esg/business-schedule';
import { FlexBox, Text, useHttpQuery, useLocalesFormat } from '@esg/ui';
import { Card, Tree } from 'antd';
import React, { Key, useEffect, useMemo, useState } from 'react';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { useRecoilState } from 'recoil';
import { AppointmentUpcomingEmpty } from './AppointmentUpcomingEmpty';
import './CustomerDetailTable.scss';
import { AppointmentUpcomingItem } from './customer-table/AppointmentUpcomingItem';
import { ItemTreeCustomerActivity } from './ItemTreeCustomerActivity';
import { appointmentEvents } from '@/event';
import { translateCodes } from '@/locales/translateCodes';

interface CustomerActivityProps {
    customerActivities: GetAllCustomerActivityResponseItem[];
    customer?: GetCustomerDetailResponse;
    appointmentUpcoming: AppointmentGetDetailResponse[];
    refetchAppointmentUpcoming: () => void;
    isLoadingAppointmentUpcoming: boolean;
    refetchCustomerActivity: () => void;
}

export function CustomerActivity({
    customerActivities,
    customer,
    appointmentUpcoming,
    refetchAppointmentUpcoming,
    isLoadingAppointmentUpcoming,
    refetchCustomerActivity
}: CustomerActivityProps) {
    const { formatDate } = useLocalesFormat();
    const [currentLocale] = useRecoilState(currentLocaleState);
    const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);

    const {
        data: serviceResponse,
        isFetching: isServiceFetching,
    } = useHttpQuery(managementApis.getAllServices);

    useEffect(() => {
        const listen = appointmentEvents.refetchAppointments.listen(() => {
            refetchAppointmentUpcoming();
            refetchCustomerActivity();
        });

        return () => {
            listen();
        };
    }, [refetchAppointmentUpcoming, refetchCustomerActivity]);

    const isLoading = isLoadingAppointmentUpcoming || isServiceFetching;

    const groupedActivities = useMemo(
        () =>
            customerActivities?.reduce<Record<string, typeof customerActivities>>((acc, activity) => {
                if (activity.branchId) {
                    const date = formatDate(activity.creationTime);
                    if (!acc[date]) acc[date] = [];
                    acc[date].push(activity);
                }
                return acc;
            }, {}),
        [customerActivities, formatDate]
    );

    const treeData = useMemo(
        () =>
            Object.entries(groupedActivities).map(([date, activities], dateIndex) => ({
                title: (
                    <span style={{ fontSize: 16, fontWeight: 'bold' }}>
                        {formatDate(date, `ddd, ${currentLocale?.format}`)}
                    </span>
                ),
                selectable: false,
                key: `day-${dateIndex}`,
                children: activities.map((activity, activityIndex) => ({
                    title: <ItemTreeCustomerActivity refetchCustomerActivity={refetchCustomerActivity} activity={activity} />,
                    key: `day-${dateIndex}-activity-${activityIndex}`,
                    selectable: false,
                })),
            })),
        [groupedActivities, formatDate, currentLocale?.format, refetchCustomerActivity]
    );

    const handleExpand = (keys: Key[]) => {
        setExpandedKeys(keys);
    };

    useEffect(() => {
        const defaultExpandedKeys = treeData.map((node) => node.key);
        setExpandedKeys(defaultExpandedKeys);
    }, [treeData]);

    const appointmentUpcomingItem =
        appointmentUpcoming?.map(o => {
            return (
                <>
                    <AppointmentUpcomingItem
                        appointmentResponse={o}
                        serviceResponse={serviceResponse?.items ?? []}
                        refetchAppointmentUpcoming={refetchAppointmentUpcoming}
                    />
                </>
            );
        });

    const renderAppointmentUpcoming = ()=>{

        if(appointmentUpcoming.length == 0){
            return <AppointmentUpcomingEmpty refetchAppointmentUpcoming={refetchAppointmentUpcoming} customer={customer} />;
        }
        return appointmentUpcomingItem;
    };

    return (
        <FlexBox direction='column' gap={20}>
            <Card loading={isLoading}>
                <FlexBox direction='column' gap={12}>
                    <Text fontSize={18} fontWeight='bold'>Upcoming appointments ({appointmentUpcoming.length})</Text>
                    {renderAppointmentUpcoming()}
                </FlexBox>
            </Card>
            <Card style={{ maxHeight: 450, overflowY: 'auto' }}>
                <FlexBox direction='column' gap={20}>
                    <Text translateCode={translateCodes.ACTIVITY_HISTORY} fontSize={18} fontWeight='bold' />
                    <Tree
                        className='custom-tree'
                        showLine
                        onExpand={handleExpand}
                        expandedKeys={expandedKeys}
                        switcherIcon={<DownOutlined />}
                        treeData={treeData}
                    />
                </FlexBox>
            </Card>
        </FlexBox>
    );
}

