import { AppAvatar, Block, ExternalLink, FlexBox, Spacer, Text, IconTerminateCustomer } from '@esg/ui';
import React from 'react';
import { GetAllCustomerResponseItem, GetCustomerDetailResponse } from '@esg/business-crm';
import { Badge, Card } from 'antd';
import { CustomerLabelTag } from '@/components/customer/CustomerLabelTag';
import { CUSTOMER_DETAIL_URL } from '@/configs';

interface CustomerSelectValueProps {
    customer?: GetAllCustomerResponseItem;
    onClear: ()=> void;
    disabled?: boolean;
}

export default function CustomerSelectValue(props: CustomerSelectValueProps) {
    const {customer, onClear, disabled} = props;
    // const navigate = useNavigate();
    
    return (
        <Card size='small'>
            <FlexBox alignItems='center' gap={16}>
                <AppAvatar data={props.customer} size='lg' />
                <FlexBox direction='column'>
                    <FlexBox>
                        <ExternalLink 
                            fontWeight='bold' 
                            href={`${CUSTOMER_DETAIL_URL}/${customer?.id}`}
                            target='_blank'
                            color='#101828'
                        >
                            {customer?.fullName}
                        </ExternalLink>
                        <Spacer right={10}/>
                        <CustomerLabelTag customer={customer as GetCustomerDetailResponse} /> 
                    </FlexBox>
                    <FlexBox flexWrap='wrap' justifyContent='flex-start' gap={8}>
                        {customer?.email && <Text ellipsis maxWidth={200} maxLines={1}>{customer?.email} </Text>}
                        {customer?.email && customer?.phone && <Block><Badge status='default' className='badge-point' /></Block>}
                        {customer?.phone && <Text ellipsis maxWidth={200} maxLines={1}>{customer?.phone}</Text>}
                    </FlexBox>
                </FlexBox>
                <FlexBox flex='unset' gap={4} height={55}>
                    {!disabled && (
                        <IconTerminateCustomer 
                            onClick={onClear} 
                            cursor='pointer'
                        />
                    )}
                </FlexBox>
            </FlexBox>
        </Card>
    );
}
