import React, { useEffect, useState } from 'react';
import { AppointmentStatus, dayjs, getAppointmentStatusLabel, getAppointmentTagColor, } from '@esg/shared';
import { GetCustomerDetailResponse } from '@esg/business-crm';
import { ColumnAvatarGroup, TableCustom, useHttpQuery, ListNameColumn, useLocalesFormat, Block, globalModalState, useHttpCommand, usePagination, AppButton, IconFilter } from '@esg/ui';
import { Dropdown, TableColumnsType, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { AppointmentGetDetailResponse, schedulerApis } from '@esg/business-schedule';
import { managementApis } from '@esg/business-management';

import { useRecoilState, useResetRecoilState } from 'recoil';
import { viewRequests } from '@esg/framework';
import { AppointmentColumnActions } from './appointment-column-table/AppointmentColumnAction';
import FilterCustomerAppointmentForm from './appointment-column-table/FilterCustomerAppointmentForm';
import { translateCodes } from '@/locales';
import { getAppointmentSummary, getEmployeesAppointment } from '@/components/form/Appointment/@share/appointmentUtils';
import { AppointmentFormBtn } from '@/components';
import { AppointmentDeleteConfirm } from '@/components/appointment/AppointmentDeleteConfirm';
import { appointmentEvents } from '@/event';

interface CustomerAppointmentTableProps {
    customer?: GetCustomerDetailResponse;
}

export interface FilterCustomerAppointmentParams {
    readonly serviceIds?: string[];
    readonly employeeIds?: string[];
    readonly date?: string;
    readonly status?: AppointmentStatus[];
}

export const CustomerAppointmentTable = ({ customer }: CustomerAppointmentTableProps) => {

    const { t } = useTranslation();
    const { formatDate, formatCurrency } = useLocalesFormat();
    const [dropdownVisible, setDropdownVisible] = useState(false);
            
    const handleDropdownVisibleChange = (visible: boolean) => {
        setDropdownVisible(visible);
    };
        
    const [filterParams, setFilterParams] = useState<FilterCustomerAppointmentParams>();
    const [searchParams, setSearchParams] = usePagination(
        {
            pageSize: '10',
            orderBy: 'appointmentCode',
        }
    );

    const [, setModalGlobalState] = useRecoilState(globalModalState);
    const resetModal = useResetRecoilState(globalModalState);
    const { mutateAsync: deleteAppointment } = useHttpCommand(schedulerApis.deleteAppointment, {
        onSuccess() {
            resetModal();
            refetch!();
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.NM_SC_03),
            });
        },
    });

    const showFilterDropdown = () => {
        return(
            <Dropdown
                placement='bottomRight'
                destroyPopupOnHide
                dropdownRender={
                    () => <>
                        <div
                            onClick={(e) => e.stopPropagation()} 
                            onMouseDown={(e) => e.preventDefault()}
                            style={{
                                padding: '16px',
                                background: '#fff',
                                borderRadius: '4px',
                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                            }}
                        >
                            <FilterCustomerAppointmentForm
                                defaultValues={filterParams}
                                handleSubmit={(value) => {
                                    setFilterParams(value);
                                    setDropdownVisible(false);
                                    refetch();
                                }}
                                onCancel={() => setDropdownVisible(false)}
                            />
                        </div>
                    </>
                }
                open={dropdownVisible}
                onOpenChange={handleDropdownVisibleChange}
                trigger={['click']}
            >
                <AppButton
                    translateCode='Filter'
                    icon={<IconFilter />}
                />
            </Dropdown>
        );
    };

    const onPopupConfirmDelete = React.useCallback((appointment: AppointmentGetDetailResponse) => 
    {
        setModalGlobalState({
            content: <AppointmentDeleteConfirm item={appointment} />,
            isOpen: true,
            onOk: () => {
                if (appointment.id) {
                    deleteAppointment({
                        pathData: { id: appointment.id }
                    });
                    return;
                }
            },
            showModalDelete: true,
        });
    },
    [deleteAppointment, setModalGlobalState]);

    const {
        data: appointmentResponse,
        isFetching: isAppointmentFetching,
        refetch: refetch
    } = useHttpQuery(schedulerApis.getAllAppointments,
        {
            query: {
                ...filterParams,
                StartDate: filterParams?.date,
                EndDate: filterParams?.date,
                customerId: customer!.id,
                search: searchParams.searchByMain,
                orderBy: searchParams.orderByMain,
                orderDirection: searchParams.mainDirection,
                page: searchParams.pageMain,
                pageSize: searchParams.pageSize,
                ...searchParams
            },
        }
    );

    useEffect(() => {
        const listen = appointmentEvents.refetchAppointments.listen(() => {
            refetch();
        });
    
        return () => {
            listen();
        };
    }, [refetch]);

    const {
        data: serviceResponse,
        isFetching: isServiceFetching,
    } = useHttpQuery(managementApis.getAllServices);

    const isLoading = isAppointmentFetching || isServiceFetching;

    const employeeColumn = (appointment: AppointmentGetDetailResponse) => {
        const employees = getEmployeesAppointment(appointment);
        return (
            <ColumnAvatarGroup
                items={employees ?? []}
                maxCount={2}
                prefixEnd={
                    employees?.length === 1 ?
                        employees[0].fullName ?? '' :
                        `${employees?.length} employees`}
            />
        );
    };

    const timeColumn = (appointment: AppointmentGetDetailResponse) => {
        const { minutes } = getAppointmentSummary(appointment.appointmentServices, serviceResponse?.items ?? []);

        const startTime = formatDate(dayjs(appointment?.appointmentDate), 'hh:mm A');
        const endTime = formatDate(dayjs(appointment?.appointmentDate).add(minutes, 'minutes'), 'hh:mm A');
        const createdDate = formatDate(appointment?.appointmentDate);

        return (
            <Block>
                <p>{startTime} - {endTime}</p>
                <p>{createdDate}</p>
            </Block>
        );
    };

    const columns: TableColumnsType<AppointmentGetDetailResponse> = [
        {
            title: 'Schedule ID',
            dataIndex: 'appointmentCode',
            key: 'appointmentCode',
            sorter: true,
            onHeaderCell: () => ({
                onClick: () => {
                    const order = searchParams.mainDirection === 'desc' ? 'asc' : 'desc';
                    setSearchParams({ ...searchParams, mainDirection: order });
                }
            }),
            render: (_, record) => (
                <AppointmentFormBtn
                    className='appointment-form-btn'
                    type='text'
                    size='small'
                    initialValues={record}
                    refetch={refetch}
                >
                    <a style={{ color: '#004EEB' }}>{record.appointmentCode}</a>
                </AppointmentFormBtn>
            )
        },
        {
            title: t(translateCodes.EMPLOYEES),
            key: 'employee',
            render: (_, record) => employeeColumn(record),
        },
        {
            title: t(translateCodes.DATE_AND_TIME),
            key: 'time',
            render: (_, record) => timeColumn(record),
        },
        {
            title: t(translateCodes.SERVICES_TIME),
            key: 'service',
            render: (_, record) => {
                const servicesName = record.appointmentServices.map((s) =>
                    s.appointmentServiceDetails.map((o) => o.service?.name)
                ).flat() ?? [];
                return <ListNameColumn names={servicesName} />;
            }
        },
        {
            title: t(translateCodes.STATUS),
            key: 'status',
            render: (_, record) => {
                return (
                    <Tag color={getAppointmentTagColor(record.status)}>
                        {getAppointmentStatusLabel(record.status)}
                    </Tag>
                );
            },
        },
        {
            title: t(translateCodes.TOTAL_AMOUNT),
            key: 'total',
            render: (_, record) => {
                const { price } = getAppointmentSummary(record.appointmentServices, serviceResponse?.items ?? []);
                return formatCurrency(price);
            },
        },
        {
            width: 116,
            title: t(translateCodes.ACTION),
            render: (_, record) => {
                const allowRebook = record.status == AppointmentStatus.Completed || record.status == AppointmentStatus.Cancelled;
                return (
                    <AppointmentColumnActions
                        appointment={record}
                        isShowRebook={allowRebook}
                        refetch={refetch}
                        onClickDelete={()=>onPopupConfirmDelete(record)}
                    />
                );
            },
        },
    ];
    return (
        <TableCustom
            onPageChange={(page) =>
                setSearchParams({...searchParams, page: page.toString()})
            }
            showFilterDropdown={showFilterDropdown}
            onSearch={(value) =>
                setSearchParams({ ...searchParams, searchByMain: value })
            }
            placeholderSearchTransCode={translateCodes.CLIENT_DETAIL_PLACEHOLDER_SEARCH}
            loading={isLoading}
            titleTableTransCode={translateCodes.APPOINTMENT_LIST}
            columns={columns}
            dataSource={appointmentResponse?.items ?? []}
            pageInfo={appointmentResponse?.meta}
        />
    );
};

