import React from 'react';
import { IconProps } from '../../Types';

export const IconArrowUpClient = (props: IconProps) => {
    return (
        <svg width='15' height='7' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M9 5L5 1L1 5' stroke='#98A2B3' strokeWidth='1.33333' strokeLinecap='round' strokeLinejoin='round'/>
        </svg>       
    );
};

IconArrowUpClient.defaultProps = {
    className: '',
    onClick: () => {},
    color: '#475467',
};
