import React from 'react';
import { IconProps } from '../../Types';

export const IconLocation = (props: IconProps) => {
    const { color = '#98A2B3', width = 12, height = 16 } = props;
    return (
        <svg width={width} height={height} viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M6.00033 8.66671C7.10489 8.66671 8.00033 7.77128 8.00033 6.66671C8.00033 5.56214 7.10489 4.66671 6.00033 4.66671C4.89576 4.66671 4.00033 5.56214 4.00033 6.66671C4.00033 7.77128 4.89576 8.66671 6.00033 8.66671Z' stroke='#98A2B3' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round'/>
            <path d='M6.00033 14.6667C8.66699 12 11.3337 9.61223 11.3337 6.66671C11.3337 3.72119 8.94584 1.33337 6.00033 1.33337C3.05481 1.33337 0.666992 3.72119 0.666992 6.66671C0.666992 9.61223 3.33366 12 6.00033 14.6667Z' stroke={color} strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round'/>
        </svg>

    );
};