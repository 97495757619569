import { PlusOutlined } from '@ant-design/icons';
import { AppButton, globalDrawerState } from '@esg/ui';
import React from 'react';
import { useRecoilState } from 'recoil';

import { TaxForm } from '@/components';

interface CreateTaxBtnProps {
  readonly refetchCreateTax?: () => void;
}

export const CreateTaxBtn = (props: CreateTaxBtnProps) => {
    const [, setDrawerState] = useRecoilState(globalDrawerState);
    return (
        <AppButton
            translateCode='Create Tax'
            icon={<PlusOutlined />}
            type='primary'
            onClick={() => {
                setDrawerState({
                    isOpen: true,
                    titleTransCode: 'Create Tax',
                    content: (
                        <TaxForm
                            onSuccess={props.refetchCreateTax}
                        />
                    )
                });
            }}
        />
    );
};
