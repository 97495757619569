import React from 'react';
import { Dayjs } from '@esg/shared';
import { Block, useLocalesFormat } from '@esg/ui';

interface SaleDateTimeColumnProps {
    readonly checkoutAt?: Dayjs,
    readonly createdAt: Dayjs
}

export default function SaleDateTimeColumn(props: SaleDateTimeColumnProps) {
    const { checkoutAt, createdAt } = props;
    const { formatDate } = useLocalesFormat();

    const checkoutAtTime = checkoutAt ? formatDate(checkoutAt, 'hh:mm A') : '';
    const createdTime = formatDate(createdAt, 'hh:mm A');
    const createdDate = formatDate(createdAt);  

    return (
        <Block>
            <p>{createdTime} - {checkoutAt ? checkoutAtTime : ''}</p>
            <p>{createdDate}</p>
        </Block>
    );
}
