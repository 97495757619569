import { PlusOutlined } from '@ant-design/icons';
import { AppButton, globalDrawerState } from '@esg/ui';
import React, { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useQueryClient } from '@tanstack/react-query';
import { getAllRole } from '@esg/business-account';
import { viewRequests } from '@esg/framework';
import { useTranslation } from 'react-i18next';
import { translateCodes } from '@/locales';
import { RoleForm } from '@/components';

export default function CreateRoleButton() {
    const [, setGlobalDrawer] = useRecoilState(globalDrawerState);
    const resetGlobalDrawer = useResetRecoilState(globalDrawerState);
    const queryClient = useQueryClient();
    const {t} = useTranslation();

    const handleCreateRole = useCallback(() => {
        setGlobalDrawer({
            isOpen: true,
            titleTransCode: translateCodes.CREATE_ROLE,
            content: (
                <RoleForm
                    onSuccess={() => {
                        resetGlobalDrawer();
                        queryClient.refetchQueries({
                            queryKey: [getAllRole.method, getAllRole.baseUrl, getAllRole.path],
                        });
                        viewRequests.showNotification.send({
                            type: 'success',
                            message: t(translateCodes.NM_ACC_04),
                        });
                    }}
                />
            ),
        });
    }, [queryClient, resetGlobalDrawer, setGlobalDrawer, t]);

    return (
        <AppButton
            translateCode={translateCodes.CREATE_ROLE}
            icon={<PlusOutlined />}
            type='primary'
            onClick={handleCreateRole}
        />
    );
}
