import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';
import { env } from '../../configs';

interface GetAllPromotionRequest extends HttpRequestData {
    readonly query: PaginationRequest; // Phân trang
}

export interface PromotionConditionValue {
    objectType?: number;
    numberOfTimes?: number;
    objectMode?: number;
    objectValue?: string;
}

export interface PromotionConditionItem {
    id?: string;
    objectType: number; 
    numberOfTimes: number;
    objectMode: number; 
    objectValue?: string[]; 
}

export interface PromotionConditionRequest {
    id?: string;
    objectType: number; 
    numberOfTimes: number;
    objectMode: number; 
    objectValue?: string; 
}

export enum UnitApply {
    Percent = 0,
    Amount = 1
}

export interface GetPromotionResponseItem {
    readonly id: string;
    name: string;
    code: string;
    isActive: boolean;
    unit: UnitApply;
    value: number;
    effectiveDate: string;
    expirationDate: string;
    createdBy?: string;
    creationTime?: string;
    promotionCondition?: PromotionConditionItem;
}

export const getAllPromotion = httpUtil.createHttpRequestMeta<
    GetAllPromotionRequest,
    PaginationResponse<GetPromotionResponseItem>
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/promotions',
    method: 'GET',
    authentication: 'bearer',
});