import React from 'react';
import { IconProps } from '../../Types';

export const IconSms = (props: IconProps) => {
    return (
        <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g clipPath='url(#clip0_3133_73508)'>
                <path d='M0 13.7143C0 8.91384 0 6.51361 0.93423 4.68008C1.756 3.06726 3.06726 1.756 4.68008 0.93423C6.51361 0 8.91384 0 13.7143 0H26.2857C31.0862 0 33.4864 0 35.3199 0.93423C36.9327 1.756 38.244 3.06726 39.0658 4.68008C40 6.51361 40 8.91384 40 13.7143V26.2857C40 31.0862 40 33.4864 39.0658 35.3199C38.244 36.9327 36.9327 38.244 35.3199 39.0658C33.4864 40 31.0862 40 26.2857 40H13.7143C8.91384 40 6.51361 40 4.68008 39.0658C3.06726 38.244 1.756 36.9327 0.93423 35.3199C0 33.4864 0 31.0862 0 26.2857V13.7143Z' fill='url(#paint0_linear_3133_73508)' />
                <path d='M20 30.5C26.6274 30.5 32 25.4632 32 19.25C32 13.0368 26.6274 8 20 8C13.3726 8 8 13.0368 8 19.25C8 23.1882 10.1585 26.6538 13.4269 28.6637C13.3175 29.664 12.9886 30.999 12 32C13.8695 31.6214 15.3615 30.8607 16.4761 30.0071C17.5904 30.3276 18.7737 30.5 20 30.5Z' fill='white' />
            </g>
            <rect x='0.5' y='0.5' width='39' height='39' rx='7.5' stroke='#EAECF0' />
            <defs>
                <linearGradient id='paint0_linear_3133_73508' x1='20' y1='0' x2='20' y2='40' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#5AF575' />
                    <stop offset='1' stopColor='#13BD2C' />
                </linearGradient>
                <clipPath id='clip0_3133_73508'>
                    <rect width='40' height='40' rx='8' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

