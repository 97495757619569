import { eventUtil } from '@esg/framework';

export const saleEvents = {
    onRefetchSaleForm: eventUtil.createEvent<void>('onRefetchSaleForm'),
    onRefetchSaleList: eventUtil.createEvent<void>('onRefetchSaleList'),
    onRefetchTabSaleList: eventUtil.createEvent<void>('onRefetchTabSaleList'),
    onRefetchFastTipSale: eventUtil.createEvent<void>('onRefetchFastTipSale'),
    onRefetchSaleActive: eventUtil.createEvent<void>('onRefetchSaleActive'),
    onRefetchCustomerActive: eventUtil.createEvent<void>('onRefetchCustomerActive'),
    onPrintBill: eventUtil.createEvent<void>('onPrintBill'),
    onPaid: eventUtil.createEvent<void>('PAID_SALE')
};