import { GetAllCustomerResponseItem, GetCustomerDetailResponse } from '@esg/business-crm';
import { FlexBox, Spacer, Text, useLocalesFormat } from '@esg/ui';
import { Avatar } from 'antd';
import React from 'react';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { useRecoilState } from 'recoil';
import { CustomerLabelTag } from '@/components/customer/CustomerLabelTag';
import { translateCodes } from '@/locales/translateCodes';

interface CustomerInfoFullNameProps {
    record: GetAllCustomerResponseItem;
    lastAccessedOn: string;
}

export const CustomerInfoFullName = (props: CustomerInfoFullNameProps) => {
    const {formatDate} = useLocalesFormat();
    const [currentLocale] = useRecoilState(currentLocaleState);

    const { record } = props;
    const nameAvatar = record?.firstName?.[0] + (record?.lastName ? record?.lastName?.[0] : record?.firstName?.[1]??'');
    return (
        <FlexBox gap={12} alignItems='center'>
            <Avatar
                size={86}
                key={record.id}
                src={record.avatar}
                style={{ backgroundColor: '#F2F4F7' }}
            >
                {nameAvatar}
            </Avatar>
            <FlexBox direction='column'>
                <FlexBox>
                    <Text fontSize={18} ellipsis fontWeight='bold' maxWidth={200} maxLines={1}>{record?.fullName}</Text>
                    <Spacer right={5}/>
                    <CustomerLabelTag customer={record as GetCustomerDetailResponse} /> 
                </FlexBox>
                <FlexBox direction='row'>
                    <Text translateCode={translateCodes.LAST_ACCESSED_ON} ellipsis maxWidth={200} maxLines={1} />
                    <Spacer right={5}/>
                    <Text ellipsis maxWidth={200} maxLines={1}>{formatDate(props.lastAccessedOn, 'ddd, ' + currentLocale?.format + ' h:mm A')}</Text>
                </FlexBox>
            </FlexBox>
        </FlexBox>
    );
};

