import {
    FormBase,
    InputField,
    TextAreaField,
    SwitchField,
    Text,
    FormLayout,
    Spacer,
    AppButton,
    RadioGroupField} from '@esg/ui';
import React from 'react';
import { GetSegmentResponseItem, SegmentConditionItem } from '@esg/business-management/api/segment';
import { Card, Divider, Radio } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { GetAllProductCategoryResponseItem, GetAllServiceCategoryResponseItem } from '@esg/business-management';
import { segmentRules } from '../rules';
import {SegmentConditionForm}  from './SegmentConditionForm';
import { translateCodes } from '@/locales';

interface SegmentFormProps {
    readonly onDelete?: () => void;
    readonly refetch: () => void;
    readonly resetDrawerState: () => void;
    readonly defaultValues?: GetSegmentResponseItem;
    readonly onSubmit?: (value: GetSegmentResponseItem) => void;
    readonly serviceCategories?: GetAllServiceCategoryResponseItem[];
    readonly productCategories?: GetAllProductCategoryResponseItem[];
}

export const SegmentForm = (props: SegmentFormProps) => {
    const {serviceCategories, productCategories} = props;
    const services = serviceCategories?.flatMap(category => category.services) ||  [];
    const products = productCategories?.flatMap(category => category.products) || [];
    
    return (
        <FormBase
            onSubmit={props.onSubmit}
            defaultValues={{ ...props.defaultValues }}
        >
            {
                (form) => (
                    <FormLayout
                        labelSubmitCode={
                            props.defaultValues?.id
                                ? translateCodes.SAVE
                                : translateCodes.CREATE
                        }
                    >
                        <Spacer bottom={8}>
                            <Text
                                fontWeight={600}
                                lineHeight='20px'
                                color='#344054'
                            >
                                General information
                            </Text>
                        </Spacer>
                        <Card className='card-body' >
                            <InputField
                                name='name'
                                label='Segment name'
                                placeholder='Enter segment name'
                                rules={segmentRules.segmentName}
                            />
                            <Spacer bottom={8}/>
                            <TextAreaField
                                name='description'
                                label='Description'
                                placeholder='Enter description'
                                rules={segmentRules.description}
                            />

                        </Card>
                        <Spacer bottom={8} top={12}>
                            <Text
                                fontWeight={600}
                                lineHeight='20px'
                                color='#344054'
                            >
                                Conditions
                            </Text>
                        </Spacer>
                        <Card className='card-body'>
                            {
                                form.watch('segmentConditions').length > 1
                                && <Spacer bottom={12}>
                                    <RadioGroupField
                                        name='operator'
                                        disabled={!!props.defaultValues?.id}
                                    >
                                        <Radio value={0}>And</Radio>
                                        <Radio value={1}>Or</Radio>
                                    </RadioGroupField>
                                </Spacer>
                            }
                            {
                                form.watch('segmentConditions')?.map((condition: SegmentConditionItem, index: number) => (
                                    // eslint-disable-next-line react/jsx-key
                                    <React.Fragment key={index}>
                                        <SegmentConditionForm
                                            form={form}
                                            condition={condition}
                                            index={index}
                                            disabled={!!props.defaultValues?.id}
                                            serviceCategories={serviceCategories}
                                            productCategories={productCategories}
                                            services={services}
                                            products={products}
                                        />
                                        {index < form.watch('segmentConditions').length - 1 && <Divider  style={{ marginTop: 16, marginBottom: 16 }}/>}
                                    </React.Fragment>
                                ))
                            }
                            <AppButton
                                translateCode='Add Condition'
                                icon={<PlusOutlined/>}
                                onClick={() => {
                                    const updatedConditions = form.watch('segmentConditions') || [];
                                    updatedConditions.push({ condition: '', value: '' });
                                    form.setValue('segmentConditions', updatedConditions);
                                }}
                                disabled={!!props.defaultValues?.id}
                            />
                        </Card>
                        <Spacer top={12}>
                            <SwitchField
                                name='isAutoUpdate'
                                rightDescription='Automatically update client list'
                            />
                        </Spacer>
                    </FormLayout >
                )
            }

        </FormBase>
    );
};

SegmentForm.defaultProps = {
    defaultValues: {
        isAutoUpdate: false,
        operator: 0,
        segmentConditions: []
    },
};

